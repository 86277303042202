import { isConstructorDeclaration } from "typescript";
import httpRequest from "../../api";
import { profileConstants } from "../actionTypes";

const persistUpdatedProfile = (data) => {
  console.log("persisted data", data);
  const prevProfile = JSON.parse(sessionStorage.getItem("userData") || "{}");
  const newProfile = {
    ...prevProfile,
    ...data.profile,
  };
  sessionStorage.setItem("userData", JSON.stringify(newProfile));
  return newProfile;
};

// ======================================================

export const getProfileAction = (dispatch) => {
  httpRequest("get", "user/profile")
    .then(({ data }) => {
      console.log("dd", data.data);

      const newProfile = persistUpdatedProfile(data.data);
      console.log("newProfile", newProfile);

      const action = {
        type: profileConstants.GET_PROFILE,
        payload: data.data.profile,
      };

      dispatch(action);

      return action;
    })
    .catch(({ response }) => ({
      payload: response?.data?.message,
      type: profileConstants.ERROR,
    }));
};

// ======================================================

export const updateProfileAction = (profileData, dispatch) => {
  dispatch({ type: profileConstants.LOADING });

  return httpRequest("put", `user/profile`, profileData)
    .then(({ data }) => {
      console.log("Profile Data", data);

      const action = {
        type: profileConstants.EDIT_PROFILE,
        payload: data,
      };

      dispatch({ type: profileConstants.STOP_LOADING });
      // dispatch(action);
      return action;
    })
    .catch(({ response }) => {
      console.log("Response", response);
      const message = response?.data?.data?.error;

      dispatch({ type: profileConstants.STOP_LOADING });
      dispatch({ type: profileConstants.ERROR, payload: message });
    });
};

// ======================================================

export const updateProfileImage = async (
  image,
  { first_name, last_name, email },
  dispatch
) => {
  dispatch({ type: profileConstants.LOADING_PICTURE });

  const data = new FormData();
  data.append("file", image);
  data.append("upload_preset", "profile");
  data.append("cloud_name", "holiday-short-stays");
  fetch("https://api.cloudinary.com/v1_1/holiday-short-stays/image/upload", {
    method: "POST",
    body: data,
  })
    .then((response) => response.json())
    .then((data) => {
      updateProfileAction(
        { profile_url: data.url, first_name, last_name, email },
        dispatch
      );

      dispatch({
        type: profileConstants.STOP_LOADING_PICTURE,
      });

      const action = { profile_url: data.url };

      console.log("ACTION", action);

      return action;
    })
    .catch((err) => console.log("error", err));
};
