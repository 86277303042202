// import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
// import SectionGridAuthorBox from "components/SectionGridAuthorBox/SectionGridAuthorBox";
import SectionHeroArchivePage from "components/SectionHeroArchivePage/SectionHeroArchivePage";
// import SectionSliderNewCategories from "components/SectionSliderNewCategories/SectionSliderNewCategories";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import { FC, useEffect, useState } from "react";
import SectionGridFilterCard from "./SectionGridFilterCard";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { getShortlets } from "redux/actions/shortletsAction";
import { RootState } from "redux/reducers";
import { ShowLoading } from "components/ShowLoading";
import Heading2 from "components/Heading/Heading2";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { ApartmentDataType } from "data/types";

export interface ListingStayPageProps {
  className?: string;
}

export interface ShortletsProps {
  loading: boolean;
  shortlets: Array<ApartmentDataType>;
  count: number;
  pagination: { nextPage: number };
  currentPage: number;
}

const ShortletsPage: FC<ListingStayPageProps> = ({ className = "" }) => {
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [filter, setFilter] = useState({});
  const [loadingMore, setLoadingMore] = useState(false);

  const { loading, shortlets, count, pagination, currentPage }: ShortletsProps =
    useSelector((store: RootState) => store.shortlets);

  useEffect(() => {
    const fetchShortlets = async () => {
      await getShortlets(filter, 1, false, dispatch);
    };

    if (shortlets.length === 0) fetchShortlets();
  }, [dispatch]);

  const loadMore = async (page: number) => {
    setLoadingMore(true);
    setPage(page);
    await getShortlets(filter, page, true, dispatch);
    setLoadingMore(false);
  };

  return (
    <div
      className={`nc-ListingStayPage relative overflow-hidden ${className}`}
      data-nc-id="ListingStayPage"
    >
      <Helmet>
        <title>Shortlet Apartments - Holiday Short Stays</title>
      </Helmet>
      <BgGlassmorphism />

      <div className="container relative overflow-hidden">
        {/* SECTION HERO */}
        <SectionHeroArchivePage
          currentPage="Stay"
          currentTab="Stay"
          className=""
          heroText="Shortlet Apartments Equipped For You"
          subtitleText="Check out our array of shortlet apartments tailored for your
              perfect holiday"
        />

        {/* SECTION */}
        <Heading2 />
        {loading && <ShowLoading />}

        {!loading && shortlets.length > 0 && (
          <SectionGridFilterCard className="" data={shortlets} />
        )}

        {!loading && pagination.nextPage && (
          <div className="flex mt-16 justify-center items-center pb-24 lg:pb-32">
            <ButtonPrimary
              loading={loadingMore}
              onClick={() => loadMore(pagination.nextPage)}
            >
              Load More
            </ButtonPrimary>
          </div>
        )}

        {/* SECTION 1 */}
        {/* <div className="relative py-16">
          <BackgroundSection />
          <SectionSliderNewCategories
            heading="Explore by types of stays"
            subHeading="Explore houses based on 10 types of stays"
            categoryCardType="card5"
            itemPerRow={5}
            sliderStyle="style2"
          />
        </div> */}

        {/* SECTION */}
        <SectionSubscribe2 className="py-24 lg:py-32" />

        {/* SECTION */}
        {/* <div className="relative py-16 mb-24 lg:mb-32">
          <BackgroundSection className="bg-orange-50 dark:bg-black dark:bg-opacity-20 " />
          <SectionGridAuthorBox />
        </div> */}
      </div>
    </div>
  );
};

export default ShortletsPage;
