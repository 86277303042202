import SectionHero from "components/SectionHero/SectionHero";
import SectionSliderNewCategories from "components/SectionSliderNewCategories/SectionSliderNewCategories";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import SectionOurFeatures from "components/SectionOurFeatures/SectionOurFeatures";
import SectionGridFeaturePlaces from "./SectionGridFeaturePlaces";
import SectionHowItWork from "components/SectionHowItWork/SectionHowItWork";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import { TaxonomyType } from "data/types";
import SectionBecomeAnAuthor from "components/SectionBecomeAnAuthor/SectionBecomeAnAuthor";
import SectionClientSay from "components/SectionClientSay/SectionClientSay";
import { Helmet } from "react-helmet";

const DEMO_CATS: TaxonomyType[] = [
  {
    id: "1",
    href: "/ikate-elegushi-zrcd/62fa97267ba4f8f546d71454",
    name: "Ikate",
    taxonomy: "category",
    count: 158,
    thumbnail:
      "https://res.cloudinary.com/holiday-short-stays/image/upload/v1659548159/featuredlocations/Ikate_gmruge.jpg",
  },
  {
    id: "2",
    href: "/lekki-phase-1-hlpu/62fa972d7ba4f8f546d71456",
    name: "Lekki Phase 1",
    taxonomy: "category",
    count: 744,
    thumbnail:
      "https://res.cloudinary.com/holiday-short-stays/image/upload/v1659547954/featuredlocations/Lekki_Phase_1_dkecq7.jpg",
  },
  {
    id: "3",
    href: "/bluewaters-hxjt/62ff9c0eba6d83e982554d7b",
    name: "Bluewaters",
    taxonomy: "category",
    count: 458,
    thumbnail:
      "https://res.cloudinary.com/holiday-short-stays/image/upload/v1659547941/featuredlocations/Blue_waters_earx7x.jpg",
  },
  {
    id: "4",
    href: "/eko-atlantic-byee/62fa97117ba4f8f546d7144c",
    name: "Eko Atlantic",
    taxonomy: "category",
    count: 365,
    thumbnail:
      "https://res.cloudinary.com/holiday-short-stays/image/upload/v1659548530/featuredlocations/toplocation4_gd2szf.jpg",
  },
  {
    id: "5",
    href: "/lakowe-7f7z/62fa975a7ba4f8f546d71458",
    name: "Lakowe Lakes",
    taxonomy: "category",
    count: 965,
    thumbnail:
      "https://res.cloudinary.com/holiday-short-stays/image/upload/v1659547976/featuredlocations/Lakowe-Lakes_oljg2r.jpg",
  },
  {
    id: "6",
    href: "/ikeja-40ut/62fa971a7ba4f8f546d71450",
    name: "Ikeja GRA",
    taxonomy: "category",
    count: 533,
    thumbnail:
      "https://res.cloudinary.com/holiday-short-stays/image/upload/v1659548299/featuredlocations/Ikeja_GRA_sbwpar.jpg",
  },
  {
    id: "7",
    href: "/victoria-island-38if/62fa96f57ba4f8f546d71446",
    name: "Victoria Island",
    taxonomy: "category",
    count: 533,
    thumbnail:
      "https://res.cloudinary.com/holiday-short-stays/image/upload/v1659547950/featuredlocations/Victoria_Island_seuao9.jpg",
  },
  {
    id: "8",
    href: "/oniru-nwj8/62fa975f7ba4f8f546d7145a",
    name: "Oniru",
    taxonomy: "category",
    count: 533,
    thumbnail:
      "https://res.cloudinary.com/holiday-short-stays/image/upload/v1659547955/featuredlocations/Oniru_uvsyff.jpg",
  },
  {
    id: "9",
    href: "/ikoyi-436g/62ff85ef484a14a63d4e9222",
    name: "Ikoyi",
    taxonomy: "category",
    count: 533,
    thumbnail:
      "https://res.cloudinary.com/holiday-short-stays/image/upload/v1659547944/featuredlocations/Ikoyi_tavaqh.jpg",
  },
  {
    id: "10",
    href: "/ilashe-fz0i/62ffc066ba6d83e982554d85",
    name: "Ilashe",
    taxonomy: "category",
    count: 533,
    thumbnail:
      "https://res.cloudinary.com/holiday-short-stays/image/upload/v1659547957/featuredlocations/Ilashe_pht9ax.jpg",
  },
];

const DEMO_CATS_2: TaxonomyType[] = [
  {
    id: "1",
    href: "#",
    name: "Jara Beach resort",
    taxonomy: "category",
    count: 10,
    location: "Eleko, Lagos",
    thumbnail:
      "https://i1.wp.com/www.travelwithapen.com/wp-content/uploads/2020/01/Jara-Private-Beach-Resort-scaled.jpg?resize=1897%2C2048&ssl=1",
  },
  {
    id: "222",
    href: "#",
    name: "La Campagne Tropicana",
    taxonomy: "category",
    count: 22,
    location: "Lekki-Epe Expressway, Lagos",
    thumbnail:
      "https://i0.wp.com/www.travelwithapen.com/wp-content/uploads/2019/04/La-Campagne-Tropicana-Travelwithapen.jpeg?w=1860&ssl=1",
  },
  {
    id: "3",
    href: "#",
    name: "Kodo Beach Resort",
    taxonomy: "category",
    count: 38,
    location: "Eleko, Lagos",
    thumbnail:
      "https://res.cloudinary.com/eko-shortlets/image/upload/v1638672676/get-away_fkaugb.jpg",
  },
  {
    id: "4",
    href: "#",
    name: "Tarkwa Bay",
    taxonomy: "category",
    count: 9,
    location: "Lagos Island, Lagos",
    thumbnail:
      "https://i2.wp.com/www.travelwithapen.com/wp-content/uploads/2020/08/Tarkwa-Bay-Beach-scaled.jpg?w=1740&ssl=1",
  },
];

function PageHome() {
  return (
    <div className="nc-PageHome relative overflow-hidden">
      <Helmet>
        <title>Holiday Short Stays </title>
      </Helmet>
      {/* GLASSMOPHIN */}
      <BgGlassmorphism />

      <div className="container relative space-y-24 pt-8 mt-40 md:mt-0 mb-24 lg:space-y-32 lg:mb-32">
        {/* SECTION HERO */}
        <SectionHero className="pb-5" />

        {/* Top Locations in Lagos */}
        <SectionSliderNewCategories categories={DEMO_CATS} />

        {/* Featured Places to Stay */}
        <div className="relative py-16">
          <BackgroundSection />
          <SectionGridFeaturePlaces />
        </div>

        {/* SECTION2 */}
        <SectionOurFeatures />

        {/* SECTION */}
        <SectionHowItWork />

        {/* SECTION 1 */}
        <div className="relative py-16">
          <BackgroundSection className="bg-orange-50 dark:bg-black dark:bg-opacity-20 " />
          <SectionSliderNewCategories
            categories={DEMO_CATS_2}
            categoryCardType="card4"
            itemPerRow={4}
            heading="Top Get-Away Destinations"
            subHeading="We found some popular destinations for the perfect vacation"
            sliderStyle="style2"
          />
        </div>

        {/* SECTION */}
        <SectionSubscribe2 />

        {/* SECTION */}
        {/* <div className="relative py-16">
          <BackgroundSection className="bg-orange-50 dark:bg-black dark:bg-opacity-20 " />
          <SectionGridAuthorBox />
        </div> */}

        {/* SECTION */}
        {/* <SectionGridCategoryBox /> */}

        {/* SECTION */}
        <div className="relative py-16">
          <BackgroundSection />
          <SectionBecomeAnAuthor />
        </div>

        {/* SECTION 1 */}
        <SectionSliderNewCategories
          heading="Various Types of Apartments"
          subHeading="We've got a wide array of apartment types on our platform"
          categoryCardType="card5"
          itemPerRow={5}
        />

        {/* SECTION */}
        {/* <SectionVideos /> */}

        {/* SECTION */}
        {/* <div className="relative py-16">
          <BackgroundSection />
          <SectionClientSay />
        </div> */}
      </div>
    </div>
  );
}

export default PageHome;
