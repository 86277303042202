import React, { FC, useState, useEffect } from "react";
import StartRating from "components/StartRating/StartRating";
// import GoogleMapReact from "google-map-react";
import moment from "moment";
import Avatar from "shared/Avatar/Avatar";
import Badge from "shared/Badge/Badge";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Input from "shared/Input/Input";
import NcImage from "shared/NcImage/NcImage";
import LikeSaveBtns from "./LikeSaveBtns";
import ModalPhotos from "./ModalPhotos";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import SectionSliderNewCategories from "components/SectionSliderNewCategories/SectionSliderNewCategories";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import { fetchCarDetails } from "redux/actions/carActions";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import { RootState } from "redux/reducers";
import FormItem from "containers/PageAddListing/FormItem";
import { carConstants } from "redux/actionTypes";
import cogoToast from "cogo-toast";
import OwnersCard from "components/Owner/OwnersCard";
import Textarea from "shared/Textarea/Textarea";
import { submitInquiry } from "redux/actions/bookingActions";
import { ButtonLoader } from "components/ButtonLoader.jsx";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import SocialRow from "components/Owner/SocialRow";
import { formatMoney } from "utils/helpers";
export interface ListingCarDetailPageProps {
  className?: string;
}

const includes_demo = [
  { name: "Free cancellation up to 48 hours before pick-up" },
  { name: "Our drivers are well trained and experienced" },
  { name: "All our cars are in good condition" },
];

export interface SingleCarProps {
  carid: string;
  slug: string;
}

const ListingCarDetailPage: FC<ListingCarDetailPageProps> = ({
  className = "",
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [openFocusIndex, setOpenFocusIndex] = useState(0);
  const [whatsAppMessage, setWhatsAppMessage] = useState("");
  const [apartmentURL, setApartmentURL] = useState("");
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [state, setState] = useState({
    fullname: "",
    phone: "",
    email: "",
    message: "",
    from: "",
    to: "",
  });

  const { carid, slug } = useParams<SingleCarProps>();
  const { car, fetching, ownerscount }: any = useSelector(
    (state: RootState) => state.cars
  );
  const { loading }: any = useSelector((state: RootState) => state.booking);

  console.log("Car details", car);

  useEffect(() => {
    // setLoading(true);
    const fetchSingleCarDetails = async () => {
      await fetchCarDetails(carid, dispatch);
      // setLoading(false);
    };

    fetchSingleCarDetails();
  }, [dispatch]);

  useEffect(() => {
    setApartmentURL(`https://holidayshortstays.com${location.pathname}`);
    const text = `I am interested in your property on Holiday Short Stays:

    https://holidayshortstays.com${location.pathname}`;

    decodeURIComponent(text.replace(/\+/g, " "));
    setWhatsAppMessage(text);

    document.title = "Fresh apartment";
  }, [location]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue: any = {};
    newValue[e.target.name] = e.target.value;
    setState({ ...state, ...newValue });
  };

  const handleChangeTextArea = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newValue: any = {};
    newValue[e.target.name] = e.target.value;
    setState({ ...state, ...newValue });
  };

  // Submit Car Inquiry...
  const handleSubmit = (e: { preventDefault: () => void }) => {
    e.preventDefault();

    if (!state.fullname || !state.phone || !state.email || !state.message) {
      cogoToast.error("Kindly fill all fields before submitting", {
        position: "top-center",
      });
      return;
    }

    dispatch({ type: carConstants.LOADING });
    const category = "car";

    const fromDate = moment(startDate).format("dddd, MMMM Do YYYY");
    const toDate = moment(endDate).format("dddd, MMMM Do YYYY");

    let postPayload: any = state;
    postPayload["owner_id"] = car.owner_id;
    postPayload["category"] = category;
    postPayload["property_id"] = carid;
    postPayload["property_title"] = car.title;
    postPayload["fromDate"] = fromDate;
    postPayload["toDate"] = toDate;
    postPayload[
      "property_url"
    ] = `https://holidayshortstays.com/${category}/${carid}/${slug}`;

    const processSubmitInquiry = async () => {
      const booked = await submitInquiry(postPayload, dispatch);

      console.log("Booked", booked);

      const {
        payload: { data, status },
      } = booked;

      if (status === 200) {
        cogoToast.success(data.message, {
          position: "top-right",
          hideAfter: 5,
        });

        const defaultState = {
          fullname: "",
          phone: "",
          email: "",
          message: "",
        };
        setState({ ...state, ...defaultState });
      } else {
        cogoToast.error(data.message, {
          position: "top-right",
        });
        dispatch({ type: carConstants.STOP_LOADING });
      }
    };

    processSubmitInquiry();
  };

  // const windowSize = useWindowSize();

  // const getDaySize = () => {
  //   if (windowSize.width <= 375) {
  //     return 34;
  //   }
  //   if (windowSize.width <= 500) {
  //     return undefined;
  //   }
  //   if (windowSize.width <= 1280) {
  //     return 56;
  //   }
  //   return 48;
  // };

  const handleOpenModal = (index: number) => {
    setIsOpen(true);
    setOpenFocusIndex(index);
  };

  const handleCloseModal = () => setIsOpen(false);

  const renderSection1 = () => {
    return (
      <div className="listingSection__wrap !space-y-6">
        {/* 1 */}
        <div className="flex justify-between items-center">
          <Badge color="pink" name={`${car.make_id.make} - ${car.model}`} />
          <LikeSaveBtns
            url={apartmentURL}
            description={`Check out this car on Holiday Short Stays`}
          />
        </div>

        {/* 2 */}
        <h2 className="text-2xl sm:text-3xl lg:text-4xl font-semibold">
          {car.title}
        </h2>

        {/* 3 */}
        {/* <div className="flex items-center space-x-4">
          <StartRating />
          <span>·</span>
          <span>
            <i className="las la-map-marker-alt"></i>
            <span className="ml-1"> Tokyo, Jappan</span>
          </span>
        </div> */}

        {/* 4 */}
        <div className="flex items-center">
          <Avatar hasChecked sizeClass="h-10 w-10" radius="rounded-full" />
          <span className="ml-2.5 text-neutral-500 dark:text-neutral-400">
            Car owner:{" "}
            <span className="text-neutral-900 dark:text-neutral-200 font-medium">
              {car &&
                car.owner_id &&
                (car.owner_id.company_name
                  ? car.owner_id.company_name
                  : car.owner_id.first_name + " " + car.owner_id.last_name)}
            </span>
          </span>
        </div>

        {/* 5 */}
        <div className="w-full border-b border-neutral-100 dark:border-neutral-700" />

        {/* 6 */}
        <div className="flex items-center justify-between xl:justify-start space-x-8 xl:space-x-12 text-sm text-neutral-700 dark:text-neutral-300">
          <div className="flex flex-col sm:flex-row items-center space-y-3 sm:space-y-0 text-center sm:text-left sm:space-x-3 ">
            <i className="las la-user-friends text-2xl"></i>
            <span className="">4 seats</span>
          </div>
          <div className="flex flex-col sm:flex-row items-center space-y-3 sm:space-y-0 text-center sm:text-left sm:space-x-3 ">
            <i className="las la-palette text-2xl"></i>
            <span className=""> {car.color}</span>
          </div>
          <div className="flex flex-col sm:flex-row items-center space-y-3 sm:space-y-0 text-center sm:text-left sm:space-x-3 ">
            <i className="las la-calendar text-2xl"></i>
            <span className=""> {car.year}</span>
          </div>
        </div>
      </div>
    );
  };

  const renderSection2 = () => {
    return (
      <div className="listingSection__wrap">
        <h2 className="text-2xl font-semibold">Car description</h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        <div className="text-neutral-6000 dark:text-neutral-300">
          <p>{car.description}</p>
        </div>
      </div>
    );
  };

  const priceSection = () => {
    return (
      <div className="listingSection__wrap">
        <span className="text-3xl font-semibold">
          {car && car.price ? formatMoney(car.price) : 0}
          <span className="ml-1 text-base font-normal text-neutral-500 dark:text-neutral-400">
            /day
          </span>
        </span>
      </div>
    );
  };

  const renderSection3 = () => {
    return (
      <div className="listingSection__wrap">
        <div>
          <h2 className="text-2xl font-semibold">Include </h2>
          <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
            Included in the price
          </span>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        {/* 6 */}
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 text-sm text-neutral-700 dark:text-neutral-300 ">
          {includes_demo
            .filter((_, i) => i < 12)
            .map((item) => (
              <div key={item.name} className="flex items-center space-x-3">
                <i className="las la-check-circle text-2xl"></i>
                <span>{item.name}</span>
              </div>
            ))}
        </div>
      </div>
    );
  };

  const renderSectionCheckIndate = () => {
    return (
      <div className="listingSection__wrap overflow-hidden">
        <form method="POST" onSubmit={handleSubmit}>
          {/* HEADING */}
          <div className="">
            <h2 className="text-2xl font-semibold">
              Inquire about this luxury car
            </h2>
            <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
              All inquiries filled in the form below go directly to the host of
              the car.
            </span>
          </div>
          <div className="w-14 border-b border-neutral-200 mt-8 mb-8 dark:border-neutral-700"></div>
          {/*  */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8 md:gap-5 mt-6">
            <FormItem label="Full Name">
              <Input
                placeholder="Full Name"
                type="text"
                name="fullname"
                onChange={handleChange}
                value={state.fullname}
              />
            </FormItem>
            <FormItem label="Phone Number">
              <Input
                placeholder="phone"
                type="number"
                name="phone"
                onChange={handleChange}
                value={state.phone}
              />
            </FormItem>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-1 gap-8 md:gap-5 mt-6">
            <FormItem label="Email Address">
              <Input
                placeholder="email"
                type="email"
                name="email"
                onChange={handleChange}
                value={state.email}
              />
            </FormItem>
          </div>
          <div className="grid grid-cols-2 md:grid-cols-2 gap-8 md:gap-5 mt-6">
            <FormItem label="From">
              <DatePicker
                name="from"
                dateFormat="dd/MMM/yyyy"
                className="block rounded-xl h-14 px-4 py-3 w-full border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900"
                selected={startDate}
                onChange={(date: Date) => setStartDate(date)}
              />
            </FormItem>
            <FormItem label="To">
              <DatePicker
                name="to"
                dateFormat="dd/MMM/yyyy"
                className="block rounded-xl h-14 px-4 py-3 w-full border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900"
                selected={endDate}
                onChange={(date: Date) => setEndDate(date)}
              />
            </FormItem>
          </div>
          <div className="grid grid-cols-1 gap-8 md:gap-5 mt-6">
            <FormItem label="Message">
              <Textarea
                placeholder="enter your message"
                rows={4}
                name="message"
                value={state.message ?? ""}
                onChange={handleChangeTextArea}
              />
            </FormItem>
            <div className="grid grid-cols-1">
              <ButtonPrimary type="submit">
                {loading ? <ButtonLoader /> : "Send Message"}
              </ButtonPrimary>
            </div>
          </div>
        </form>
      </div>
    );
  };

  const renderSection5 = () => {
    return (
      <div className="listingSection__wrap">
        {/* HEADING */}
        <h2 className="text-2xl font-semibold">Car Owner</h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>

        {/* host */}
        <div className="flex items-center space-x-4">
          <Avatar
            hasChecked
            hasCheckedClass="w-4 h-4 -top-0.5 right-0.5"
            sizeClass="h-14 w-14"
            radius="rounded-full"
          />
          <div>
            <a className="block text-xl font-medium" href="##">
              {car.owner_id &&
                car.owner_id.first_name + " " + car.owner_id.last_name}
            </a>
            <div className="mt-1.5 flex items-center text-sm text-neutral-500 dark:text-neutral-400">
              <StartRating />
              <span className="mx-2">·</span>
              <span> 12 places</span>
            </div>
          </div>
        </div>

        {/* desc */}
        <span className="block text-neutral-6000 dark:text-neutral-300">
          {car.owner_id.bio.substring(0, 100)}
        </span>

        {/* info */}
        <div className="block text-neutral-500 dark:text-neutral-400 space-y-2.5">
          <div className="flex items-center space-x-3">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
              />
            </svg>
            <span>
              Joined in {moment(car.owner_id.created_at).format("Do MMM. YYYY")}
            </span>
          </div>
          <div className="flex items-center space-x-3">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                d="M7 8h10M7 12h4m1 8l-4-4H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-3l-4 4z"
              />
            </svg>
            <span>
              {car.owner_id.phone}
              {car.owner_id.phone1 && ", " + car.owner_id.phone1}
            </span>
          </div>
          <div className="flex items-center space-x-3">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>

            <span>{car.owner_id.email}</span>
          </div>
        </div>

        {/* == */}
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
      </div>
    );
  };

  const renderSidebarPrice = () => {
    return (
      <OwnersCard
        property="car"
        ownerDetails={car.owner_id}
        price={car.price}
        unit="day"
        ownerscount={ownerscount}
      />
    );
  };

  const renderSidebarDetail = () => {
    return (
      <div className="listingSection__wrap md:hidden">
        {/* HEADING */}
        <div>
          <h2 className="text-2xl font-semibold">Posted By</h2>
          <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
            The details of this car is in total control of the poster.
          </span>
        </div>
        <div className="w-14 border-b border-ne utral-200 dark:border-neutral-700"></div>
        {/* CONTENT */}
        <div className="flow-root">
          <div className="text-sm sm:text-base text-neutral-6000 dark:text-neutral-300 mb-4">
            <div className="p-4 bg-neutral-100 dark:bg-neutral-800 flex justify-between items-center space-x-4 rounded-lg">
              <span>Full Name</span>
              <span>
                {car &&
                  car.owner_id &&
                  `${car.owner_id.first_name} ${car.owner_id.last_name}`}
              </span>
            </div>
            <div className="p-4 flex justify-between items-center space-x-4 rounded-lg">
              <span>Company Name</span>
              <span>
                {car.owner_id &&
                  (car.owner_id.company_name
                    ? car.owner_id.company_name
                    : `${car.owner_id.first_name} ${car.owner_id.last_name}`)}
              </span>
            </div>
            <div className="p-4 bg-neutral-100 dark:bg-neutral-800 flex justify-between items-center space-x-4 rounded-lg">
              <span>Phone Number: </span>
              <span>{car.owner_id && car.owner_id.phone1}</span>
            </div>
            <div className="p-4 flex justify-between items-center space-x-4 rounded-lg">
              <span>Email: </span>
              <span>{car.owner_id && car.owner_id.email}</span>
            </div>
            {car.owner_id && car.owner_id.phone1 && (
              <div className="p-4 flex justify-between dark:bg-neutral-800 items-center space-x-4 rounded-lg">
                <span>WhatsApp:</span>
                <span>
                  <a
                    href={`https://wa.me/${car.owner_id.phone1}?text=${whatsAppMessage}`}
                    target="_blank"
                    style={{ textDecoration: "underline" }}
                  >
                    +{car.owner_id.phone1}
                  </a>
                </span>
              </div>
            )}
            <div className="p-4 flex justify-between items-center space-x-4 rounded-lg">
              {car && car.owner_id && (
                <Link to={`/host/car/${car.owner_id._id}`}>
                  <span style={{ textDecoration: "underline" }}>
                    Total Cars:
                  </span>
                </Link>
              )}
              <span>{ownerscount}</span>
            </div>
            <div className="p-4 flex justify-between dark:bg-neutral-800 items-center space-x-4 rounded-lg">
              <span>Social Media:</span>
              <span>
                <SocialRow socials={car.social} />
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div
      className={`nc-ListingCarDetailPage  ${className}`}
      data-nc-id="ListingCarDetailPage"
    >
      {/* SINGLE HEADER */}
      {car && (
        <>
          <header className="container 2xl:px-14 rounded-md sm:rounded-xl">
            <div className="relative grid grid-cols-4 gap-1 sm:gap-2">
              <div
                className="col-span-2 row-span-2 relative rounded-md sm:rounded-xl overflow-hidden cursor-pointer"
                onClick={() => handleOpenModal(0)}
              >
                <NcImage
                  containerClassName="absolute inset-0"
                  className="object-cover w-full h-full rounded-md sm:rounded-xl"
                  src={car.featured_img}
                  prevImageHorizontal
                />
                <div className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity"></div>
              </div>

              {/*  */}
              <div
                className="col-span-1 row-span-2 relative rounded-md sm:rounded-xl overflow-hidden cursor-pointer"
                onClick={() => handleOpenModal(1)}
              >
                <NcImage
                  containerClassName="absolute inset-0"
                  className="object-cover w-full h-full rounded-md sm:rounded-xl"
                  src={car.other_imgs[0]}
                  prevImageHorizontal
                />
                <div className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity"></div>
              </div>

              {/*  */}
              {car.other_imgs
                .filter((_: any, i: number) => i >= 2 && i < 4)
                .map((item: any, index: number) => (
                  <div
                    key={index}
                    className={`relative rounded-md sm:rounded-xl overflow-hidden ${
                      index >= 2 ? "block" : ""
                    }`}
                  >
                    <NcImage
                      containerClassName="aspect-w-4 aspect-h-3"
                      className="object-cover w-full h-full rounded-md sm:rounded-xl "
                      src={item || ""}
                      prevImageHorizontal
                    />

                    {/* OVERLAY */}
                    <div
                      className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity cursor-pointer"
                      onClick={() => handleOpenModal(index + 2)}
                    />
                  </div>
                ))}

              <div
                className="absolute hidden md:flex md:items-center md:justify-center left-3 bottom-3 px-4 py-2 rounded-xl bg-neutral-100 text-neutral-500 cursor-pointer hover:bg-neutral-200 z-10"
                onClick={() => handleOpenModal(0)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={1.5}
                    d="M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z"
                  />
                </svg>
                <span className="ml-2 text-neutral-800 text-sm font-medium">
                  Show all photos
                </span>
              </div>
            </div>
          </header>
          {/* MODAL PHOTOS */}
          <ModalPhotos
            imgs={car.other_imgs}
            isOpen={isOpen}
            onClose={handleCloseModal}
            initFocus={openFocusIndex}
          />
        </>
      )}

      {/* MAIn */}
      <main className="container mt-11 flex ">
        {/* CONTENT */}
        {car && (
          <div className="w-full lg:w-3/5 xl:w-2/3 space-y-8 lg:pr-10 lg:space-y-10">
            {renderSection1()}
            <div className="block lg:hidden">{renderSidebarDetail()}</div>
            {/* {renderSectionTienIch()} */}
            {renderSection2()}
            <div className="block lg:hidden">{priceSection()}</div>
            {renderSection3()}
            {renderSectionCheckIndate()}
            {renderSection5()}
          </div>
        )}

        {/* SIDEBAR */}
        {car && (
          <div className="hidden lg:block flex-grow">
            {/* {renderSidebarDetail()} */}
            <div className="mt-10 sticky top-24">{renderSidebarPrice()}</div>
          </div>
        )}
      </main>

      {/* STICKY FOOTER MOBILE */}
      {/* <div className="block lg:hidden fixed bottom-0 inset-x-0 py-4 bg-white text-neutral-900 border-t border-neutral-200 z-20">
        <div className="container flex items-center justify-between">
          <span className="text-2xl font-semibold">
            {car.price}
            <span className="ml-1 text-base font-normal text-neutral-500 dark:text-neutral-400">
              /day
            </span>
          </span>

          <ButtonPrimary href="##">Reserve</ButtonPrimary>
        </div>
      </div> */}

      {/* OTHER SECTION */}
      <div className="container py-24 lg:py-32">
        {/* SECTION 1 */}
        <div className="relative py-16">
          <BackgroundSection />
          <SectionSliderNewCategories
            heading="Explore by types of stays"
            subHeading="Explore houses based on 10 types of stays"
            categoryCardType="card5"
            itemPerRow={5}
            sliderStyle="style2"
          />
        </div>

        {/* SECTION */}
        <SectionSubscribe2 className="pt-24 lg:pt-32" />
      </div>
    </div>
  );
};

export default ListingCarDetailPage;
