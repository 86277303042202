import { FC } from "react";
import { Helmet } from "react-helmet";
import { useHistory } from "react-router";
import ButtonPrimary from "shared/Button/ButtonPrimary";

export interface PageLoginProps {
  className?: string;
}

const PageRegisterConfirmation: FC<PageLoginProps> = ({ className = "" }) => {
  const history = useHistory();
  const source: any = history.location.state;

  console.log("source", source);

  return (
    <div className={`nc-PageLogin ${className}`} data-nc-id="PageLogin">
      <Helmet>
        <title>Register Confirmation || Holiday Short Stays</title>
      </Helmet>
      {source && source.source === "registration" && (
        <div className="container mb-24 lg:mb-32">
          <h2 className="my-20 flex items-center text-xl leading-[115%] md:text-2xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
            Your registration has been received.
          </h2>
          <div className="text-xl max-w-md mx-auto space-y-6">
            <div className="mb-8">
              Thank you for registering to join us as a property host on Holiday
              Short Stays.
            </div>
            <div className="mb-8">
              Kindly check your email and click the Veriy Email link to complete
              your registration
            </div>
          </div>
        </div>
      )}

      {source && source.source === "confirmation" && (
        <div className="container mb-24 lg:mb-32">
          <h2 className="my-20 flex items-center text-xl leading-[115%] md:text-2xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
            Email Confirmed Successfully
          </h2>
          <div className="text-xl flex flex-col items-center max-w-md mx-auto space-y-6">
            <div className="mb-8">
              You have successfully confirmed your email. You can now login to
              proceed with posting your apartments, cars and boats for rental
              purposes.
            </div>
            <div className="mb-8">
              <ButtonPrimary className="px-4" href="/login">
                Proceed to Login
              </ButtonPrimary>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PageRegisterConfirmation;
