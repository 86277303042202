import Loader from "react-loader-spinner";

export const ShowLoading = () => (
  <div className="flex justify-center">
    <Loader type="ThreeDots" color="#000000" height={70} width={70} />
  </div>
);

ShowLoading.defaultProps = {
  type: "ThreeDots",
  color: "#fff",
  width: "70",
  height: "70",
  customClass: "20",
};
