import { OwnerType } from "data/types";
import React, { FC } from "react";
import facebookIcon from "images/logos/facebook.png";
import instagramIcon from "images/logos/instagram.png";
import twitterIcon from "images/logos/twitter.png";
import YouTubeIcon from "images/logos/youtube.png";
import webIcon from "images/logos/web.png";
import { Link } from "react-router-dom";

export interface SocialRowProps {
  socials: {
    facebook?: string;
    twitter?: string;
    instagram?: string;
    youtube?: string;
    web?: string;
  };
}

const SocialRow: FC<SocialRowProps> = ({ socials }) => {
  return (
    <div className={`nc-SocialRow`} data-nc-id="SocialRow">
      <div className="flex flex-row space-x-4">
        {socials && socials.instagram && (
          <Link
            to={{ pathname: `https://instagram.com/${socials.instagram}` }}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              className={`block w-6`}
              src={instagramIcon}
              alt={socials.instagram}
            />
          </Link>
        )}

        {socials && socials.facebook && (
          <Link
            to={{ pathname: socials.facebook }}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              className={`block w-6`}
              src={facebookIcon}
              alt={socials.facebook}
            />
          </Link>
        )}

        {socials && socials.twitter && (
          <Link
            to={{ pathname: `https://www.twitter.com/${socials.twitter}` }}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              className={`block w-6`}
              src={twitterIcon}
              alt={socials.twitter}
            />
          </Link>
        )}

        {socials && socials.youtube && (
          <Link
            to={{ pathname: `https://www.youtube.com/${socials.youtube}` }}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              className={`block w-6`}
              src={YouTubeIcon}
              alt={socials.youtube}
            />
          </Link>
        )}

        {socials && socials.web && (
          <Link
            to={{ pathname: socials.web }}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img className={`block w-6`} src={webIcon} alt={socials.web} />
          </Link>
        )}
      </div>
    </div>
  );
};

export default SocialRow;
