import { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Input from "shared/Input/Input";
import Select from "shared/Select/Select";
import FormItem from "./FormItem";
import { RootState } from "redux/reducers";
import Textarea from "shared/Textarea/Textarea";
import LoadingGif from "./../../images/loading-gif.svg";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import cogoToast from "cogo-toast";
import { useHistory } from "react-router-dom";
import { ButtonLoader } from "components/ButtonLoader.jsx";
import {
  addFeaturedImage,
  addOtherImages,
  publishBoatAction,
} from "redux/actions/boatActions";

export interface PageAddListing1Props {}

const PageListBoat: FC<PageAddListing1Props> = () => {
  const dispatch = useDispatch();
  const boats: any = useSelector((store: RootState) => store.boats);
  const auth = useSelector((store: RootState) => store.auth);
  const history = useHistory();

  const carConditions = ["Tokunbo", "Brand New", "Faily Used", "Second Hand"];

  const [state, setState] = useState({
    title: "",
    guest_capacity: "",
    no_cabins: "",
    beds: "",
    bathrooms: "",
    description: "",
    party_allowed: "",
    price: "",
    featured_image: "",
    boat_images: [],
  });

  console.log("boats", boats);

  const selectChange = (event: React.FormEvent<HTMLSelectElement>) => {
    const newValue: any = {};
    newValue[event.currentTarget.name] = event.currentTarget.value;

    console.log("newValue", newValue);
    setState({ ...state, ...newValue });
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    console.log("handlechange");
    const newValue: any = {};
    newValue[e.target.name] = e.target.value;

    setState({ ...state, ...newValue });
  };

  const handleChangeTextArea = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newValue: any = {};
    newValue[e.target.name] = e.target.value;
    setState({ ...state, ...newValue });
  };

  //  Images Upload

  const handleChangeFeatured = async (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const fileList = e.target.files;
    if (!fileList) return;
    addFeaturedImage(fileList[0], dispatch);
  };

  const handleChangeOthers = (e: React.ChangeEvent<HTMLInputElement>) => {
    const fileList = e.target.files;
    if (!fileList) return;
    addOtherImages(fileList, dispatch);
  };

  const processUploadBoat = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    const user_id = auth.userData.user_id ?? auth.userData._id;

    const publishCar = async () => {
      const { type, payload: message } = await publishBoatAction(
        state,
        user_id,
        boats.featured_image,
        boats.other_imgs,
        dispatch
      );
      console.log("Type+Message", type, message);
      if (type === "ERROR") {
        cogoToast.error(message, {
          position: "top-right",
          hideAfter: 5,
        });
      } else {
        cogoToast.success("Listing Successfully Submitted", {
          position: "top-right",
          hideAfter: 5,
        });
        // redirect to success page
        history.push({
          pathname: "/listing-published",
          state: { property: "boat", post_url: "list-boat" },
        });

        // clear state
      }
    };

    publishCar();
  };

  return (
    <form name="listCarForm" method="POST" onSubmit={processUploadBoat}>
      <div
        className={`nc-PageAddListing1 px-4 max-w-3xl mx-auto pb-24 pt-14 sm:py-24 lg:pb-32`}
        data-nc-id="PageAddListing1"
      >
        <div className="space-y-11">
          <div>
            <span className="text-4xl font-semibold">
              Enlist a Boat on Holiday Short Stays
            </span>
          </div>

          {/* --------------------- */}
          <div className="listingSection__wrap ">
            {/* FORM */}
            <div className="space-y-8">
              <FormItem label="Title of Boat">
                <Input
                  placeholder="title"
                  name="title"
                  onChange={handleChange}
                  value={state.title}
                />
              </FormItem>

              <div className="grid grid-cols-1 md:grid-cols-2 gap-8 md:gap-5">
                <FormItem label="Guest Capacity">
                  <Input
                    name="guest_capacity"
                    type="number"
                    onChange={handleChange}
                    value={state.guest_capacity}
                  />
                </FormItem>
                <FormItem label="No. of Cabins">
                  <Input
                    name="no_cabins"
                    type="number"
                    onChange={handleChange}
                    value={state.no_cabins}
                  />
                </FormItem>
              </div>

              <div className="grid grid-cols-1 md:grid-cols-2 gap-8 md:gap-5">
                <FormItem label="No of Beds">
                  <Input
                    name="beds"
                    type="number"
                    onChange={handleChange}
                    value={state.beds}
                  />
                </FormItem>
                <FormItem label="No. of Bathrooms">
                  <Input
                    name="bathrooms"
                    type="number"
                    onChange={handleChange}
                    value={state.bathrooms}
                  />
                </FormItem>
              </div>

              <FormItem label="Short Description of the Boat">
                <Textarea
                  placeholder="..."
                  rows={3}
                  name="description"
                  value={state.description}
                  onChange={handleChangeTextArea}
                />
              </FormItem>

              <div className="grid grid-cols-1 md:grid-cols-2 gap-8 md:gap-5">
                <FormItem label="Rental Price per hour">
                  <div className="relative">
                    <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                      <span className="text-gray-500">&#8358;</span>
                    </div>
                    <Input
                      className="!pl-8 !pr-10"
                      placeholder="0.00"
                      name="price"
                      type="number"
                      value={state.price}
                      onChange={handleChange}
                    />
                    <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                      <span className="text-gray-500">NGN</span>
                    </div>
                  </div>
                </FormItem>

                <FormItem label="Is Party Allowed on the boat?">
                  <Select
                    name="party_allowed"
                    onChange={selectChange}
                    defaultValue="yes"
                  >
                    <option key="yes" value="yes">
                      Yes
                    </option>
                    <option key="no" value="no">
                      No
                    </option>
                  </Select>
                </FormItem>
              </div>
            </div>

            <>
              <div>
                <span className="text-lg font-semibold">
                  Upload Cover image
                </span>
                <div className="mt-5 ">
                  <div>
                    <input
                      className="block w-full pt-0 pb-0 h-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 cursor-pointer dark:text-gray-400 focus:outline-none focus:border-transparent dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
                      aria-describedby="featured_image_help"
                      id="featured_image"
                      name="featured_image"
                      type="file"
                      accept="image/*"
                      onChange={handleChangeFeatured}
                    />
                    <div
                      className="mt-1 text-sm text-gray-500 dark:text-gray-300"
                      id="featured_image_help"
                    >
                      Only one picture allowed
                    </div>
                  </div>
                </div>
                <div className="grid grid-cols-6 gap-4 mt-4">
                  {boats && boats["featured_image"] ? (
                    <span>
                      <img src={boats["featured_image"]} alt="feat_img" />
                    </span>
                  ) : null}
                  {boats && boats["loadingFeatured"] ? (
                    <span>
                      <img src={LoadingGif} alt="Loading" />
                    </span>
                  ) : null}
                </div>
              </div>
              {/* ----------------- */}
              <div className="w-48 border-b border-neutral-200 dark:border-neutral-700"></div>
              <div>
                <span className="text-lg font-semibold">
                  Upload Pictures of the car
                </span>
                <div className="mt-5 ">
                  <input
                    className="block w-full h-full pt-0 pb-0 text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 cursor-pointer dark:text-gray-400 focus:outline-none focus:border-transparent dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
                    aria-describedby="car_images_help"
                    id="car_images"
                    type="file"
                    name="car_images"
                    onChange={handleChangeOthers}
                    accept="image/*"
                    multiple
                  />
                  <div
                    className="mt-1 text-sm text-gray-500 dark:text-gray-300"
                    id="car_images_help"
                  >
                    Multiple picutures are allowed. Minimum of 5. Maximum of 10
                  </div>
                </div>
                <div className="grid grid-cols-6 gap-4 mt-4">
                  {boats && boats["other_imgs"]
                    ? boats["other_imgs"].map(
                        (otherimg: string, index: number) => (
                          <span key={index}>
                            <img src={otherimg} alt="other_img" />
                          </span>
                        )
                      )
                    : null}
                  {boats && boats["loadingOtherImages"] ? (
                    <span>
                      <img src={LoadingGif} alt="Loading" />
                    </span>
                  ) : null}
                </div>
              </div>
            </>
          </div>

          {/* --------------------- */}
          <div className="flex justify-end space-x-5">
            <ButtonPrimary
              type="submit"
              disabled={
                state.title !== "" &&
                state.guest_capacity !== "" &&
                state.price !== ""
                  ? false
                  : true
              }
            >
              {boats.loading ? <ButtonLoader /> : "Complete Boat Listing"}
            </ButtonPrimary>
          </div>
        </div>
      </div>
    </form>
  );
};

export default PageListBoat;
