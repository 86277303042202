import { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Input from "shared/Input/Input";
import Select from "shared/Select/Select";
import FormItem from "./FormItem";
import { RootState } from "redux/reducers";
import Textarea from "shared/Textarea/Textarea";
import LoadingGif from "./../../images/loading-gif.svg";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import cogoToast from "cogo-toast";
import { useHistory, useParams } from "react-router-dom";
import { ButtonLoader } from "components/ButtonLoader.jsx";
import {
  addFeaturedImage,
  addOtherImages,
  getCustomLocations,
} from "redux/actions/listingActions";
import {
  getSingleApartment,
  updateApartmentAction,
} from "redux/actions/listActions";

export interface PageAddListing1Props {}

export interface SingleApartmentProps {
  apartmentid: string;
  slug: string;
}

const PageEditApartment: FC<PageAddListing1Props> = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const auth = useSelector((store: RootState) => store.auth);
  const listing = useSelector((store: RootState) => store.listing);
  const { loading, apartment } = useSelector((state: RootState) => state.list);

  console.log("apartment", apartment);

  const [state, setState] = useState({
    loading: false,
    loadingFeatured: false,
    loadingOtherImages: false,
    title: "",
    apartment_type: "",
    location: "",
    sublocation: "",
    max_guests: 2,
    min_nights: 3,
    bedrooms: 0,
    bathrooms: 0,
    description: "",
    price: "",
    featured_img: "",
    gallery_imgs: [],
  });

  const { apartmentid } = useParams<SingleApartmentProps>();

  // console.log("apartment", apartment);

  const selectChange = (event: React.FormEvent<HTMLSelectElement>) => {
    const newValue: any = {};
    newValue[event.currentTarget.name] = event.currentTarget.value;

    console.log("newValue", newValue);
    setState({ ...state, ...newValue });
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    console.log("handlechange");
    const newValue: any = {};
    newValue[e.target.name] = e.target.value;

    setState({ ...state, ...newValue });
  };

  const handleChangeTextArea = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newValue: any = {};
    newValue[e.target.name] = e.target.value;
    setState({ ...state, ...newValue });
  };

  const selectChangeLocation = (event: React.FormEvent<HTMLSelectElement>) => {
    const newValue: any = {};
    newValue[event.currentTarget.name] = event.currentTarget.value;
    console.log("New value", newValue);
    setState({ ...state, ...newValue });
  };

  useEffect(() => {
    async function getLocations() {
      await getCustomLocations(dispatch);
    }

    const getApartmentDetails = async () => {
      await getSingleApartment(apartmentid, "", dispatch);
    };

    getLocations();
    getApartmentDetails();
  }, []);

  useEffect(() => {
    if (apartment) {
      const dataLocation = apartment?.location?.location;
      apartment["location"] = dataLocation;
      setState({ ...state, ...apartment });
    }
  }, [apartment]);

  const processUpdateApartment = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    // const user_id = auth.userData.user_id ?? auth.userData._id;
    setState({ ...state, loading: true });

    console.log("STATE", state);

    const updateApartment = async () => {
      const { type, payload } = await updateApartmentAction(
        state,
        apartmentid,
        dispatch
      );
      console.log("Type + message", type, payload);
      if (type === "ERROR") {
        cogoToast.error(payload, {
          position: "top-right",
          hideAfter: 5,
        });
        setState({ ...state, loading: false });
      } else {
        cogoToast.success("Apartment Listing Update Submitted", {
          position: "top-right",
          hideAfter: 5,
        });
        setState({ ...state, loading: false });
      }
    };

    updateApartment();
  };

  //  Images Upload
  const handleChangeFeatured = async (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setState({ ...state, ...{ loadingFeatured: true } });

    const fileList = e.target.files;
    if (!fileList) return;

    const featuredImgUrl = await addFeaturedImage(
      fileList[0],
      "apartment",
      dispatch
    );
    console.log("featuredImgUrl ->", featuredImgUrl);
    const fImg: any = {
      featured_img: featuredImgUrl,
      loadingFeatured: false,
    };
    setState({
      ...state,
      ...fImg,
    });
  };

  const handleChangeOthers = async (e: React.ChangeEvent<HTMLInputElement>) => {
    setState({ ...state, ...{ loadingOtherImages: true } });

    const fileList = e.target.files;
    if (!fileList) return;

    const otherImages: any = await addOtherImages(fileList, dispatch);
    const oImgs: any = {
      gallery_imgs: [...state.gallery_imgs, ...otherImages],
      loadingOtherImages: false,
    };

    setState({
      ...state,
      ...oImgs,
    });
  };

  return (
    <form name="listCarForm" method="POST" onSubmit={processUpdateApartment}>
      {!loading && apartment && (
        <div
          className={`nc-PageAddListing1 px-4 max-w-3xl mx-auto pb-24 pt-14 sm:py-24 lg:pb-32`}
          data-nc-id="PageAddListing1"
        >
          <div className="space-y-11">
            <div>
              <span className="text-3xl font-semibold">Edit Apartment: </span>{" "}
              <span className="text-3xl">{state.title}</span>
            </div>

            {/* --------------------- */}
            <div className="listingSection__wrap ">
              {/* FORM */}
              <div className="space-y-8">
                <FormItem label="Type of Apartment">
                  <Select
                    name="apartment_type"
                    onChange={selectChange}
                    defaultValue={state.apartment_type ?? "select-apartment"}
                  >
                    <option value="select-apartment" disabled>
                      --Select Apartment Type--
                    </option>
                    <option value="Beach House">Beach House</option>
                    <option value="Condo">Condo</option>
                    <option value="Duplex">Duplex</option>
                    <option value="Flat">Flat</option>
                    <option value="Garden">Garden</option>
                    <option value="Highrise">Highrise</option>
                    <option value="Penthouse">Penthouse</option>
                    <option value="Studio Apartment">Studio Apartment</option>
                  </Select>
                </FormItem>
                <FormItem label="Title of Boat">
                  <Input
                    placeholder="title"
                    name="title"
                    onChange={handleChange}
                    value={state.title}
                  />
                </FormItem>

                <div className="space-y-8">
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-8 md:gap-5">
                    <FormItem label="Location">
                      <Select
                        name="location"
                        onChange={selectChangeLocation}
                        defaultValue={state.location}
                      >
                        <option value="--Select Location--">
                          --Select Location--
                        </option>
                        {listing &&
                          listing.customLocations.map((eachLocation: any) => (
                            <option
                              key={eachLocation.slug}
                              value={eachLocation._id}
                              data-slug={eachLocation.state}
                              selected={
                                eachLocation.location === state.location
                              }
                            >
                              {eachLocation.location}
                            </option>
                          ))}
                      </Select>
                    </FormItem>
                    <FormItem label="Sub Location">
                      <Input
                        placeholder="Sub Location"
                        name="sublocation"
                        onChange={handleChange}
                        value={state.sublocation}
                      />
                    </FormItem>
                  </div>
                </div>

                <div className="grid grid-cols-1 md:grid-cols-2 gap-8 md:gap-5">
                  <FormItem label="Guest Capacity">
                    <Input
                      name="max_guests"
                      type="number"
                      onChange={handleChange}
                      value={state.max_guests}
                    />
                  </FormItem>
                  <FormItem label="Min Nights">
                    <Input
                      name="min_nights"
                      type="number"
                      onChange={handleChange}
                      value={state.min_nights}
                    />
                  </FormItem>
                </div>

                <div className="grid grid-cols-1 md:grid-cols-2 gap-8 md:gap-5">
                  <FormItem label="No of Beds">
                    <Input
                      name="bedrooms"
                      type="number"
                      onChange={handleChange}
                      value={state.bedrooms}
                    />
                  </FormItem>
                  <FormItem label="No. of Bathrooms">
                    <Input
                      name="bathrooms"
                      type="number"
                      onChange={handleChange}
                      value={state.bathrooms}
                    />
                  </FormItem>
                </div>

                <FormItem label="Description of Apartment">
                  <Textarea
                    placeholder="..."
                    rows={3}
                    name="description"
                    value={state.description}
                    onChange={handleChangeTextArea}
                  />
                </FormItem>

                <div className="grid grid-cols-1 md:grid-cols-1 gap-8 md:gap-5">
                  <FormItem label="Price per night">
                    <div className="relative">
                      <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                        <span className="text-gray-500">&#8358;</span>
                      </div>
                      <Input
                        className="!pl-8 !pr-10"
                        placeholder="0.00"
                        name="price"
                        type="number"
                        value={state.price}
                        onChange={handleChange}
                      />
                      <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                        <span className="text-gray-500">NGN</span>
                      </div>
                    </div>
                  </FormItem>
                </div>
              </div>

              <>
                <div>
                  <span className="text-lg font-semibold">
                    Upload Cover image
                  </span>
                  <div className="mt-5 ">
                    <div>
                      <input
                        className="block w-full pt-0 pb-0 h-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 cursor-pointer dark:text-gray-400 focus:outline-none focus:border-transparent dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
                        aria-describedby="featured_image_help"
                        id="featured_img"
                        name="featured_img"
                        type="file"
                        accept="image/*"
                        onChange={handleChangeFeatured}
                      />
                      <div
                        className="mt-1 text-sm text-gray-500 dark:text-gray-300"
                        id="featured_image_help"
                      >
                        Only one picture allowed
                      </div>
                    </div>
                  </div>
                  <div className="grid grid-cols-6 gap-4 mt-4">
                    {state.featured_img ? (
                      <span>
                        <img src={state.featured_img} alt="feat_img" />
                      </span>
                    ) : null}
                    {state.loadingFeatured ? (
                      <span>
                        <img src={LoadingGif} alt="Loading" />
                      </span>
                    ) : null}
                  </div>
                </div>
                {/* ----------------- */}
                <div className="w-48 border-b border-neutral-200 dark:border-neutral-700"></div>
                <div>
                  <span className="text-lg font-semibold">
                    Upload Pictures of the car
                  </span>
                  <div className="mt-5 ">
                    <input
                      className="block w-full h-full pt-0 pb-0 text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 cursor-pointer dark:text-gray-400 focus:outline-none focus:border-transparent dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
                      aria-describedby="car_images_help"
                      id="car_images"
                      type="file"
                      name="car_images"
                      onChange={handleChangeOthers}
                      accept="image/*"
                      multiple
                    />
                    <div
                      className="mt-1 text-sm text-gray-500 dark:text-gray-300"
                      id="car_images_help"
                    >
                      Multiple picutures are allowed. Minimum of 5. Maximum of
                      10
                    </div>
                  </div>
                  <div className="grid grid-cols-6 gap-4 mt-4">
                    {state["gallery_imgs"]
                      ? state.gallery_imgs.map(
                          (otherimg: string, index: number) => (
                            <span key={index}>
                              <img src={otherimg} alt="other_img" />
                            </span>
                          )
                        )
                      : null}
                    {state.loadingOtherImages ? (
                      <span>
                        <img src={LoadingGif} alt="Loading" />
                      </span>
                    ) : null}
                  </div>
                </div>
              </>
            </div>

            {/* --------------------- */}
            <div className="flex justify-end space-x-5">
              <ButtonPrimary
                type="submit"
                disabled={
                  state.title !== "" &&
                  state.max_guests > 1 &&
                  state.price !== ""
                    ? false
                    : true
                }
              >
                {state.loading ? <ButtonLoader /> : "Update Apartment"}
              </ButtonPrimary>
            </div>
          </div>
        </div>
      )}
    </form>
  );
};

export default PageEditApartment;
