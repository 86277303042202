import { carConstants } from "../actionTypes";

const INITIAL_STATE = {
  loading: false,
  loadingFeatured: false,
  loadingOtherImages: false,
  fetching: false,
  featured_image: "",
  other_imgs: [],
  error: "",
  cars: [],
  carDetails: {},
  mycars: [],
  makes: [],
  models: [],
  count: 0,
  pagination: {},
  currentPage: 0,
  booked: {},
  ownerscount: 0,
};

const carReducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case carConstants.LOADING:
      return {
        ...state,
        loading: true,
      };
    case carConstants.STOP_LOADING:
      return {
        ...state,
        loading: false,
      };
    case carConstants.LOADING_FEATURED:
      return {
        ...state,
        loadingFeatured: true,
      };
    case carConstants.LOADING_OTHER_IMAGES:
      return {
        ...state,
        loadingOtherImages: true,
      };
    case carConstants.GET_MAKES:
      return {
        ...state,
        makes: payload,
      };
    case carConstants.GET_MODELS:
      console.log("models-payload", payload);
      return {
        ...state,
        models: payload,
      };
    case carConstants.GET_CARS:
      return {
        ...state,
        fetching: false,
        cars: payload.cars,
        count: payload.count,
        pagination: payload.pagination,
      };
    case carConstants.GET_MORE_CARS:
      return {
        ...state,
        fetching: false,
        cars: [...state.cars, ...payload.cars],
        count: payload.total,
        pagination: payload.pagination,
      };
    case carConstants.GET_MY_CARS:
      return {
        ...state,
        fetching: false,
        mycars: payload,
      };
    case carConstants.GET_CAR_DETAILS:
      return {
        ...state,
        fetching: false,
        car: payload.car,
        ownerscount: payload.ownerscount,
      };
    case carConstants.ADD_FEATURED_IMAGE:
      return {
        ...state,
        featured_image: payload,
        loadingFeatured: false,
      };
    case carConstants.ADD_OTHER_IMAGES:
      return {
        ...state,
        other_imgs: [...state.other_imgs, payload],
        loadingOtherImages: false,
      };
    case carConstants.POST_CAR:
      return {
        ...state,
        loading: false,
        posted: true,
        loadingFeatured: false,
        loadingOtherImages: false,
        featured_image: "",
        other_imgs: [],
      };
    case carConstants.BOOK_CAR:
      return {
        ...INITIAL_STATE,
        loading: false,
        booked: payload,
      };
    case carConstants.DELETE_CAR:
      const newcars = state.mycars.filter((thecars) => thecars._id !== payload);

      return {
        ...INITIAL_STATE,
        loading: false,
        mycars: [...newcars],
      };
    case carConstants.ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
      };
    case carConstants.RESET_LISTING:
      return {
        ...state,
        loadingFeatured: false,
        loading: false,
        featured_image: "",
        other_imgs: [],
        cars: [],
      };
    default:
      return state;
  }
};

export default carReducer;
