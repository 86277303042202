import React, { FC, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import CommonLayout from "./CommonLayout";
import {
  fetchAmenities,
  updateChosenAmenity,
} from "../../redux/actions/listingActions";
import { RootState } from "redux/reducers";

export interface PageAddListing3Props {}

const PageAddListing3: FC<PageAddListing3Props> = () => {
  const dispatch = useDispatch();
  const listing = useSelector((store: RootState) => store.listing);

  console.log("listing", listing);

  const handleChange = (
    name: string,
    id: string,
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    // console.log("clicked", e);
    const status = e.target.checked;
    // const item = { amenity: name, id };
    const amenity_id = id;

    updateChosenAmenity(amenity_id, status, dispatch);
  };

  useEffect(() => {
    fetchAmenities(dispatch);
  }, []);

  const isSelected = (id: string) => {
    return listing.chosen_amenities.find((x: any) => x.id === id);
  };

  return (
    <CommonLayout
      index="03"
      backtHref="/add-listing-2"
      nextHref="/add-listing-4"
    >
      <>
        <div>
          <h2 className="text-2xl font-semibold">Amenities </h2>
          <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
            Many customers have searched for accommodation based on amenities
            criteria
          </span>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        {/* FORM */}
        <div className="space-y-8">
          {/* ITEM */}
          <div>
            <label className="text-lg font-semibold" htmlFor="">
              General amenities
            </label>
            <div className="mt-6 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-5">
              {listing.loading ? "Loading Amenities..." : ""}
              {listing && listing.amenities
                ? listing.amenities.map((list: any, index: number) => (
                    <div className="flex text-sm sm:text-base" key={index}>
                      <input
                        id={list.name}
                        name={list.name}
                        type="checkbox"
                        className="focus:ring-action-primary h-6 w-6 text-primary-500 border-primary rounded border-neutral-500 bg-white dark:bg-neutral-700  dark:checked:bg-primary-500 focus:ring-primary-500"
                        onChange={(e) => handleChange(list.name, list._id, e)}
                        checked={isSelected(list._id)}
                        value={isSelected(list._id)}
                      />
                      <label
                        htmlFor={list.name}
                        className="ml-3.5 flex flex-col flex-1 justify-center"
                      >
                        <span className=" text-neutral-900 dark:text-neutral-100">
                          {list.name}
                        </span>
                      </label>
                    </div>
                  ))
                : null}
            </div>
          </div>
        </div>
      </>
    </CommonLayout>
  );
};

export default PageAddListing3;
