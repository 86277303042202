import cogoToast from "cogo-toast";
import ApartmentCard from "components/ApartmentCard/ApartmentCard";
import { ButtonLoader } from "components/ButtonLoader.jsx";
import { FC } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { publishApartmentAction } from "redux/actions/listingActions";
import { RootState } from "redux/reducers";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";

export interface PageAddListing8Props {}

const PageAddListing8: FC<PageAddListing8Props> = () => {
  const dispatch = useDispatch();
  const listing = useSelector((store: RootState) => store.listing);
  const auth = useSelector((store: RootState) => store.auth);
  const history = useHistory();

  if (listing.min_nights === 0) {
    history.push("/add-listing-7");
  }

  const loading = listing.loading;

  console.log("listing", listing);
  console.log("auth", auth);

  const details = {
    authorId: auth.userData.user_id,
    href: "/listing-stay-detail",
    listingCategoryId: listing.apartment_type,
    title: listing.title,
    apartment_type: listing.apartment_type,
    featured_img: listing.featured_img,
    gallery_imgs: listing.other_imgs,
    like: false,
    address: `${listing.lga}, ${listing.state}`,
    price: listing.price,
    maxGuests: listing.no_guests,
    bedrooms: listing.no_bedrooms,
    bathrooms: listing.no_bathrooms,
    isAds: false,
    listingCategory: listing.apartment_type,
  };

  console.log("Details", details);

  // publish apartment...
  const publishApartment = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    console.log("publish apartment");

    const user_id = auth.userData.user_id ?? auth.userData._id;

    console.log("Listing", listing);

    const publishApt = async () => {
      const { type, payload: message } = await publishApartmentAction(
        listing,
        user_id,
        dispatch
      );
      console.log("Type+Message", type, message);
      if (type === "ERROR") {
        cogoToast.error(message, {
          position: "top-right",
          hideAfter: 5,
        });
      } else {
        cogoToast.success("Listing Successfully Submitted", {
          position: "top-right",
          hideAfter: 5,
        });
        // redirect to success page
        history.push({
          pathname: "/listing-published",
          state: { property: "apartment", post_url: "add-listing" },
        });

        // clear state
      }
    };

    publishApt();
  };

  return (
    <div
      className={`nc-PageAddListing1 px-4 max-w-3xl mx-auto pb-24 pt-14 sm:py-24 lg:pb-32`}
      data-nc-id="PageAddListing1"
    >
      <div className="space-y-11">
        <div>
          <span className="text-4xl font-semibold">8</span>{" "}
          <span className="text-lg text-neutral-500 dark:text-neutral-400">
            / 8
          </span>
        </div>

        {/* --------------------- */}
        <form method="post" onSubmit={publishApartment}>
          <div className="listingSection__wrap ">
            <>
              <div>
                <h2 className="text-2xl font-semibold">Almost Done 🔥</h2>
                <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
                  Excellent, weldone on completing the listing process.
                </span>
              </div>
              <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
              {/* FORM */}
              <div>
                <h3 className="text-lg font-semibold">
                  How your listing will look
                </h3>
                <div className="max-w-xs">
                  {listing && (
                    <ApartmentCard
                      className="mt-8"
                      data={{ ...details, reviewStart: 0 }}
                    />
                  )}
                </div>
                <div className="flex items-center space-x-5 mt-8">
                  <ButtonSecondary href="/add-listing">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-5 w-5"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={1.5}
                        d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
                      />
                    </svg>
                    <span className="ml-3">Edit</span>
                  </ButtonSecondary>

                  <ButtonPrimary>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-5 w-5"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={1.5}
                        d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                      />
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={1.5}
                        d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
                      />
                    </svg>
                    <span className="ml-3">Preview</span>
                  </ButtonPrimary>
                </div>
              </div>
              {/*  */}
            </>
          </div>

          {/* --------------------- */}
          <div className="flex justify-end space-x-5 mt-5">
            <ButtonPrimary type="submit">
              {loading ? <ButtonLoader /> : "Publish Listing"}
            </ButtonPrimary>
          </div>
        </form>
      </div>
    </div>
  );
};

export default PageAddListing8;
