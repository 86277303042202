export const authConstants = {
  ERROR: "ERROR",

  AUTH_LOADING: "AUTH_LOADING",
  STOP_AUTH_LOADING: "STOP_AUTH_LOADING",
  LOGIN_USER: "LOGIN_USER",
  REGISTER_USER: "REGISTER_USER",

  GET_STATES: "GET_STATES",
};

// ======== PROFILE =====================
export const profileConstants = {
  LOADING: "LOADING",
  STOP_LOADING: "STOP_LOADING",
  ERROR: "ERROR",

  GET_PROFILE: "GET_PROFILE",
  EDIT_PROFILE: "EDIT_PROFILE",
  UPDATE_PROFILE: "UPDATE_PROFILE",
  CHANGE_PASSWORD: "CHANGE_PASSWORD",
  PROFILE_LOADING: "PROFILE_LOADING",

  LOADING_PICTURE: "LOADING_PICTURE",
  STOP_LOADING_PICTURE: "STOP_LOADING_PICTURE",
};

// ======== LISTING =====================
export const listingConstants = {
  LOADING: "LOADING",
  STOP_LOADING: "STOP_LOADING",
  ERROR: "ERROR",

  LOADING_FEATURED: "LOADING_FEATURED",
  LOADING_OTHERS: "LOADING_OTHERS",

  GET_STATES: "GET_STATES",
  GET_LGAS: "GET_LGAS",
  UPDATE_LISTING: "UPDATE_LISTING",
  ADD_ADDITIONAL_RULE: "ADD_ADDITIONAL_RULE",
  REMOVE_ADDITIONAL_RULE: "REMOVE_ADDITIONAL_RULE",
  ADD_FEATURED_IMAGE: "ADD_FEATURED_IMAGE",
  ADD_OTHER_IMAGES: "ADD_OTHER_IMAGES",
  GET_AMENITIES: "GET_AMENITIES",
  ADD_CHOSEN_AMENITY: "ADD_CHOSEN_AMENITY",
  REMOVE_CHOSEN_AMENITY: "REMOVE_CHOSEN_AMENITY",
  UPDATE_RULE: "UPDATE_RULE",
  POST_APARTMENT: "POST_APARTMNET",

  GET_LOCATIONS: "GET_LOCATIONS",
};

// ======== LISTS =====================
export const listConstants = {
  LOADING: "LOADING",
  STOP_LOADING: "STOP_LOADING",
  FETCHING: "FETCHING",
  STOP_FETCHING: "STOP_FETCHING",

  ERROR: "ERROR",

  FETCH_MY_APARTMENTS: "FETCH_MY_APARTMENTS",
  NO_APARTMENT: "NO_APARTMENT",
  GET_SINGLE_APARTMENT: "GET_SINGLE_APARTMENT",
  FETCH_APARTMENTS_LOCATION: "FETCH_APARTMENTS_LOCATION",
  FETCH_MORE_APARTMENTS_LOCATION: "FETCH_MORE_APARTMENTS_LOCATION",
  FETCH_SINGLE_LOCATION: "FETCH_SINGLE_LOCATION",
  FETCH_FEATURED_APARTMENTS: "FETCH_FEATURED_APARTMENTS",
  DELETE_APARTMENT: "DELETE_APARTMENT",
};

// ======== BOOKINGS =====================
export const bookingConstants = {
  LOADING: "LOADING",
  STOP_LOADING: "STOP_LOADING",
  ERROR: "ERROR",

  SET_ORDER: "SET_ORDER",
  CREATE_BOOKING: "CREATE_BOOKING",
  RESET_BOOKING: "RESET_BOOKING",

  PAYMENT_SUCCESS: "PAYMENT_SUCCESS",
  PAYMENT_FAILURE: "PAYMENT_FAILURE",

  GET_MY_BOOKINGS: "GET_MY_BOOKINGS",

  SUBMIT_INQUIRY: "SUBMIT_INQUIRY",

  SEARCH_RESULT: "SEARCH_RESULT",
  PROPERTIES_BY_HOST: "PROPERTIES_BY_HOST",
};

// ======== SHORTLETS =====================
export const shortletsConstants = {
  LOADING: "LOADING",
  STOP_LOADING: "STOP_LOADING",
  ERROR: "ERROR",

  GET_SHORTLETS: "GET_SHORTLETS",
  SET_CURRENT_PAGE: "SET_CURRENT_PAGE",
  GET_MORE_SHORTLETS: "GET_MORE_SHORTLETS",
};

// ======== HOTELS =====================
export const hotelConstants = {
  LOADING: "LOADING",
  STOP_LOADING: "STOP_LOADING",
  ERROR: "ERROR",

  FETCHING: "FETCHING",
  STOP_FETCHING: "STOP_FETCHING",

  LOADING_FEATURED: "LOADING_FEATURED",
  LOADING_OTHER_IMAGES: "LOADING_OTHER_IMAGES",

  ADD_FEATURED_IMAGE: "ADD_FEATURED_IMAGE",
  ADD_OTHER_IMAGES: "ADD_OTHER_IMAGES",

  FETCH_HOTELS: "FETCH_HOTELS",
  FETCH_MORE_HOTELS: "FETCH_MORE_HOTELS",

  GET_MY_HOTELS: "GET_MY_HOTELS",
  SET_CURRENT_PAGE: "SET_CURRENT_PAGE",
  NO_HOTEL: "NO_HOTEL",

  GET_HOTEL_DETAILS: "GET_HOTEL_DETAILS",

  POST_HOTEL: "POST_HOTEL",
};

// ======== CARS =====================
export const carConstants = {
  LOADING: "LOADING",
  STOP_LOADING: "STOP_LOADING",
  ERROR: "ERROR",

  FETCHING: "FETCHING",
  STOP_FETCHING: "STOP_FETCHING",

  LOADING_FEATURED: "LOADING_FEATURED",
  LOADING_OTHER_IMAGES: "LOADING_OTHER_IMAGES",

  ADD_FEATURED_IMAGE: "ADD_FEATURED_IMAGE",
  ADD_OTHER_IMAGES: "ADD_OTHER_IMAGES",

  GET_MAKES: "GET_MAKES",
  GET_MODELS: "GET_MODELS",
  SET_CURRENT_PAGE: "SET_CURRENT_PAGE",
  GET_CARS: "GET_CARS",
  GET_MY_CARS: "GET_MY_CARS",
  GET_MORE_CARS: "GET_MORE_CARS",
  NO_CAR: "NO_CAR",

  GET_CAR_DETAILS: "GET_CAR_DETAILS",

  BOOK_CAR: "BOOK_CAR",

  POST_CAR: "POST_CAR",
  DELETE_CAR: "DELETE_CAR",

  RESET_LISTING: "RESET_LISTING",
};

// ======== BOAT =====================
export const boatConstants = {
  LOADING: "LOADING",
  STOP_LOADING: "STOP_LOADING",
  ERROR: "ERROR",

  FETCHING: "FETCHING",
  STOP_FETCHING: "STOP_FETCHING",

  LOADING_FEATURED: "LOADING_FEATURED",
  LOADING_OTHER_IMAGES: "LOADING_OTHER_IMAGES",

  ADD_FEATURED_IMAGE: "ADD_FEATURED_IMAGE",
  ADD_OTHER_IMAGES: "ADD_OTHER_IMAGES",

  SET_CURRENT_PAGE: "SET_CURRENT_PAGE",
  GET_BOATS: "GET_BOATS",
  GET_MY_BOATS: "GET_MY_BOATS",
  GET_MORE_BOATS: "GET_MORE_BOATS",
  NO_BOAT: "NO_BOAT",

  GET_BOAT_DETAILS: "GET_BOAT_DETAILS",
  DELETE_BOAT: "DELETE_BOAT",

  POST_BOAT: "POST_BOAT",
};

export const actions = {
  LOADING_FEATURED: "LOADING_FEATURED",
  STOP_LOADING: "STOP_LOADING",
};
