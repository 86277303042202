import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { rootReducer } from "../reducers/index";
import { composeWithDevTools } from "redux-devtools-extension";

let middlewares = [];

if (process.env.NODE_ENV === "production") {
  middlewares = [thunk];
} else {
  const { logger } = require("redux-logger");
  middlewares = [thunk, logger];
}

const initialState = {};

const createAppStore = createStore(
  rootReducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middlewares))
);

export default createAppStore;
