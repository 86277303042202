import { authConstants } from "../actionTypes";

const INITIAL_STATE = {
  currentUser: null,
  userRegistered: false,
  loading: false,
  error: {},
  token: "",
  userData: JSON.parse(localStorage.getItem("userData")),
  isAuthenticated: !!localStorage.token && !!localStorage.userData,
};

const authReducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case "SET_CURRENT_USER":
      return {
        ...state,
        currentUser: payload,
      };
    case authConstants.REGISTER_USER:
      return {
        ...state,
        userRegistered: true,
        loading: false,
      };
    case authConstants.LOGIN_USER:
      console.log("payload", payload);
      return {
        ...state,
        isAuthenticated: true,
        userData: payload.user,
        token: payload.token,
        loading: false,
      };
    case authConstants.AUTH_LOADING:
      return {
        ...state,
        loading: true,
      };
    case authConstants.STOP_AUTH_LOADING:
      return {
        ...state,
        loading: false,
      };
    case authConstants.ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
      };
    default:
      return state;
  }
};

export default authReducer;
