import { listingConstants } from "../actionTypes";

const INITIAL_STATE = {
  loadingFeatured: false,
  loadingOthers: false,
  loading: false,
  error: "",
  apartment_type: "",
  allStates: [],
  allLGAs: [],
  title: "",
  customLocations: [],
  location: "",
  sublocation: "",
  no_guests: 0,
  no_bedrooms: 1,
  no_bathrooms: 0,
  additional_rules: ["No smoking in premises"],
  description: "",
  price: 0,
  min_nights: 0,
  max_nights: 0,
  featured_img: "",
  other_imgs: [],
  amenities: [],
  chosen_amenities: [],
  house_rules: {
    smoking: false,
    pets: false,
    party_allowed: false,
    cooking: false,
  },
  posted: false,
};

const listingReducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case listingConstants.LOADING_FEATURED:
      return {
        ...state,
        loadingFeatured: true,
      };
    case listingConstants.LOADING_OTHERS:
      return {
        ...state,
        loadingOthers: true,
      };
    case listingConstants.ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
      };
    case listingConstants.GET_STATES:
      return {
        ...state,
        allStates: [...payload],
      };
    case listingConstants.GET_LOCATIONS:
      return {
        ...state,
        customLocations: [...payload],
      };
    case listingConstants.GET_LGAS:
      return {
        ...state,
        allLGAs: payload,
      };
    case listingConstants.UPDATE_LISTING:
      return {
        ...state,
        ...payload,
      };
    case listingConstants.ADD_ADDITIONAL_RULE:
      return {
        ...state,
        additional_rules: [...state.additional_rules, payload],
      };
    case listingConstants.REMOVE_ADDITIONAL_RULE:
      let currentRules = state.additional_rules;
      currentRules.splice(payload, 1);
      return {
        ...state,
        additional_rules: [...currentRules],
      };
    case listingConstants.ADD_FEATURED_IMAGE:
      return {
        ...state,
        featured_img: payload,
        loadingFeatured: false,
      };
    case listingConstants.ADD_OTHER_IMAGES:
      return {
        ...state,
        other_imgs: [...state.other_imgs, payload],
        loadingOthers: false,
      };
    case listingConstants.GET_AMENITIES:
      return {
        ...state,
        amenities: payload,
      };
    case listingConstants.ADD_CHOSEN_AMENITY:
      console.log("payload", payload);
      return {
        ...state,
        chosen_amenities: [...state.chosen_amenities, payload],
      };
    case listingConstants.REMOVE_CHOSEN_AMENITY:
      let currentAmenity = state.chosen_amenities;
      let finalAmenity = currentAmenity.filter((obj) => obj !== payload);
      console.log("currentAmenity", finalAmenity);
      return {
        ...state,
        chosen_amenities: [...finalAmenity],
      };
    case listingConstants.UPDATE_RULE:
      return {
        ...state,
        house_rules: { ...state.house_rules, ...payload },
      };
    case listingConstants.LOADING:
      return {
        ...state,
        loading: true,
      };
    case listingConstants.STOP_LOADING:
      return {
        ...state,
        loading: false,
      };
    case listingConstants.POST_APARTMENT:
      return {
        ...INITIAL_STATE,
        posted: true,
      };
    default:
      return state;
  }
};

export default listingReducer;
