import { boatConstants } from "../actionTypes";

const INITIAL_STATE = {
  loading: false,
  loadingFeatured: false,
  loadingOtherImages: false,
  fetching: false,
  myboats: [],
  featured_image: "",
  other_imgs: [],
  error: "",
  boats: [],
  boat: {},
  count: 0,
  pagination: {},
  currentPage: 0,
};

const boatReducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case boatConstants.LOADING:
      return {
        ...state,
        loading: true,
      };
    case boatConstants.LOADING_FEATURED:
      return {
        ...state,
        loadingFeatured: true,
      };
    case boatConstants.FETCHING:
      return {
        ...state,
        fetching: true,
      };
    case boatConstants.STOP_FETCHING:
      return {
        ...state,
        fetching: false,
      };
    case boatConstants.GET_BOATS:
      return {
        ...state,
        fetching: false,
        boats: payload.boats,
        count: payload.count,
        pagination: payload.pagination,
      };
    case boatConstants.GET_MORE_BOATS:
      return {
        ...state,
        fetching: false,
        boats: [...state.boats, ...payload.boats],
        count: payload.total,
        pagination: payload.pagination,
      };
    case boatConstants.GET_MY_BOATS:
      return {
        ...state,
        fetching: false,
        myboats: payload,
      };
    case boatConstants.GET_BOAT_DETAILS:
      return {
        ...state,
        fetching: false,
        boat: payload,
      };
    case boatConstants.NO_BOAT:
      return {
        ...state,
        fetching: false,
        myboats: [],
      };
    case boatConstants.LOADING_OTHER_IMAGES:
      return {
        ...state,
        loadingOtherImages: true,
      };
    case boatConstants.ADD_FEATURED_IMAGE:
      return {
        ...state,
        featured_image: payload,
        loadingFeatured: false,
      };
    case boatConstants.ADD_OTHER_IMAGES:
      return {
        ...state,
        other_imgs: [...state.other_imgs, payload],
        loadingOtherImages: false,
      };
    case boatConstants.GET_BOATS:
      return {
        ...INITIAL_STATE,
        loading: false,
        boats: payload,
      };
    case boatConstants.POST_BOAT:
      return {
        ...INITIAL_STATE,
        loading: false,
        posted: true,
      };
    case boatConstants.DELETE_BOAT:
      const newboats = state.myboats.filter(
        (theboats) => theboats._id !== payload
      );

      return {
        ...INITIAL_STATE,
        loading: false,
        myboats: [...newboats],
      };
    case boatConstants.ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
      };
    default:
      return state;
  }
};

export default boatReducer;
