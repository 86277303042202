import React, { FC } from "react";
import GallerySlider from "components/GallerySlider/GallerySlider";
import StartRating from "components/StartRating/StartRating";
import { Link } from "react-router-dom";
import Badge from "shared/Badge/Badge";
import { HotelsDataType } from "data/types";
import { formatMoney } from "utils/helpers";

export interface HotelCardHProps {
  className?: string;
  data?: HotelsDataType;
}

const DEMO_DATA: any = [];

const HotelCardH: FC<HotelCardHProps> = ({
  className = "",
  data = DEMO_DATA,
}) => {
  const {
    _id,
    title,
    // featured_img,
    gallery_imgs,
    location,
    bedrooms,
    bathrooms,
    host,
    // is_ads,
    price,
    // view_count,
  } = data;

  const href = `hotel/${_id}`;

  const renderSliderGallery = () => {
    return (
      <div className="flex-shrink-0 p-3 w-full h-full sm:w-64 ">
        <GallerySlider
          ratioClass="aspect-w-1 aspect-h-1"
          galleryImgs={gallery_imgs}
          className="w-full h-full rounded-2xl overflow-hidden"
          uniqueID={`PropertyCardH-${Date.now()}-${_id}`}
        />

        {/* {saleOff && (
          <SaleOffBadge className="absolute left-5 top-5 !bg-orange-500" />
        )} */}
      </div>
    );
  };

  const renderTienIch = () => {
    return (
      <>
        <div className="inline-grid grid-cols-2 gap-2">
          <div className="flex items-center space-x-2">
            <span className="hidden sm:inline-block">
              <i className="las la-bed text-lg"></i>
            </span>
            <span className="text-xs text-neutral-500 dark:text-neutral-400">
              {bedrooms} beds
            </span>
          </div>

          {/* ---- */}
          <div className="flex items-center space-x-2">
            <span className="hidden sm:inline-block">
              <i className="las la-bath text-lg"></i>
            </span>
            <span className="text-xs text-neutral-500 dark:text-neutral-400">
              {bathrooms} baths
            </span>
          </div>

          {/* ---- */}
        </div>
        <div className="flex items-center space-x-2">
          <span className="hidden sm:inline-block">
            <i className="las la-map-marker-alt text-lg"></i>
          </span>
          <span className="text-xs text-neutral-500 dark:text-neutral-400">
            {location && location.city
              ? location.city + ", " + location.country
              : location.country}
          </span>
        </div>
      </>
    );
  };

  const renderContent = () => {
    return (
      <div className="flex-grow p-3 sm:pr-6 flex flex-col items-start">
        <div className="space-y-4 w-full">
          <div className="inline-flex space-x-3">
            <Badge
              name={
                <div className="flex items-center">
                  <i className="text-sm las la-share-alt"></i>
                  <span className="ml-1">
                    {host &&
                      (host.company_name
                        ? host.company_name
                        : host.first_name + " " + host.last_name)}
                  </span>
                </div>
              }
            />
            {/* <Badge
              name={
                <div className="flex items-center">
                  <i className="text-sm las la-user-friends"></i>
                  <span className="ml-1">Family</span>
                </div>
              }
              color="yellow"
            /> */}
          </div>
          <div className="flex items-center space-x-2">
            <h2 className="text-lg font-medium capitalize">
              <span className="line-clamp-2">{title}</span>
            </h2>
          </div>
          {renderTienIch()}
          <div className="w-14 border-b border-neutral-100 dark:border-neutral-800 "></div>
          <div className="flex w-full justify-between items-end">
            <StartRating reviewCount={5} />
            <span className="flex items-center justify-center px-3 py-2 border border-secondary-500 rounded leading-none text-base font-medium text-secondary-500">
              {`${formatMoney(price)}`}
            </span>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div
      className={`nc-PropertyCardH group relative bg-white dark:bg-neutral-900 border border-neutral-100 dark:border-neutral-800 rounded-3xl overflow-hidden hover:shadow-xl transition-shadow ${className}`}
      data-nc-id="PropertyCardH"
    >
      <Link
        to={href}
        className="h-full w-full flex flex-col sm:flex-row sm:items-center"
      >
        {renderSliderGallery()}
        {renderContent()}
      </Link>
      {/* <BtnLikeIcon
        colorClass={` bg-neutral-100 dark:bg-neutral-700 hover:bg-neutral-200 hover:bg-opacity-70 text-neutral-6000 dark:text-neutral-400`}
        isLiked={like}
        className="absolute right-5 top-5 sm:right-3 sm:top-3 "
      /> */}
    </div>
  );
};

export default HotelCardH;
