import httpRequest from "../../api";
import { hotelConstants } from "../actionTypes";

// ========================================================================

export const saveListing = (data, dispatch) => {
  const action = { type: "UPDATE_LISTING", payload: data };
  console.log("data", data);

  dispatch(action);
};

// ========================================================================

export const publishHotelAction = (
  details,
  user_id,
  featured_img,
  other_imgs,
  dispatch
) => {
  dispatch({ type: hotelConstants.LOADING });
  console.log("Post Data", details);

  const postData = {
    host: user_id,
    title: details.title,
    description: details.description,
    bedrooms: details.bedrooms,
    bathrooms: details.bathrooms,
    price: details.price,
    state: details.state,
    lga: details.lga,
    amenities: details.amenities,
    featured_image: featured_img,
    gallery_imgs: other_imgs,
  };

  return httpRequest("post", `hotel`, postData)
    .then(({ data }) => {
      let action = {};
      console.log("Response Data", data);
      if (data.status === 200) {
        action = {
          type: hotelConstants.POST_HOTEL,
          payload: data.message,
        };
        dispatch(action);
      } else {
        const payload = "Could not load post hotel";
        action = { type: hotelConstants.ERROR, payload };
        dispatch(action);
      }

      return action;
    })
    .catch(({ response }) => {
      console.log("response", response);

      const message = response?.data?.data?.error;
      console.log("MSG", message);
      const action = { payload: message, type: "ERROR" };

      dispatch(action);

      return action;
    });
};

// ====================================================================

export const fetchAllHotels = (filter = {}, page, loadMore, dispatch) => {
  if (page === 1) dispatch({ type: hotelConstants.FETCHING });
  const limit = 12;

  console.log("{{{{{{{ FETCHING ALL HOTELS }}}}}}}}");

  return httpRequest("get", `hotel?page=${page}&limit=${limit}`)
    .then(({ data }) => {
      console.log("fetched all hotels", data);
      let action = {};
      if (data.status === 200) {
        dispatch({ type: hotelConstants.STOP_FETCHING });

        if (data.data.hotels.length > 0) {
          if (!loadMore) {
            action = {
              type: hotelConstants.FETCH_HOTELS,
              payload: data.data,
            };
          } else {
            action = {
              type: hotelConstants.FETCH_MORE_HOTELS,
              payload: data.data,
            };
          }
        } else {
          action = {
            type: hotelConstants.NO_HOTEL,
            payload: data.data.message,
          };
        }
        dispatch(action);
      } else {
        const payload = "Could not load hotels";
        action = { type: hotelConstants.ERROR, payload };

        dispatch({ type: hotelConstants.STOP_FETCHING });
        dispatch(action);
      }

      return action;
    })
    .catch(({ response }) => {
      dispatch({ type: hotelConstants.STOP_FETCHING });

      const message = response?.data; //.data?.message;
      return {
        payload: message,
        type: "ERROR",
      };
    });
};

// ========================================================================

export const addFeaturedImage = async (image, dispatch) => {
  dispatch({ type: hotelConstants.LOADING_FEATURED });

  const data = new FormData();
  data.append("file", image);
  data.append("upload_preset", "hotelpreset");
  data.append("cloud_name", "holiday-short-stays");
  fetch("https://api.cloudinary.com/v1_1/holiday-short-stays/image/upload", {
    method: "POST",
    body: data,
  })
    .then((response) => response.json())
    .then((data) => {
      console.log("upload data", data);
      dispatch({
        type: hotelConstants.ADD_FEATURED_IMAGE,
        payload: data.url,
      });
    })
    .catch((err) => console.log("error", err));
};

// ====================================================================

export const addOtherImages = (images, dispatch) => {
  for (let i = 0; i < images.length; i++) {
    dispatch({ type: "LOADING_OTHER_IMAGES" });

    const data = new FormData();
    data.append("file", images[i]);
    data.append("upload_preset", "hotelpreset");
    data.append("cloud_name", "holiday-short-stays");

    fetch("https://api.cloudinary.com/v1_1/holiday-short-stays/image/upload", {
      method: "POST",
      body: data,
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("data", data);
        dispatch({
          type: hotelConstants.ADD_OTHER_IMAGES,
          payload: data.url,
        });
      })
      .catch((err) => console.log("error", err));
  }
};

// ====================================================================

export const fetchMyHotels = (host_id, dispatch) => {
  dispatch({ type: hotelConstants.FETCHING });

  return httpRequest("get", `hotel/user/${host_id}`)
    .then(({ data }) => {
      console.log("fetched", data.data.hotels);
      let action = {};
      if (data.status === 200) {
        if (data.data.hotels.length > 0) {
          action = {
            type: hotelConstants.GET_HOTELS,
            payload: data.data.hotels,
          };
        } else {
          action = {
            type: hotelConstants.NO_HOTEL,
            payload: data.data.message,
          };
        }
        dispatch({ type: hotelConstants.STOP_FETCHING });
        dispatch(action);
      } else {
        const payload = "Could not load hotels";
        action = { type: hotelConstants.ERROR, payload };

        dispatch({ type: hotelConstants.STOP_LOADING });
        dispatch(action);
      }

      return action;
    })
    .catch(({ response }) => {
      dispatch({ type: hotelConstants.STOP_LOADING });

      const message = response?.data; //.data?.message;
      return {
        payload: message,
        type: "ERROR",
      };
    });
};
