import React, { useEffect, useState } from "react";
import LocationInput from "./LocationInput";
import GuestsInput, { GuestsInputProps } from "./GuestsInput";
import { FocusedInputShape } from "react-dates";
import StayDatesRangeInput from "./StayDatesRangeInput";
import ButtonSubmit from "./ButtonSubmit";
import moment from "moment";
import { FC } from "react";
import PropertyInput from "./PropertyInput";
import { getCustomLocations } from "redux/actions/listingActions";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "redux/reducers";
import { Link, useHistory } from "react-router-dom";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import cogoToast from "cogo-toast";
import { searchProperties } from "redux/actions/bookingActions";
import { ButtonLoader } from "components/ButtonLoader.jsx";

export interface DateRage {
  startDate: moment.Moment | null;
  endDate: moment.Moment | null;
}

export interface StaySearchFormProps {
  haveDefaultValue?: boolean;
}

// DEFAULT DATA FOR ARCHIVE PAGE
const defaultLocationValue = "";
const defaultPropertyValue = "";
const defaultDateRange = {
  startDate: moment(),
  endDate: moment().add(4, "days"),
};
const defaultGuestValue: GuestsInputProps["defaultValue"] = {
  guestAdults: 2,
  guestChildren: 2,
  guestInfants: 1,
};

export interface ShortletsProps {
  customLocations: [
    { slug: string; location: string; state: string; _id: string }
  ];
}

const StaySearchForm: FC<StaySearchFormProps> = ({
  haveDefaultValue = false,
}) => {
  const history = useHistory();
  const [dateRangeValue, setDateRangeValue] = useState<DateRage>({
    startDate: null,
    endDate: null,
  });
  const [locationInputValue, setLocationInputValue] = useState("");
  const [propertyInputValue, setPropertyInputValue] = useState("");

  const [dateFocused, setDateFocused] = useState<FocusedInputShape | null>(
    null
  );
  const [searching, setSearching] = useState(false);

  const dispatch = useDispatch();
  const { customLocations }: ShortletsProps = useSelector(
    (store: RootState) => store.listing
  );

  const setMyLocationInputValue = (e: any) => {
    console.log("My loation", e);
    setLocationInputValue(e);
  };

  //
  useEffect(() => {
    if (haveDefaultValue) {
      setDateRangeValue(defaultDateRange);
      setLocationInputValue(defaultLocationValue);
      setPropertyInputValue(defaultPropertyValue);
    }
  }, []);

  useEffect(() => {
    const fetchCustomLocations = async () => {
      await getCustomLocations(dispatch);
    };
    fetchCustomLocations();
  }, []);

  console.log("customLocations", customLocations);
  //

  const submitSearchForm = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    console.log("Submitted!!!");

    const propLowercase = propertyInputValue.toLowerCase().split(" ").join("");

    console.log(
      "propertyInputValue",
      propLowercase,
      "locationInputValue",
      locationInputValue,
      "dateRangeValue",
      dateRangeValue
    );

    if (!propLowercase) {
      cogoToast.error("Select a category", {
        position: "top-center",
      });
      return;
    } else if (!locationInputValue) {
      cogoToast.error("Select a location ", {
        position: "top-center",
      });
      return;
    }

    setSearching(true);

    let postPayload: any = {
      category: propLowercase,
      location: locationInputValue,
    };

    const processSearchProperty = async () => {
      console.log("processing search...");
      const booked = await searchProperties(postPayload, dispatch);

      console.log("Booked", booked);

      const {
        status,
        data,
        data: { category, count, message },
      } = booked;

      let propertyResult = [];

      if (category === "apartment") propertyResult = data.apartments;
      else if (category === "car") propertyResult = data.cars;
      else if (category === "boat") propertyResult = data.boats;

      console.log("propertyResult", propertyResult);

      if (status === 200) {
        cogoToast.success(`${count} ${message}`, {
          position: "top-right",
          hideAfter: 5,
        });
        setSearching(false);
        if (count > 0) {
          history.push(`/searchresult/${propLowercase}/${locationInputValue}`, {
            source: "registration",
          });
        }
      } else {
        cogoToast.error(message, {
          position: "top-right",
        });
        setSearching(false);
      }
    };

    processSearchProperty();
  };

  const renderForm = () => {
    return (
      <form
        onSubmit={submitSearchForm}
        className="w-full relative mt-8 flex flex-col md:flex-row md:items-center rounded-3xl lg:rounded-full shadow-xl dark:shadow-2xl bg-white dark:bg-neutral-900 divide-y divide-neutral-200 dark:divide-neutral-700 md:divide-y-0"
      >
        <PropertyInput
          defaultValue={propertyInputValue}
          onChange={(e) => setPropertyInputValue(e)}
          onInputDone={() => setDateFocused("startDate")}
        />
        <LocationInput
          defaultValue={locationInputValue}
          onChange={(e) => setMyLocationInputValue(e)}
          locations={customLocations}
        />
        <StayDatesRangeInput
          defaultValue={dateRangeValue}
          defaultFocus={dateFocused}
          onFocusChange={(focus) => setDateFocused(focus)}
          onChange={(data) => setDateRangeValue(data)}
        />
        {/* BUTTON SUBMIT OF FORM */}
        <div className="px-4 py-4 lg:py-0">
          <ButtonPrimary
            type="submit"
            className="h-14 md:h-16 w-full md:w-16 rounded-full bg-primary-6000 hover:bg-primary-700 flex items-center justify-center text-neutral-50 focus:outline-none"
            disabled={searching}
          >
            {!searching && (
              <>
                <span className="mr-3 md:hidden">Search</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={1.5}
                    d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                  />
                </svg>
              </>
            )}
            {searching && <ButtonLoader />}
          </ButtonPrimary>
        </div>
      </form>
    );
  };

  return renderForm();
};

export default StaySearchForm;
