// import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import { TaxonomyType } from "data/types";
import { FC, useEffect, useState } from "react";
import SectionGridFilterCard from "./SectionGridFilterCard";
import { Helmet } from "react-helmet";
import SectionHero2ArchivePage from "components/SectionHero2ArchivePage/SectionHero2ArchivePage";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "redux/reducers";
import { fetchAllHotels } from "redux/actions/hotelActions";
import { ShowLoading } from "components/ShowLoading";
import ButtonPrimary from "shared/Button/ButtonPrimary";

export interface ListingHotelsPageProps {
  className?: string;
}

// const DEMO_CATS: TaxonomyType[] = [
//   {
//     id: "1",
//     href: "#",
//     name: "Enjoy the Beauty of Brazil ",
//     taxonomy: "category",
//     count: 17288,
//     thumbnail:
//       "https://images.pexels.com/photos/1118877/pexels-photo-1118877.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260",
//     listingType: "experiences",
//   },
//   {
//     id: "2",
//     href: "#",
//     name: "Enjoy the Beauty of Paris",
//     taxonomy: "category",
//     count: 2118,
//     thumbnail:
//       "https://images.pexels.com/photos/2412609/pexels-photo-2412609.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
//     listingType: "experiences",
//   },
//   {
//     id: "3",
//     href: "#",
//     name: "Enjoy the Beauty of Bangkok",
//     taxonomy: "category",
//     count: 36612,
//     thumbnail:
//       "https://images.pexels.com/photos/732895/pexels-photo-732895.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
//     listingType: "experiences",
//   },
//   {
//     id: "5",
//     href: "#",
//     name: "Enjoy the Beauty of Singapore",
//     taxonomy: "category",
//     count: 188288,
//     thumbnail:
//       "https://images.pexels.com/photos/3152124/pexels-photo-3152124.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260",
//     listingType: "experiences",
//   },
//   {
//     id: "4",
//     href: "#",
//     name: "Enjoy the Beauty of Seoul",
//     taxonomy: "category",
//     count: 188288,
//     thumbnail:
//       "https://images.pexels.com/photos/373290/pexels-photo-373290.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
//     listingType: "experiences",
//   },
// ];

export interface HotelListingProps {
  hotels: Array<any>;
  fetching: boolean;
  loading: boolean;
  pagination: { nextPage: number };
  count: number;
  currentPage: number;
}

const ListingHotelPage: FC<ListingHotelsPageProps> = ({ className = "" }) => {
  const dispatch = useDispatch();
  const [filter, setFilter] = useState({});
  const [page, setPage] = useState(1);
  const [loadingMore, setLoadingMore] = useState(false);

  const {
    fetching,
    hotels,
    count,
    pagination,
    currentPage,
  }: HotelListingProps = useSelector((store: RootState) => store.hotels);

  const hotelsData = hotels;

  // console.log("ALl hotels", hotels);
  console.log("Hellooooo");

  useEffect(() => {
    console.log("useEffect ... ");

    const fetchData = async () => {
      console.log("Hotels Listing Page");
      await fetchAllHotels(filter, 1, false, dispatch);
    };

    if (hotels.length === 0) fetchData();
  }, []);

  const loadMore = async (page: number) => {
    setLoadingMore(true);
    setPage(page);
    await fetchAllHotels(filter, page, true, dispatch);
    setLoadingMore(false);
  };

  useEffect(() => {
    const $body = document.querySelector("body");
    if ($body) {
      $body.className = "theme-cyan-blueGrey";
    }
    return () => {
      if ($body) {
        $body.className = "";
      }
    };
  }, []);

  return (
    <div
      className={`nc-ListingRealEstatePage relative overflow-hidden ${className}`}
      data-nc-id="ListingRealEstatePage"
    >
      <Helmet>
        <title>Holiday Short Stays </title>
      </Helmet>

      <div className="container relative">
        {/* SECTION HERO */}
        <SectionHero2ArchivePage className="lg:mt-2" />

        {/* SECTION */}
        {fetching && <ShowLoading />}

        {!fetching && hotelsData.length > 0 && (
          <SectionGridFilterCard className="" data={hotelsData} />
        )}

        {!fetching && pagination.nextPage && (
          <div className="flex mt-16 justify-center items-center pb-24">
            <ButtonPrimary
              loading={loadingMore}
              onClick={() => loadMore(pagination.nextPage)}
            >
              Load More
            </ButtonPrimary>
          </div>
        )}

        {/* <SectionGridFilterCard className="py-24 lg:py-32" /> */}

        {/* SECTION 1 */}
        {/* <div className="relative py-16">
          <BackgroundSection />
          <SectionSliderNewCategories
            heading="Explore top destination ✈"
            subHeading="Explore thousands of destinations around the world"
            categoryCardType="card4"
            itemPerRow={4}
            categories={DEMO_CATS}
            sliderStyle="style2"
          />
        </div> */}

        {/* SECTION */}
        {/* <SectionSubscribe2 className="py-24 lg:py-32" /> */}
      </div>
    </div>
  );
};

export default ListingHotelPage;
