export default function toTitleCase(str: string) {
  //convert to all lowercase
  const strLower = str.toLowerCase();

  const strArr = strLower.split(" ");
  const capitalized = strArr.map(
    (str) => str.split("")[0].toUpperCase() + str.slice(1)
  );

  return capitalized.join(" ");
}
