import { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Input from "shared/Input/Input";
import Select from "shared/Select/Select";
import FormItem from "./FormItem";
import {
  saveListing,
  getStates,
  getLGAs,
  fetchAmenities,
} from "redux/actions/listingActions";
import { RootState } from "redux/reducers";
import Textarea from "shared/Textarea/Textarea";
import LoadingGif from "./../../images/loading-gif.svg";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import cogoToast from "cogo-toast";
import {
  addFeaturedImage,
  addOtherImages,
  publishHotelAction,
} from "redux/actions/hotelActions";
import { useHistory } from "react-router-dom";

export interface PageAddListing1Props {}

const PageListHotel: FC<PageAddListing1Props> = () => {
  const dispatch = useDispatch();
  const listing = useSelector((store: RootState) => store.listing);
  const hotels: any = useSelector((store: RootState) => store.hotels);
  const auth = useSelector((store: RootState) => store.auth);
  const history = useHistory();

  const [state, setState] = useState({
    title: "",
    description: "",
    bedrooms: "",
    bathrooms: "",
    price: "",
    state: "",
    lga: "",
    amenities: [],
    featured_image: "",
    hotel_images: [],
  });

  // console.log("listing", listing);
  // console.log("hotels", hotels);

  const selectChange = (event: React.FormEvent<HTMLSelectElement>) => {
    const newValue: any = {};
    newValue[event.currentTarget.name] = event.currentTarget.value;
    saveListing(newValue, dispatch);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    console.log("handlechange");
    const newValue: any = {};
    newValue[e.target.name] = e.target.value;

    setState({ ...state, ...newValue });
  };

  const handleChangeTextArea = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newValue: any = {};
    newValue[e.target.name] = e.target.value;
    setState({ ...state, ...newValue });
  };

  useEffect(() => {
    async function getAllStates() {
      await getStates(dispatch);
    }
    getAllStates();
  }, []);

  const handleChangeAmenities = (
    name: string,
    id: string,
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const status = e.target.checked;
    // const item = { amenity: name, id };
    const amenity_id = id;

    if (status) addAmenity(amenity_id);
    else removeAmenity(amenity_id);
  };

  const removeAmenity = (id: string) => {
    let currentAmenities = [];
    currentAmenities = state.amenities;
    let removedList = currentAmenities.filter((e) => e !== id);

    let newValue: any = {};
    newValue["amenities"] = removedList;
    setState({ ...state, ...newValue });
  };

  const addAmenity = (id: string) => {
    console.log("add amenity");
    let currentAmenities: any = [];
    currentAmenities = state.amenities;
    currentAmenities.push(id);

    let newValue: any = {};
    newValue["amenities"] = currentAmenities;
    setState({ ...state, ...newValue });
  };

  useEffect(() => {
    fetchAmenities(dispatch);
  }, []);

  const isSelected = (id: string) => {
    return listing.chosen_amenities.find((x: any) => x.id === id);
  };

  const changeState = (event: React.FormEvent<HTMLSelectElement>) => {
    const newValue: any = {};
    const stateslug =
      event.currentTarget.options[
        event.currentTarget.selectedIndex
      ].getAttribute("data-slug");
    // const evt = event.currentTarget.getAttribute("data-slug");
    newValue[event.currentTarget.name] = event.currentTarget.value;

    setState({ ...state, ...newValue });
    getLGAs(stateslug, dispatch);
  };

  //  Images Upload

  const handleChangeFeatured = async (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const fileList = e.target.files;
    if (!fileList) return;
    addFeaturedImage(fileList[0], dispatch);
  };

  const handleChangeOthers = (e: React.ChangeEvent<HTMLInputElement>) => {
    const fileList = e.target.files;
    if (!fileList) return;
    addOtherImages(fileList, dispatch);
  };

  const processUploadHotel = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    const user_id = auth.userData.user_id ?? auth.userData._id;

    const publishHtl = async () => {
      const { type, payload: message } = await publishHotelAction(
        state,
        user_id,
        hotels.featured_image,
        hotels.other_imgs,
        dispatch
      );
      console.log("Type+Message", type, message);
      if (type === "ERROR") {
        cogoToast.error(message, {
          position: "top-right",
          hideAfter: 5,
        });
      } else {
        cogoToast.success("Listing Successfully Submitted", {
          position: "top-right",
          hideAfter: 5,
        });
        // redirect to success page
        history.push({
          pathname: "/listing-published",
          state: { property: "hotel", post_url: "list-hotel" },
        });

        // clear state
      }
    };

    publishHtl();
  };

  return (
    <form name="listHotelForm" method="POST" onSubmit={processUploadHotel}>
      <div
        className={`nc-PageAddListing1 px-4 max-w-3xl mx-auto pb-24 pt-14 sm:py-24 lg:pb-32`}
        data-nc-id="PageAddListing1"
      >
        <div className="space-y-11">
          <div>
            <span className="text-4xl font-semibold">
              List a Hotel on Holiday Short Stays
            </span>
          </div>

          {/* --------------------- */}
          <div className="listingSection__wrap ">
            <>
              {/* FORM */}
              <div className="space-y-8">
                {/* ITEM */}

                <FormItem label="Name of Hotel">
                  <Input
                    placeholder="Name of hotel"
                    name="title"
                    onChange={handleChange}
                    value={state.title}
                  />
                </FormItem>

                <FormItem label="Short Description of the Hotel">
                  <Textarea
                    placeholder="..."
                    rows={3}
                    name="description"
                    value={state.description}
                    onChange={handleChangeTextArea}
                  />
                </FormItem>

                <div className="grid grid-cols-1 md:grid-cols-2 gap-8 md:gap-5">
                  <FormItem label="No. of Bedrooms">
                    <Input
                      placeholder="1"
                      type="number"
                      name="bedrooms"
                      onChange={handleChange}
                      value={state.bedrooms}
                    />
                  </FormItem>
                  <FormItem label="No. of Bathrooms">
                    <Input
                      placeholder="0"
                      type="number"
                      name="bathrooms"
                      onChange={handleChange}
                      value={state.bathrooms}
                    />
                  </FormItem>
                </div>

                <FormItem label="Price per night">
                  <div className="relative">
                    <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                      <span className="text-gray-500">&#8358;</span>
                    </div>
                    <Input
                      className="!pl-8 !pr-10"
                      placeholder="0.00"
                      name="price"
                      type="number"
                      value={state.price}
                      onChange={handleChange}
                    />
                    <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                      <span className="text-gray-500">NGN</span>
                    </div>
                  </div>
                </FormItem>
              </div>
            </>

            <>
              {/* FORM */}
              <div className="space-y-8">
                {/* ITEM */}
                <div className="grid grid-cols-1 md:grid-cols-2 gap-8 md:gap-5">
                  <FormItem label="State">
                    <Select name="state" onChange={changeState}>
                      {listing &&
                        listing.allStates.map(
                          (eachState: any, index: number) => (
                            <option
                              key={index}
                              value={eachState.state}
                              data-slug={eachState.slug}
                            >
                              {eachState.state}
                            </option>
                          )
                        )}
                    </Select>
                  </FormItem>
                  <FormItem label="Local Government">
                    <Select name="lga" onChange={selectChange}>
                      {listing &&
                        listing.allLGAs.map((lga: any, index: number) => (
                          <option key={index} value={lga.name}>
                            {lga.name}
                          </option>
                        ))}
                    </Select>
                  </FormItem>
                </div>
              </div>
            </>

            <div>
              <label className="text-lg font-semibold" htmlFor="">
                General amenities
              </label>
              <div className="mt-6 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-5">
                {listing.loading ? "Loading Amenities..." : ""}
                {listing && listing.amenities
                  ? listing.amenities.map((list: any, index: number) => (
                      <div className="flex text-sm sm:text-base" key={index}>
                        <input
                          id={list.name}
                          name={list.name}
                          type="checkbox"
                          className="focus:ring-action-primary h-6 w-6 text-primary-500 border-primary rounded border-neutral-500 bg-white dark:bg-neutral-700  dark:checked:bg-primary-500 focus:ring-primary-500"
                          onChange={(e) =>
                            handleChangeAmenities(list.name, list._id, e)
                          }
                          checked={isSelected(list._id)}
                          value={isSelected(list._id)}
                        />
                        <label
                          htmlFor={list.name}
                          className="ml-3.5 flex flex-col flex-1 justify-center"
                        >
                          <span className=" text-neutral-900 dark:text-neutral-100">
                            {list.name}
                          </span>
                        </label>
                      </div>
                    ))
                  : null}
              </div>
            </div>

            <>
              <div>
                <span className="text-lg font-semibold">
                  Upload Cover image
                </span>
                <div className="mt-5 ">
                  <div>
                    <input
                      className="block w-full pt-0 pb-0 h-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 cursor-pointer dark:text-gray-400 focus:outline-none focus:border-transparent dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
                      aria-describedby="featured_image_help"
                      id="featured_image"
                      name="featured_image"
                      type="file"
                      accept="image/*"
                      onChange={handleChangeFeatured}
                    />
                    <div
                      className="mt-1 text-sm text-gray-500 dark:text-gray-300"
                      id="featured_image_help"
                    >
                      Only one picture allowed
                    </div>
                  </div>
                </div>
                <div className="grid grid-cols-6 gap-4 mt-4">
                  {hotels && hotels["featured_image"] ? (
                    <span>
                      <img src={hotels["featured_image"]} alt="feat_img" />
                    </span>
                  ) : null}
                  {hotels && hotels["loadingFeatured"] ? (
                    <span>
                      <img src={LoadingGif} alt="Loading" />
                    </span>
                  ) : null}
                </div>
              </div>
              {/* ----------------- */}
              <div className="w-48 border-b border-neutral-200 dark:border-neutral-700"></div>
              <div>
                <span className="text-lg font-semibold">
                  Upload Pictures of the apartment
                </span>
                <div className="mt-5 ">
                  <input
                    className="block w-full h-full pt-0 pb-0 text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 cursor-pointer dark:text-gray-400 focus:outline-none focus:border-transparent dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
                    aria-describedby="hotel_images_help"
                    id="hotel_images"
                    type="file"
                    name="hotel_images"
                    onChange={handleChangeOthers}
                    accept="image/*"
                    multiple
                  />
                  <div
                    className="mt-1 text-sm text-gray-500 dark:text-gray-300"
                    id="hotel_images_help"
                  >
                    Multiple picutures are allowed. Minimum of 5. Maximum of 10
                  </div>
                </div>
                <div className="grid grid-cols-6 gap-4 mt-4">
                  {hotels && hotels["other_imgs"]
                    ? hotels["other_imgs"].map(
                        (otherimg: string, index: number) => (
                          <span key={index}>
                            <img src={otherimg} alt="other_img" />
                          </span>
                        )
                      )
                    : null}
                  {hotels && hotels["loadingOtherImages"] ? (
                    <span>
                      <img src={LoadingGif} alt="Loading" />
                    </span>
                  ) : null}
                </div>
              </div>
            </>
          </div>

          {/* --------------------- */}
          <div className="flex justify-end space-x-5">
            <ButtonPrimary type="submit">Complete Upload</ButtonPrimary>
          </div>
        </div>
      </div>
    </form>
  );
};

export default PageListHotel;
