import { SocialType } from "shared/SocialsShare/SocialsShare";
import React, { FC } from "react";

export interface SocialsListProps {
  className?: string;
  itemClass?: string;
  socials?: SocialType[];
}

// const socialsDemo: SocialType[] = [
//   { name: "Facebook", href: "#" },
//   { name: "Twitter", href: "#" },
//   { name: "Youtube", href: "#" },
//   { name: "Instagram", href: "#" },
// ];

const socialIcons = {
  facebook: "lab la-facebook-square",
  twitter: "lab la-twitter",
  youtube: "lab la-youtube",
  instagram: "lab la-instagram",
};

export interface socialTest {
  facebook?: string;
  twitter?: string;
  instagram?: string;
  youtube?: string;
}

const socialMydemo: socialTest = {
  facebook: "",
  twitter: "",
  instagram: "",
  youtube: "",
};

const SocialsList: FC<SocialsListProps> = ({
  className = "",
  itemClass = "block",
  socials = socialMydemo,
}) => {
  return (
    <nav
      className={`nc-SocialsList flex space-x-2.5 text-2xl text-neutral-6000 dark:text-neutral-300 ${className}`}
      data-nc-id="SocialsList"
    >
      {/* {Object.entries(socials).map((item, i) => (
        <p>{item}</p>
      ))} */}
      {/* {Object.keys(socials).map((item, i) => {
        <a
          key={i}
          className={`${itemClass}`}
          href={item.href}
          target="_blank"
          rel="noopener noreferrer"
          title={item.name}
        >
          <i className={item.icon}></i>
        </a>;
      })} */}
    </nav>
  );
};

export default SocialsList;
