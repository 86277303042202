import { Tab } from "@headlessui/react";
import StartRating from "components/StartRating/StartRating";
import { FC, Fragment, useEffect, useState } from "react";
import Avatar from "shared/Avatar/Avatar";
import SocialsList from "shared/SocialsList/SocialsList";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "redux/reducers";
import moment from "moment";
import { fetchMyApartments } from "redux/actions/listActions";
import ApartmentCard from "components/ApartmentCard/ApartmentCard";
import Loader from "react-loader-spinner";
import { getProfileAction } from "redux/actions/profileActions";
import { fetchMyHotels } from "redux/actions/hotelActions";
import MyHotelsCardH from "components/ExperiencesCardH/MyHotelsCardH";
import CarCardDisplay from "components/CarCard/CarCardDisplay";
import { fetchMyCars } from "redux/actions/carActions";
import BoatCardDisplay from "components/BoatCard/BoatCardDisplay";
import { fetchMyBoats } from "redux/actions/boatActions";
import ErrorMessage from "components/ErrorMessage/ErrorMessage";

export interface DashboardPageProps {
  className?: string;
}

export interface DashboardApartmnentProps {
  myapartments: Array<any>;
  loading: boolean;
}

export interface DashboardHotelProps {
  myhotels: Array<any>;
  fetching: boolean;
  loading: boolean;
}

export interface DashboardCarsProps {
  mycars: Array<any>;
  fetching: boolean;
  loading: boolean;
}

export interface DashboardBoatsProps {
  myboats: Array<any>;
  fetching: boolean;
  loading: boolean;
}

const DashboardPage: FC<DashboardPageProps> = ({ className = "" }) => {
  let [categories] = useState(["Apartments", "Cars", "Boats"]);
  const dispatch = useDispatch();

  const { loading: loadingProfile } = useSelector(
    (store: RootState) => store.profile
  );

  const { userData } = useSelector((store: RootState) => store.auth);

  const { myapartments, loading }: DashboardApartmnentProps = useSelector(
    (store: RootState) => store.list
  );
  const { fetching, myhotels }: DashboardHotelProps = useSelector(
    (store: RootState) => store.hotels
  );
  const { fetching: fetchingCars, mycars }: DashboardCarsProps = useSelector(
    (store: RootState) => store.cars
  );
  const { fetching: fetchingBoats, myboats }: DashboardBoatsProps = useSelector(
    (store: RootState) => store.boats
  );

  console.log("myapartments", myapartments);

  const {
    first_name,
    last_name,
    bio,
    location,
    rating,
    profile_url,
    created_at,
    social,
    // _id,
  } = userData;
  const fullname = `${first_name} ${last_name}`;
  console.log("Inside dashboard", userData);

  const user_id = userData.user_id ?? userData._id;

  useEffect(() => {
    console.log("useEffect ... ");
    const fetchData = async () => {
      console.log("Dashboard User", userData);
      await getProfileAction(dispatch);
      await fetchMyApartments(user_id, dispatch);
      await fetchMyHotels(user_id, dispatch);
      await fetchMyCars(user_id, dispatch);
      await fetchMyBoats(user_id, dispatch);
    };

    fetchData();
  }, []);

  const renderSidebar = () => {
    return (
      <div className=" w-full flex flex-col items-center text-center sm:rounded-xl sm:border border-neutral-200 dark:border-neutral-700 space-y-6 sm:space-y-7 px-0 sm:p-6 xl:p-8">
        {!loadingProfile && (
          <>
            <Avatar
              hasChecked
              hasCheckedClass="w-6 h-6 -top-0.5 right-2"
              sizeClass="w-28 h-28"
              userName={fullname}
              imgUrl={profile_url ? profile_url : null}
              // {...(profile_url ? "imgUrl={profile_url}" : null)}
            />

            {/* ---- */}
            <div className="space-y-3 text-center flex flex-col items-center">
              <h2 className="text-3xl font-semibold">{fullname}</h2>
              <StartRating
                className="!text-base"
                point={rating ? (rating.point === 0 ? 5 : rating.point) : 0}
                reviewCount={0}
              />
            </div>

            {/* ---- */}
            <p className="text-neutral-500 dark:text-neutral-400">
              {bio ? bio : "no bio"}
            </p>

            {/* ---- */}
            <SocialsList
              className="!space-x-3"
              socials={social}
              itemClass="flex items-center justify-center w-9 h-9 rounded-full bg-neutral-100 dark:bg-neutral-800 text-xl"
            />

            {/* ---- */}
            <div className="border-b border-neutral-200 dark:border-neutral-700 w-14"></div>

            {/* ---- */}
            <div className="space-y-4">
              <div className="flex items-center space-x-4">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6 text-neutral-400"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={1.5}
                    d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"
                  />
                </svg>
                <span className="text-neutral-6000 dark:text-neutral-300">
                  {location && location.state} state
                </span>
              </div>

              <div className="flex items-center space-x-4">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6 text-neutral-400"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={1.5}
                    d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
                  />
                </svg>
                <span className="text-neutral-6000 dark:text-neutral-300">
                  Joined in {moment(created_at).fromNow()}
                </span>
              </div>
            </div>
          </>
        )}
        {loadingProfile && (
          <Loader type="Puff" color="#00BFFF" height={100} width={100} />
        )}
      </div>
    );
  };

  const renderSection1 = () => {
    return (
      <div className="listingSection__wrap">
        <div>
          <h2 className="text-2xl font-semibold">My Listings</h2>
          {/* <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
            {fullname}'s listings is very rich, 5 star reviews help him to be
            more branded.
          </span> */}
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>

        <div>
          <Tab.Group>
            <Tab.List className="flex space-x-1 overflow-x-auto">
              {categories.map((item) => (
                <Tab key={item} as={Fragment}>
                  {({ selected }) => (
                    <button
                      className={`flex-shrink-0 block !leading-none font-medium px-5 py-2.5 text-sm sm:text-base sm:px-6 sm:py-3 capitalize rounded-full focus:outline-none ${
                        selected
                          ? "bg-secondary-900 text-secondary-50 "
                          : "text-neutral-500 dark:text-neutral-400 dark:hover:text-neutral-100 hover:text-neutral-900 hover:bg-neutral-100 dark:hover:bg-neutral-800"
                      } `}
                    >
                      {item}
                    </button>
                  )}
                </Tab>
              ))}
            </Tab.List>
            <Tab.Panels>
              <Tab.Panel className="">
                <div className="mt-8 grid grid-cols-1 gap-6 md:gap-7 sm:grid-cols-2">
                  {loading && (
                    <Loader
                      type="Puff"
                      color="#00BFFF"
                      height={100}
                      width={100}
                    />
                  )}
                  {!loading &&
                    myapartments &&
                    myapartments.length > 0 &&
                    myapartments.map((stay: any, index: number) => (
                      <ApartmentCard key={index} data={stay} owner={true} />
                    ))}
                  {!loading && myapartments.length === 0 && (
                    <div className="flex flex-col">
                      <div>You have not posted any apartment yet.</div>
                      <div className="font-semibold">
                        <a href="/add-listing">List an apartment</a>
                      </div>
                    </div>
                  )}
                </div>
                {!fetching && myapartments && myapartments.length === 0 && (
                  <ErrorMessage text="You have not uploaded an apartment" />
                )}
                {/* <div className="flex mt-11 justify-center items-center">
                  <ButtonSecondary>Show me more</ButtonSecondary>
                </div> */}
              </Tab.Panel>
              <Tab.Panel className="">
                <div className="mt-8 grid grid-cols-1 gap-6 md:gap-7 sm:grid-cols-2">
                  {!fetching &&
                    mycars &&
                    mycars.length > 0 &&
                    mycars.map((car: any) => (
                      <CarCardDisplay key={car._id} data={car} owner={true} />
                    ))}
                </div>
                {!loading && mycars.length === 0 && (
                  <div className="flex flex-col">
                    <div>You have not posted any car yet.</div>
                    <div className="font-semibold">
                      <a href="/list-car">List a car</a>
                    </div>
                  </div>
                )}
                <div className="flex justify-center">
                  {!fetching && mycars && mycars.length === 0 && (
                    <ErrorMessage text="You have not uploaded a car" />
                  )}
                </div>
                {/* <div className="flex mt-11 justify-center items-center">
                  <ButtonSecondary>Show me more</ButtonSecondary>
                </div> */}
              </Tab.Panel>
              <Tab.Panel className="">
                <div className="mt-8 grid grid-cols-1 gap-6 md:gap-7 sm:grid-cols-2">
                  {!fetching &&
                    myboats &&
                    myboats.length > 0 &&
                    myboats.map((boat: any) => (
                      <BoatCardDisplay
                        key={boat._id}
                        data={boat}
                        owner={true}
                      />
                    ))}
                </div>
                {!loading && myboats.length === 0 && (
                  <div className="flex flex-col">
                    <div>You have not posted any boat yet.</div>
                    <div className="font-semibold">
                      <a href="/list-boat">List a boat</a>
                    </div>
                  </div>
                )}
                {!fetching && myboats && myboats.length === 0 && (
                  <ErrorMessage text="You have not uploaded a boat" />
                )}
                {/* <div className="flex mt-11 justify-center items-center">
                  <ButtonSecondary>Show me more</ButtonSecondary>
                </div> */}
              </Tab.Panel>
            </Tab.Panels>
          </Tab.Group>
        </div>
      </div>
    );
  };

  return (
    <div className={`nc-AuthorPage ${className}`} data-nc-id="AuthorPage">
      <Helmet>
        <title>Dashboard || Holiday Short Stays</title>
      </Helmet>
      <main className="container mt-12 mb-24 lg:mb-32 flex flex-col lg:flex-row">
        <div className="block flex-grow mb-24 lg:mb-0">
          <div className="lg:sticky lg:top-24">{renderSidebar()}</div>
        </div>
        <div className="w-full lg:w-3/5 xl:w-2/3 space-y-8 lg:space-y-10 lg:pl-10 flex-shrink-0">
          {renderSection1()}
          {/* Reviews */}
        </div>
      </main>
    </div>
  );
};

export default DashboardPage;
