import { FC, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { RootState } from "redux/reducers";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { showSuccess } from "utils/helpers";
import { PaystackButton } from "react-paystack";
import { recordPayment, resetBooking } from "redux/actions/bookingActions";

export interface PageAddListing8Props {}

export interface BookingProps {}

const PageBookingSuccess: FC<PageAddListing8Props> = () => {
  const dispatch = useDispatch();
  const listing = useSelector((store: RootState) => store.listing);
  const booking = useSelector((store: RootState) => store.booking);
  console.log("booking", booking);

  const history = useHistory();
  const bookingDetails: any = history.location.state;

  const paymentData = booking.bookingPayload;
  const { email, fullname, grandTotal, phone } = paymentData;

  const grandTotalInt: number = parseInt(grandTotal) / 100;

  const componentProps = {
    email: email,
    amount: +grandTotalInt,
    metadata: {
      custom_fields: [
        {
          display_name: "Full Name",
          variable_name: fullname,
          value: fullname,
        },
        {
          display_name: "Phone",
          variable_name: phone,
          value: phone,
        },
      ],
    },
    publicKey: "pk_test_28887287e43c5d8923017b325f78ed30105edc97", //process.env.REACT_APP_PAYSTACK_PUBLIC_KEY,
    text: "Proceed to Payment",
    onSuccess: (success: any) => {
      console.log("payment success", success);
      showSuccess("Payment Successful");
      postPayment(success, paymentData._id);
    },
    onClose: () => {},
  };

  const postPayment = (successData: {}, bookingId: string) => {
    const paymentContent = { ...successData, booking_id: bookingId };
    recordPayment(paymentContent, dispatch);
  };

  useEffect(() => {
    return () => {
      resetBooking(dispatch);
      console.log("Unmounting...");
    };
  }, []);

  return (
    <div
      className={`nc-PageAddListing1 px-4 max-w-3xl mx-auto pb-24 pt-14 sm:py-24 lg:pb-32`}
      data-nc-id="PageAddListing1"
    >
      <div className="space-y-11">
        <div className="listingSection__wrap ">
          <>
            {listing && !listing.posted ? (
              <>
                <div className="text-center">
                  <h2 className="text-2xl font-semibold capitalize">
                    {bookingDetails && fullname ? `${fullname},` : ""}
                    <br />
                    <p> Thanks for your reservation 🤝</p>
                  </h2>
                  <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
                    <p className="pt-4">
                      {" "}
                      Your apartment reservation request has been made
                      successfully sent. <br />
                      The owner would get back to you as soon as possible.{" "}
                      <br />
                    </p>
                  </span>
                </div>
                <div className="w-14 border-b text-center border-neutral-200 dark:border-neutral-700"></div>

                {booking.bookingPayload &&
                  booking.bookingPayload.paymentMethod === "debitcard" &&
                  booking &&
                  !booking.paymentSuccess && (
                    <div className="flex justify-center space-x-5">
                      <div className="nc-Button relative h-auto inline-flex items-center justify-center rounded-full transition-colors text-sm sm:text-base font-medium px-4 py-3 sm:px-6  ttnc-ButtonPrimary disabled:bg-opacity-70 bg-primary-6000 hover:bg-primary-700 text-neutral-50  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-6000 dark:focus:ring-offset-0 ">
                        <PaystackButton {...componentProps} />
                      </div>
                    </div>
                  )}

                {booking && booking.paymentSuccess && (
                  <p className="pt-4 flex justify-center px-6 py-3 bg-black text-white">
                    Payment Received Successfully. Thank You 💃
                  </p>
                )}

                {booking && booking.paymentFailure && (
                  <p className="pt-4 flex justify-center px-6 py-3 bg-black text-white">
                    Payment Not Processed. Kindly contact Admin 😞
                  </p>
                )}
                {/* FORM */}
              </>
            ) : (
              <div>You have landed on an Invalid Page</div>
            )}
          </>
        </div>

        {/* --------------------- */}
        <div className="flex justify-center space-x-5">
          <ButtonPrimary href="/">Go to Home Page</ButtonPrimary>
        </div>
      </div>
    </div>
  );
};

export default PageBookingSuccess;
