import { FC, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Input from "shared/Input/Input";
import { Link, useHistory, useParams } from "react-router-dom";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { ButtonLoader } from "components/ButtonLoader.jsx";
import cogoToast from "cogo-toast";
import { resetPasswordAuth } from "redux/actions/authActions";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "redux/reducers";

export interface PageLoginProps {
  className?: string;
}

export interface ResetPasswordProps {
  userid: string;
  resettoken: string;
}

const PageResetPassword: FC<PageLoginProps> = ({ className = "" }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [isMessage, setIsMessage] = useState(false);
  const [message, setMessage] = useState("");
  const [state, setState] = useState({
    password1: "",
    password2: "",
  });
  const { userid, resettoken } = useParams<ResetPasswordProps>();

  const { loading, isAuthenticated } = useSelector(
    (store: RootState) => store.auth
  );

  useEffect(() => {
    if (isAuthenticated) {
      console.log("Is Authticated");
    }
  }, [dispatch, loading]);

  const handleSubmit = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    setMessage("");
    setIsMessage(false);

    if (state.password1.length < 5) {
      cogoToast.error("Passwords is too short", {
        position: "top-right",
        hideAfter: 5,
      });
      return;
    }

    if (state.password1 !== state.password2) {
      cogoToast.error("Passwords must be the same", {
        position: "top-right",
        hideAfter: 5,
      });
      return;
    }

    const resetData = {
      resettoken,
      password: state.password1,
      userid,
    };

    const processResetPassword = async () => {
      const { status, message } = await resetPasswordAuth(resetData, dispatch);

      if (status === "success") {
        setMessage(message);
        setIsMessage(true);
        cogoToast.success(message, {
          position: "top-right",
          hideAfter: 5,
        });

        const resetpass = { password1: "", password2: "" };
        setState({ ...state, ...resetpass });
      } else if (status === "error") {
        setMessage(message);
        setIsMessage(true);
        cogoToast.error(message, {
          position: "top-right",
          hideAfter: 5,
        });
      }
    };

    processResetPassword();
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue: any = {};
    newValue[e.target.name] = e.target.value;
    setState({ ...state, ...newValue });
  };

  return (
    <div className={`nc-PageLogin ${className}`} data-nc-id="PageLogin">
      <Helmet>
        <title>Reset Password || Holiday Short Stays</title>
      </Helmet>
      <div className="container mb-24 lg:mb-32">
        <h2 className="my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          Reset Your Password
        </h2>
        <div className="max-w-md mx-auto space-y-6">
          {/* FORM */}
          <form
            className="grid grid-cols-1 gap-6"
            method="post"
            onSubmit={handleSubmit}
          >
            <label className="block">
              <span className="text-neutral-800 dark:text-neutral-200">
                New Password
              </span>
              <Input
                type="password"
                className="mt-1"
                name="password1"
                required
                onChange={handleChange}
              />
            </label>
            <label className="block">
              <span className="text-neutral-800 dark:text-neutral-200">
                Retype New Password
              </span>
              <Input
                type="password"
                className="mt-1"
                name="password2"
                required
                onChange={handleChange}
              />
            </label>
            {isMessage && message && (
              <div className="grid-cols-1 text-center text-white bg-primary-500 p-4">
                {message}
              </div>
            )}
            <ButtonPrimary type="submit">
              {loading ? <ButtonLoader /> : "Continue"}
            </ButtonPrimary>
          </form>

          {/* ==== */}
          <span className="block text-center text-neutral-700 dark:text-neutral-300">
            <Link to="/login">Login to your account</Link>
          </span>
        </div>
      </div>
    </div>
  );
};

export default PageResetPassword;
