import { hotelConstants } from "../actionTypes";

const INITIAL_STATE = {
  loading: false,
  loadingFeatured: false,
  loadingOtherImages: false,
  fetching: false,
  featured_image: "",
  other_imgs: [],
  error: "",
  myhotels: [],
  hotels: [],
  count: 0,
  pagination: {},
  currentPage: 0,
};

const hotelReducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case hotelConstants.LOADING:
      return {
        ...state,
        loading: true,
      };
    case hotelConstants.LOADING_FEATURED:
      return {
        ...state,
        loadingFeatured: true,
      };
    case hotelConstants.LOADING_OTHER_IMAGES:
      return {
        ...state,
        loadingOtherImages: true,
      };
    case hotelConstants.FETCHING:
      return {
        ...state,
        fetching: true,
      };
    case hotelConstants.STOP_FETCHING:
      return {
        ...state,
        fetching: false,
      };
    case hotelConstants.ADD_FEATURED_IMAGE:
      return {
        ...state,
        featured_image: payload,
        loadingFeatured: false,
      };
    case hotelConstants.ADD_OTHER_IMAGES:
      return {
        ...state,
        other_imgs: [...state.other_imgs, payload],
        loadingOtherImages: false,
      };
    case hotelConstants.POST_HOTEL:
      return {
        ...INITIAL_STATE,
        posted: true,
      };
    case hotelConstants.FETCH_HOTELS:
      return {
        ...state,
        fetching: false,
        hotels: payload.hotels,
        count: payload.count,
        pagination: payload.pagination,
      };
    case hotelConstants.FETCH_MORE_HOTELS:
      return {
        ...state,
        fetching: false,
        hotels: [...state.hotels, ...payload.hotels],
        count: payload.count,
        pagination: payload.pagination,
      };
    case hotelConstants.GET_MY_HOTELS:
      return {
        ...state,
        fetching: false,
        myhotels: payload,
      };
    case hotelConstants.ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
      };
    default:
      return state;
  }
};

export default hotelReducer;
