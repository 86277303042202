import Label from "components/Label/Label";
import React, { FC, useEffect, useState } from "react";
import Avatar from "shared/Avatar/Avatar";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Input from "shared/Input/Input";
import Select from "shared/Select/Select";
import Textarea from "shared/Textarea/Textarea";
import CommonLayout from "./CommonLayout";
import { Helmet } from "react-helmet";
import FormItem from "containers/PageAddListing/FormItem";
import avatar1 from "images/avatars/Image-1.png";
import {
  getProfileAction,
  updateProfileAction,
  updateProfileImage,
} from "redux/actions/profileActions";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "redux/reducers";
import { profileConstants } from "redux/actionTypes";
import cogoToast from "cogo-toast";

export interface ProfilePageProps {
  className?: string;
}

const ProfilePage: FC<ProfilePageProps> = ({ className = "" }) => {
  const { loading, profile } = useSelector((store: RootState) => store.profile);
  const dispatch = useDispatch();
  const fileInput = React.useRef(null);
  const [profilePix, setProfilePix] = useState("");

  console.log("my profile", profile);
  const profileData = profile;

  console.log("profileData", profileData);

  useEffect(() => {
    console.log("Launcing UseEffect with dispatch ..... ");
    const getProfile = async () => {
      await getProfileAction(dispatch);
    };

    getProfile();
  }, [dispatch]);

  useEffect(() => {
    console.log("Dispatching....");
    const getProfile = async () => {
      await getProfileAction(dispatch);
    };

    getProfile();
  }, [dispatch]);

  // const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   const newValue: any = {};
  //   newValue[e.target.name] = e.target.value;
  //   saveListing(newValue, dispatch);
  // };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    // const newValue: any = {};
    // newValue[e.target.name] = e.target.value;
    let newName = e.target.name;
    const newValue = e.target.value;

    if (newName.includes(".")) {
      let splt = newName.split(".");
      let oldObj = { ...profileData[splt[0]] };
      oldObj[splt[1]] = e.target.value;

      dispatch({
        type: profileConstants.EDIT_PROFILE,
        payload: { [splt[0]]: oldObj },
      });
    } else {
      dispatch({
        type: profileConstants.EDIT_PROFILE,
        payload: { [newName]: newValue },
      });
    }
  };

  const handleTextAreaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    // const newValue: any = {};
    // newValue[e.target.name] = e.target.value;
    const newName = e.target.name;
    const newValue = e.target.value;
    dispatch({
      type: profileConstants.EDIT_PROFILE,
      payload: { [newName]: newValue },
    });
  };

  const selectChange = (event: React.FormEvent<HTMLSelectElement>) => {
    const newValue = event.currentTarget.value;
    // newValue[event.currentTarget.name] = event.currentTarget.value;
    dispatch({
      type: profileConstants.EDIT_PROFILE,
      payload: { [event.currentTarget.name]: newValue },
    });
  };

  const handleChangePicture = (e: React.ChangeEvent<HTMLInputElement>) => {
    const fileList = e.target.files;
    if (!fileList) return;

    console.log("single profile image", fileList[0]);
    const { first_name, last_name, email } = profileData;

    const updateProfile = async () => {
      const pix: any = await updateProfileImage(
        fileList[0],
        { first_name, last_name, email },
        dispatch
      ).then((data) => {
        console.log("My data", data);
      });

      console.log("pix", pix);

      if (pix && pix.profile_url) {
        console.log("Do sometjijng");
        setProfilePix(pix.profile_url);
      } else {
        console.log("not doing anything");
      }
    };

    updateProfile();
  };

  const updateProfile = () => {
    console.log("profileDate NEWW", profileData);
    const updateProfile = async () => {
      if (profileData.phone1 && profileData.phone1 === "+") {
        profileData.phone1 = profileData.phone1.substring(1);
      }

      const updated = await updateProfileAction(profileData, dispatch);

      if (updated && updated.payload.status === 200) {
        cogoToast.success(updated.payload.data.message, {
          position: "top-right",
        });
      } else {
        cogoToast.error("Unable to update profile", {
          position: "top-right",
        });
      }

      console.log("updated", updated);
    };

    updateProfile();
  };

  return (
    <div className={`nc-AccountPage ${className}`} data-nc-id="AccountPage">
      <Helmet>
        <title>Account || Holiday Short Stays</title>
      </Helmet>
      <CommonLayout>
        <div className="space-y-6 sm:space-y-8">
          {/* HEADING */}
          <h2 className="text-3xl font-semibold">Account information</h2>
          <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
          <div className="flex flex-col md:flex-row">
            <div className="flex-shrink-0 flex items-start">
              <div className="relative rounded-full overflow-hidden flex">
                <Avatar
                  sizeClass="w-32 h-32"
                  imgUrl={
                    profile.profile_url
                      ? profile.profile_url
                      : profilePix
                      ? profilePix
                      : avatar1
                  }
                />
                <div className="absolute inset-0 bg-black bg-opacity-60 flex flex-col items-center justify-center text-neutral-50 cursor-pointer">
                  <svg
                    width="30"
                    height="30"
                    viewBox="0 0 30 30"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M17.5 5H7.5C6.83696 5 6.20107 5.26339 5.73223 5.73223C5.26339 6.20107 5 6.83696 5 7.5V20M5 20V22.5C5 23.163 5.26339 23.7989 5.73223 24.2678C6.20107 24.7366 6.83696 25 7.5 25H22.5C23.163 25 23.7989 24.7366 24.2678 24.2678C24.7366 23.7989 25 23.163 25 22.5V17.5M5 20L10.7325 14.2675C11.2013 13.7988 11.8371 13.5355 12.5 13.5355C13.1629 13.5355 13.7987 13.7988 14.2675 14.2675L17.5 17.5M25 12.5V17.5M25 17.5L23.0175 15.5175C22.5487 15.0488 21.9129 14.7855 21.25 14.7855C20.5871 14.7855 19.9513 15.0488 19.4825 15.5175L17.5 17.5M17.5 17.5L20 20M22.5 5H27.5M25 2.5V7.5M17.5 10H17.5125"
                      stroke="currentColor"
                      strokeWidth={1.5}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>

                  <span className="mt-1 text-xs">Change Image</span>
                </div>
                <input
                  type="file"
                  className="absolute inset-0 opacity-0 cursor-pointer"
                  onChange={handleChangePicture}
                  accept="image/*"
                  ref={fileInput}
                  name="profile_image"
                />
              </div>
            </div>
            <div className="flex-grow mt-10 md:mt-0 md:pl-16 max-w-3xl space-y-6">
              <div>
                <Label>First Name</Label>
                <Input
                  className="mt-1.5"
                  name="first_name"
                  value={profileData.first_name}
                  onChange={handleChange}
                  readOnly
                />
              </div>
              <div>
                <Label>Last Name</Label>
                <Input
                  className="mt-1.5"
                  name="last_name"
                  value={profileData.last_name}
                  onChange={handleChange}
                  readOnly
                />
              </div>
              <div>
                <Label>Company Name (if any)</Label>
                <Input
                  className="mt-1.5"
                  name="company_name"
                  value={profileData.company_name}
                  onChange={handleChange}
                />
              </div>
              <div>
                <Label>About you</Label>
                <Textarea
                  className="mt-1.5"
                  name="bio"
                  value={profileData.bio}
                  onChange={handleTextAreaChange}
                />
              </div>
              {/* ---- */}
              <div>
                <Label>Gender</Label>
                <Select
                  className="mt-1.5"
                  name="gender"
                  value={profileData.gender}
                  onChange={selectChange}
                >
                  <option value="Not Specified">Not Specified</option>
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                </Select>
              </div>
              {/* ---- */}
              <div>
                <Label>Email</Label>
                <Input
                  className="mt-1.5"
                  name="email"
                  value={profileData.email}
                  onChange={handleChange}
                  readOnly
                />
              </div>
              <div className="grid grid-cols-2 gap-4">
                <div>
                  <Label>Phone Number 1</Label>
                  <Input
                    className="mt-1.5"
                    name="phone"
                    value={profileData.phone}
                    onChange={handleChange}
                  />
                </div>
                <FormItem label="WhatsApp Number" desc="Format: +2348092223478">
                  <div>
                    <Input
                      className="mt-1.5"
                      name="phone1"
                      type="number"
                      value={profileData.phone1}
                      onChange={handleChange}
                    />
                  </div>
                </FormItem>
              </div>
              {/* ---- */}
              <div>
                <Label>Location</Label>
                <Input
                  className="mt-1.5"
                  name="location.state"
                  value={profileData.location.state}
                  onChange={handleChange}
                />
              </div>
              {/* ---- */}
              <div className="max-w-lg">
                <Label>Date of birth</Label>
                <Input
                  className="mt-1.5"
                  type="date"
                  name="date_of_birth"
                  value={profileData.date_of_birth}
                  onChange={handleChange}
                />
              </div>
              {/* ---- */}
              <div className="grid grid-cols-2 gap-4">
                <FormItem label="Instagram Handle" desc="Username only">
                  <div className="relative">
                    <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                      <span className="text-gray-500">@</span>
                    </div>
                    <Input
                      className="!pl-8 !pr-10"
                      placeholder=""
                      name="social.instagram"
                      value={profileData.social.instagram}
                      onChange={handleChange}
                    />
                  </div>
                </FormItem>
                <FormItem
                  label="Facebook URL"
                  desc="E.g https://www.facebook.com/holidayshortstays"
                >
                  <div className="relative">
                    <Input
                      className="!pl-8 !pr-10"
                      placeholder=""
                      name="social.facebook"
                      value={profileData.social.facebook}
                      onChange={handleChange}
                    />
                  </div>
                </FormItem>
                <FormItem label="Twitter Handle" desc="Username only">
                  <div className="relative">
                    <Input
                      className="!pl-8 !pr-10"
                      placeholder=""
                      name="social.twitter"
                      value={profileData.social.twitter}
                      onChange={handleChange}
                    />
                  </div>
                </FormItem>
                <div>
                  <Label>YouTube Channel URL</Label>
                  <Input
                    className="mt-1.5"
                    name="social.youtube"
                    value={profileData.social.youtube}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div>
                <Label>Website</Label>
                <Input
                  className="mt-1.5"
                  name="website"
                  value={profileData.website}
                  onChange={handleChange}
                />
              </div>
              {/* ---- */}
              <div className="pt-2">
                <ButtonPrimary loading={loading} onClick={updateProfile}>
                  Update Profile
                </ButtonPrimary>
              </div>
            </div>
          </div>
        </div>
      </CommonLayout>
    </div>
  );
};

export default ProfilePage;
