import httpRequest from "../../api";
import { bookingConstants } from "../actionTypes";

export const setOrder = (orderDetails, dispatch) => {
  dispatch({ type: bookingConstants.LOADING });

  const action = { type: bookingConstants.SET_ORDER, payload: orderDetails };
  console.log("orderDetails", orderDetails);

  dispatch(action);
  dispatch({ type: bookingConstants.STOP_LOADING });

  return action;
};

export const createBooking = (bookingDetails, dispatch) => {
  console.log("bookingDetails", bookingDetails);

  dispatch({ type: bookingConstants.LOADING });

  return httpRequest("post", `booking/apartment`, bookingDetails)
    .then(({ data }) => {
      console.log("Booking Data", data);

      const action = {
        type: bookingConstants.CREATE_BOOKING,
        payload: data,
      };

      dispatch({ type: bookingConstants.STOP_LOADING });
      dispatch(action);
    })
    .catch(({ response }) => {
      console.log("Response", response);
      const message = response?.data?.data?.error;

      dispatch({ type: bookingConstants.STOP_LOADING });
      dispatch({ type: bookingConstants.ERROR, payload: message });
    });
};

// ===============≠===============≠===============

export const recordPayment = (paymentDetails, dispatch) => {
  console.log("paymentDetails", paymentDetails);

  dispatch({ type: bookingConstants.LOADING });

  return httpRequest("post", "payment", paymentDetails)
    .then((data) => {
      console.log("Payment Data", data);

      const action = {
        type: bookingConstants.PAYMENT_SUCCESS,
        payload: data,
      };

      dispatch({ type: bookingConstants.STOP_LOADING });
      dispatch(action);
    })
    .catch(({ response }) => {
      console.log("Response", response);
      const message = response?.data?.data?.error;

      dispatch({ type: bookingConstants.STOP_LOADING });
      dispatch({ type: bookingConstants.PAYMENT_FAILURE });
      dispatch({ type: bookingConstants.ERROR, payload: message });
    });
};

// ===============≠===============≠===============

export const resetBooking = (dispatch) => {
  dispatch({ type: bookingConstants.RESET_BOOKING });
};

// ===============≠===============≠===============

export const getMyBookings = (dispatch) => {
  dispatch({ type: bookingConstants.LOADING });

  return httpRequest("get", `booking/apartment`)
    .then(({ data }) => {
      const action = {
        type: bookingConstants.GET_MY_BOOKINGS,
        payload: data.data,
      };

      dispatch({ type: bookingConstants.STOP_LOADING });
      dispatch(action);
    })
    .catch(({ response }) => {
      const message = response?.data; //.data?.message;
      console.log("response", response);
      dispatch({ type: bookingConstants.STOP_LOADING });
      return {
        payload: message,
        type: "ERROR",
      };
    });
};

// ===============≠===============≠===============

export const submitInquiry = (inquiryDetails, dispatch) => {
  dispatch({ type: bookingConstants.LOADING });
  let {
    category,
    property_id,
    property_title,
    property_url,
    fromDate,
    toDate,
    owner_id: { _id: owner_id, email: owner_email, first_name, last_name },
  } = inquiryDetails;

  const owner_fullname = `${first_name} ${last_name}`;

  const inquiryD = {
    owner_id: owner_id,
    category: category,
    property_id: property_id,
    customer_name: inquiryDetails.fullname,
    customer_phone: inquiryDetails.phone,
    customer_email: inquiryDetails.email,
    customer_message: inquiryDetails.message,
    owner_email,
    owner_fullname,
    property_title,
    property_url,
    fromDate,
    toDate,
  };

  return httpRequest(
    "post",
    `booking/inquiry/${category}/${property_id}`,
    inquiryD
  )
    .then(({ data }) => {
      const action = {
        type: bookingConstants.SUBMIT_INQUIRY,
        payload: data,
      };

      dispatch({ type: bookingConstants.STOP_LOADING });
      dispatch(action);
      return action;
    })
    .catch(({ response }) => {
      console.log("Response", response);
      const message = response?.data?.data?.error;

      dispatch({ type: bookingConstants.STOP_LOADING });
      dispatch({ type: bookingConstants.ERROR, payload: message });
    });
};

// ===============≠===============≠===============

export const searchProperties = (searchParams, dispatch) => {
  dispatch({ type: bookingConstants.LOADING });

  console.log("searchParams", searchParams);

  return httpRequest("post", `search?page=1&limit=10`, searchParams)
    .then(({ data }) => {
      const action = {
        type: bookingConstants.SEARCH_RESULT,
        payload: data.data,
      };

      // dispatch({ type: bookingConstants.STOP_LOADING });
      dispatch(action);

      console.log("booking data", data);
      return data;
    })
    .catch(({ response }) => {
      const message = response?.data; //.data?.message;
      console.log("response", response);
      dispatch({ type: bookingConstants.STOP_LOADING });
      return {
        payload: message,
        type: "ERROR",
      };
    });
};
