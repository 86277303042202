import React, { FC, useEffect } from "react";
// import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
// import SectionGridAuthorBox from "components/SectionGridAuthorBox/SectionGridAuthorBox";
import SectionHeroArchivePage from "components/SectionHeroArchivePage/SectionHeroArchivePage";
// import SectionSliderNewCategories from "components/SectionSliderNewCategories/SectionSliderNewCategories";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import SectionGridHasMap from "./SectionGridHasMap";
import { Helmet } from "react-helmet";
import { getSingleLocation } from "redux/actions/listActions";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";

export interface FeaturedLocationsPageProps {
  className?: string;
}

export interface SingleAparmentProps {
  location_slug: string;
  location_id: string;
}

const FeaturedLocationsPage: FC<FeaturedLocationsPageProps> = ({
  className = "",
}) => {
  const dispatch = useDispatch();
  // const { location_slug, location_id } = useParams<SingleAparmentProps>();

  // const { location }: any = useSelector((state: RootState) => state.list);

  // useEffect(() => {
  //   console.log("getting location");
  //   const fetchSingleLocation = async () => {
  //     await getSingleLocation(location_id, location_slug, dispatch);
  //   };

  //   fetchSingleLocation();
  //   // if (shortlets.length === 0) fetchShortletsByLocation();
  // }, []);

  return (
    <div
      className={`nc-FeaturedLocationsPage relative ${className}`}
      data-nc-id="FeaturedLocationsPage"
    >
      <Helmet>
        <title>Holiday Short Stays </title>
      </Helmet>
      <BgGlassmorphism />

      {/* SECTION HERO */}
      <div className="container pb-0 lg:pt-0 lg:pb-0">
        <SectionHeroArchivePage
          currentPage="Shortlets"
          currentTab="Shortlets"
          heroText={`Shortlet Apartments`}
          subtitleText=""
        />
      </div>

      {/* SECTION */}
      <div className="container pb-0 lg:pt-0 lg:pb-0 mb-5">
        <SectionGridHasMap />
      </div>
    </div>
  );
};

export default FeaturedLocationsPage;
