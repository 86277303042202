import React from "react";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  EmailShareButton,
  EmailIcon,
  FacebookIcon,
  TwitterIcon,
  WhatsappIcon,
  LinkedinIcon,
} from "react-share";

const LikeSaveBtns = ({
  url = "https://holidayshortstays.com",
  description = "",
}) => {
  const style = {
    marginRight: 10,
  };
  return (
    <div className="flow-root">
      <div className="flex text-neutral-700 dark:text-neutral-300 text-sm -mx-3 -my-1.5">
        <span className="py-1.5 px-3 flex rounded-lg hover:bg-neutral-100 dark:hover:bg-neutral-800 cursor-pointer items-center">
          <span className=" ml-2.5 mr-2">Share: </span>
          <EmailShareButton url={url} style={style} subject={description}>
            <EmailIcon size={25} round={true} />
          </EmailShareButton>
          <FacebookShareButton
            url={url}
            quote={description}
            hashtag={"HSS HolidayShortStays"}
            style={style}
          >
            <FacebookIcon size={25} round={true} />
          </FacebookShareButton>
          <TwitterShareButton
            url={url}
            title={description}
            hashtags={["HSS", "HolidayShortStays"]}
            style={style}
          >
            <TwitterIcon size={25} round={true} />
          </TwitterShareButton>
          <LinkedinShareButton url={url} style={style}>
            <LinkedinIcon size={25} round={true} />
          </LinkedinShareButton>
          <WhatsappShareButton
            title={description}
            separator=" "
            url={url}
            style={style}
          >
            <WhatsappIcon size={25} round={true} />
          </WhatsappShareButton>
        </span>
        {/* <span className="py-1.5 px-3 flex rounded-lg hover:bg-neutral-100 dark:hover:bg-neutral-800 cursor-pointer">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={1.5}
              d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z"
            />
          </svg>
          <span className="hidden sm:block ml-2.5">Save</span>
        </span> */}
      </div>
    </div>
  );
};

export default LikeSaveBtns;
