import { profileConstants } from "../actionTypes";

const INITIAL_STATE = {
  loading: false,
  loadingPicture: false,
  error: {},
  profile: {
    first_name: "",
    last_name: "",
    company_name: "",
    bio: "",
    email: "",
    phone: "",
    phone1: "",
    gender: "",
    date_of_birth: "",
    location: {
      address: "",
      city: "",
      state: "",
    },
    social: {
      instagram: "",
      twitter: "",
      facebook: "",
      youtube: "",
    },
    profile_url: "",
    website: "",
    status: "",
  },
};

const profileReducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case profileConstants.LOADING:
      return {
        ...state,
        loading: true,
      };
    case profileConstants.STOP_LOADING:
      return {
        ...state,
        loading: false,
      };
    case profileConstants.LOADING_PICTURE:
      return {
        ...state,
        loadingPicture: true,
      };
    case profileConstants.GET_PROFILE:
      return {
        ...state,
        profile: {
          ...state.profile,
          ...payload,
        },
      };
    case profileConstants.EDIT_PROFILE:
      return {
        ...state,
        profile: {
          ...state.profile,
          ...payload,
          // [payload.newName]: payload.newValue,
        },
      };
    case profileConstants.STOP_LOADING_PICTURE:
      return {
        ...state,
        loadingPicture: false,
      };
    case profileConstants.ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
      };
    default:
      return state;
  }
};

export default profileReducer;
