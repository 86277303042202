import { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Input from "shared/Input/Input";
import Select from "shared/Select/Select";
import FormItem from "./FormItem";
import { RootState } from "redux/reducers";
import Textarea from "shared/Textarea/Textarea";
import LoadingGif from "./../../images/loading-gif.svg";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import cogoToast from "cogo-toast";
import { useHistory, useLocation, useParams } from "react-router-dom";
import {
  addCarFeaturedImage,
  addOtherImages,
  fetchCarDetails,
  getAllMakes,
  updateCarAction,
} from "redux/actions/carActions";
import { ButtonLoader } from "components/ButtonLoader.jsx";
import { getCustomLocations } from "redux/actions/listingActions";
import { carConstants } from "redux/actionTypes";

export interface PageAddListing1Props {}

export interface SingleCarProps {
  carid: string;
}

const PageEditCar: FC<PageAddListing1Props> = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { carid } = useParams<SingleCarProps>();
  const location = useLocation();

  let carDetails: any = location.state;

  // const cars: any = useSelector((store: RootState) => store.cars);
  const { makes, car, fetching, ownerscount }: any = useSelector(
    (state: RootState) => state.cars
  );
  const auth = useSelector((store: RootState) => store.auth);
  const listing = useSelector((store: RootState) => store.listing);

  const [state, setState] = useState({
    loading: false,
    fetchingFeatured: false,
    fetchingOthers: false,
    make: "",
    model: "",
    year: "",
    title: "",
    condition: "brand new",
    color: "",
    location: "",
    sublocation: "",
    description: "",
    price: "",
    featured_image: "",
    other_imgs: [],
  });

  console.log("car details", carDetails);

  // Fetch car details
  useEffect(() => {
    const getCarDetails = async () => {
      await fetchCarDetails(carid, dispatch);
    };

    async function getMakes() {
      await getAllMakes(dispatch);
    }

    async function getLocations() {
      await getCustomLocations(dispatch);
    }

    getMakes();
    getLocations();
    getCarDetails();
  }, []);

  useEffect(() => {
    if (car) {
      car["make"] = car["make_id"]["_id"];
      car["featured_image"] = car.featured_img;
      setState({ ...state, ...car });
    }
  }, [car]);

  const selectChange = (event: React.FormEvent<HTMLSelectElement>) => {
    const newValue: any = {};
    newValue[event.currentTarget.name] = event.currentTarget.value;
    setState({ ...state, ...newValue });
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let newValue: any = {};
    newValue[e.target.name] = e.target.value;
    console.log(newValue);

    setState({ ...state, ...newValue });
  };

  const handleChangeTextArea = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newValue: any = {};
    newValue[e.target.name] = e.target.value;
    setState({ ...state, ...newValue });
  };

  const changeMake = (event: React.FormEvent<HTMLSelectElement>) => {
    const newValue: any = {};
    const makeid =
      event.currentTarget.options[event.currentTarget.selectedIndex].value;
    console.log("makeid", makeid, "name", event.currentTarget.name);
    // const evt = event.currentTarget.getAttribute("data-slug");
    newValue[event.currentTarget.name] = makeid;

    setState({ ...state, ...newValue });
  };

  //  Images Upload
  const handleChangeFeatured = async (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setState({ ...state, ...{ fetchingFeatured: true } });

    const fileList = e.target.files;
    if (!fileList) return;

    const featuredImgUrl = await addCarFeaturedImage(fileList[0]);
    console.log("featuredImgUrl ->", featuredImgUrl);
    const fImg: any = {
      featured_image: featuredImgUrl,
      fetchingFeatured: false,
    };
    setState({
      ...state,
      ...fImg,
    });
  };

  const handleChangeOthers = async (e: React.ChangeEvent<HTMLInputElement>) => {
    setState({ ...state, ...{ fetchingOthers: true } });
    const fileList = e.target.files;
    if (!fileList) return;
    const otherImages: any = await addOtherImages(fileList);
    console.log("otherImages", otherImages);
    const oImgs: any = {
      other_imgs: [...state.other_imgs, ...otherImages],
      fetchingOthers: false,
    };
    setState({
      ...state,
      ...oImgs,
    });
  };

  const processUpdateCar = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    setState({ ...state, loading: true });
    // const user_id = auth.userData.user_id ?? auth.userData._id;

    const updateCar = async () => {
      const { type, payload: message } = await updateCarAction(
        state,
        carDetails._id,
        dispatch
      );

      if (type === "ERROR") {
        cogoToast.error(message, {
          position: "top-right",
          hideAfter: 5,
        });
        setState({ ...state, loading: false });
      } else {
        cogoToast.success("Car Listing Update Submitted", {
          position: "top-right",
          hideAfter: 5,
        });

        setState({ ...state, loading: false });
      }
    };

    updateCar();
  };

  return (
    <form name="listCarForm" method="POST" onSubmit={processUpdateCar}>
      <div
        className={`nc-PageAddListing1 px-4 max-w-3xl mx-auto pb-24 pt-14 sm:py-24 lg:pb-32`}
        data-nc-id="PageAddListing1"
      >
        <div className="space-y-11">
          <div>
            <span className="text-4xl font-semibold">Edit Car Details</span>
          </div>

          {/* --------------------- */}
          <div className="listingSection__wrap ">
            {/* FORM */}
            <div className="space-y-8">
              <FormItem label="Title of Car Listing">
                <Input
                  placeholder="car title"
                  name="title"
                  onChange={handleChange}
                  value={state.title}
                />
              </FormItem>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-8 md:gap-5">
                <FormItem label="Car Make">
                  <Select
                    name="make"
                    onChange={changeMake}
                    value={car?.make_id?._id}
                  >
                    <option key="none" value="none">
                      Select a Car Make
                    </option>
                    {makes &&
                      makes.map((car: any, index: number) => (
                        <option key={index} value={car._id}>
                          {car.make}
                        </option>
                      ))}
                    <option
                      key="62e3d6ce8a84adacfda58c4c"
                      value="62e3d6ce8a84adacfda58c4c"
                    >
                      Others
                    </option>
                  </Select>
                </FormItem>
                <FormItem label="Enter Model">
                  <Input
                    name="model"
                    onChange={handleChange}
                    value={state.model}
                  />
                </FormItem>
              </div>

              <div className="grid grid-cols-1 md:grid-cols-2 gap-8 md:gap-5">
                <FormItem label="Year">
                  <Input
                    name="year"
                    onChange={handleChange}
                    value={state.year}
                  />
                </FormItem>
                <FormItem label="Color">
                  <Input
                    placeholder="color"
                    name="color"
                    onChange={handleChange}
                    value={state.color}
                  />
                </FormItem>
              </div>

              <div className="grid grid-cols-1 md:grid-cols-2 gap-8 md:gap-5">
                <FormItem label="Location">
                  <Select
                    name="location"
                    onChange={selectChange}
                    value={car?.location}
                  >
                    <option>--Select Location--</option>
                    {listing &&
                      listing.customLocations.map((eachLocation: any) => (
                        <option
                          key={eachLocation.slug}
                          value={eachLocation._id}
                          data-slug={eachLocation.state}
                        >
                          {eachLocation.location}
                        </option>
                      ))}
                  </Select>
                </FormItem>
                <FormItem label="Sub Location">
                  <Input
                    placeholder="sub location"
                    name="sublocation"
                    onChange={handleChange}
                    value={state.sublocation}
                  />
                </FormItem>
              </div>

              <FormItem label="Short Description of the Car">
                <Textarea
                  placeholder="..."
                  rows={3}
                  name="description"
                  value={state.description}
                  onChange={handleChangeTextArea}
                />
              </FormItem>

              <FormItem label="Rental Price per day">
                <div className="relative">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <span className="text-gray-500">&#8358;</span>
                  </div>
                  <Input
                    className="!pl-8 !pr-10"
                    placeholder="0.00"
                    name="price"
                    type="number"
                    value={state.price}
                    onChange={handleChange}
                  />
                  <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                    <span className="text-gray-500">NGN</span>
                  </div>
                </div>
              </FormItem>
            </div>

            <>
              <div>
                <span className="text-lg font-semibold">
                  Upload Cover image
                </span>
                <div className="mt-5 ">
                  <div>
                    <input
                      className="block w-full pt-0 pb-0 h-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 cursor-pointer dark:text-gray-400 focus:outline-none focus:border-transparent dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
                      aria-describedby="featured_image_help"
                      id="featured_image"
                      name="featured_image"
                      type="file"
                      accept="image/*"
                      onChange={handleChangeFeatured}
                    />
                    <div
                      className="mt-1 text-sm text-gray-500 dark:text-gray-300"
                      id="featured_image_help"
                    >
                      Only one picture allowed
                    </div>
                  </div>
                </div>
                <div className="grid grid-cols-6 gap-4 mt-4">
                  {state.featured_image ? (
                    <span>
                      <img src={state.featured_image} alt="feat_img" />
                    </span>
                  ) : null}
                  {state.fetchingFeatured ? (
                    <span>
                      <img src={LoadingGif} alt="Loading" />
                    </span>
                  ) : null}
                </div>
              </div>
              {/* ----------------- */}
              <div className="w-48 border-b border-neutral-200 dark:border-neutral-700"></div>
              <div>
                <span className="text-lg font-semibold">
                  Upload Pictures of the car
                </span>
                <div className="mt-5 ">
                  <input
                    className="block w-full h-full pt-0 pb-0 text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 cursor-pointer dark:text-gray-400 focus:outline-none focus:border-transparent dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
                    aria-describedby="car_images_help"
                    id="car_images"
                    type="file"
                    name="car_images"
                    onChange={handleChangeOthers}
                    accept="image/*"
                    multiple
                  />
                  <div
                    className="mt-1 text-sm text-gray-500 dark:text-gray-300"
                    id="car_images_help"
                  >
                    Multiple picutures are allowed. Minimum of 5. Maximum of 10
                  </div>
                </div>
                <div className="grid grid-cols-6 gap-4 mt-4">
                  {state.other_imgs
                    ? state.other_imgs.map(
                        (otherimg: string, index: number) => (
                          <span key={index}>
                            <img src={otherimg} alt="other_img" />
                          </span>
                        )
                      )
                    : null}
                  {state.fetchingOthers ? (
                    <span>
                      <img src={LoadingGif} alt="Loading" />
                    </span>
                  ) : null}
                </div>
              </div>
            </>
          </div>

          {/* --------------------- */}
          <div className="flex justify-end space-x-5">
            <ButtonPrimary
              type="submit"
              disabled={
                state.make !== "" &&
                state.model !== "" &&
                state.featured_image !== "" &&
                state.other_imgs.length !== 0
                  ? false
                  : true
              }
            >
              {state.loading ? <ButtonLoader /> : "Complete Car Listing"}
            </ButtonPrimary>
          </div>
        </div>
      </div>
    </form>
  );
};

export default PageEditCar;
