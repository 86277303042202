import React from "react";
import { Redirect, Route, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  object as objectPropType,
  array as arrayPropType,
  func,
  oneOfType,
} from "prop-types";

/**
 * @function ProtectedRoute
 * @description - constructs the applications protected route layout
 * @returns {JSX} - JSX
 */
const ProtectedRoute = ({ component: Component, ...rest }) => {
  const { isAuthenticated } = useSelector((store) => store.auth);

  const pathname = useLocation();

  return (
    <Route
      {...rest}
      render={() =>
        isAuthenticated ? (
          <Component redirectTo={pathname.pathname} />
        ) : (
          <Redirect to="/login" from={pathname.pathname} />
        )
      }
    />
  );
};

ProtectedRoute.propTypes = {
  component: oneOfType([arrayPropType, objectPropType, func]).isRequired,
};

export default ProtectedRoute;
