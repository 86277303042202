import { NavItemType } from "shared/Navigation/NavigationItem";
import ncNanoId from "utils/ncNanoId";
// import __megamenu from "./jsons/__megamenu.json";

export const NAVIGATION_DEMO: NavItemType[] = [
  {
    id: ncNanoId(),
    href: "/",
    name: "Home",
  },
  {
    id: ncNanoId(),
    href: "/shortlets",
    name: "Shortlets",
  },
  // {
  //   id: ncNanoId(),
  //   href: "/hotels",
  //   name: "Hotels",
  // },
  {
    id: ncNanoId(),
    href: "/car-rentals",
    name: "Car Rentals",
  },
  {
    id: ncNanoId(),
    href: "/boats",
    name: "Boats",
  },
  // {
  //   id: ncNanoId(),
  //   href: "/boat",
  //   name: "Boat",
  // },
];

export const ACCOUNT_MENU: NavItemType[] = [
  {
    id: ncNanoId(),
    href: "/dashboard",
    name: "Dashboard",
  },
  {
    id: ncNanoId(),
    href: "/profile",
    name: "My Profile",
  },
  {
    id: ncNanoId(),
    href: "/add-listing",
    name: "List An Apartment",
  },
  {
    id: ncNanoId(),
    href: "/list-car",
    name: "List a Car",
  },
  {
    id: ncNanoId(),
    href: "/list-boat",
    name: "List A Boat",
  },
];
