import React, { FC, useState, useEffect } from "react";
// import AnyReactComponent from "components/AnyReactComponent/AnyReactComponent";
// import StayCardH from "components/StayCardH/StayCardH";
// import GoogleMapReact from "google-map-react";
import { DEMO_STAY_LISTINGS } from "data/listings";
// import ButtonClose from "shared/ButtonClose/ButtonClose";
// import Checkbox from "shared/Checkbox/Checkbox";
// import Pagination from "shared/Pagination/Pagination";
// import TabFilters from "./TabFilters";
import Heading2 from "components/Heading/Heading2";
import { fetchApartmentsByLocation } from "redux/actions/listActions";
import { RootState } from "redux/reducers";
import { ApartmentDataType } from "data/types";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { ShowLoading } from "components/ShowLoading";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ApartmentsLocation from "components/StayCardH/ApartmentsLocation";
import SectionGridFilterCard from "./SectionGridFilterCard";

const DEMO_STAYS = DEMO_STAY_LISTINGS.filter((_, i) => i < 12);

export interface SectionGridHasMapProps {}
export interface ShortletsProps {
  loading: boolean;
  apartmentsByLocation: Array<ApartmentDataType>;
  count?: number;
  pagination?: any;
  currentPage?: number;
  aptLocation: any;
}

export interface SingleAparmentProps {
  location_slug: string;
  location_id: string;
}

const SectionGridHasMap: FC<SectionGridHasMapProps> = () => {
  const [currentHoverID, setCurrentHoverID] = useState<string | number>(-1);
  // const [showFullMapFixed, setShowFullMapFixed] = useState(false);
  const [page, setPage] = useState(1);
  const [filter, setFilter] = useState({});
  const [loadingMore, setLoadingMore] = useState(false);
  const { location_slug, location_id } = useParams<SingleAparmentProps>();

  const dispatch = useDispatch();

  const {
    loading,
    apartmentsByLocation,
    pagination,
    aptLocation,
  }: ShortletsProps = useSelector((state: RootState) => state.list);

  useEffect(() => {
    const fetchShortletsByLocation = async () => {
      await fetchApartmentsByLocation(
        filter,
        page,
        false,
        location_slug,
        location_id,
        dispatch
      );
    };

    fetchShortletsByLocation();
    // if (apartmentsByLocation.length === 0) fetchShortletsByLocation();
  }, []);

  console.log(
    "aptLocation",
    aptLocation,
    "apartmentsByLocation",
    apartmentsByLocation
  );

  const loadMore = async (page: number) => {
    setLoadingMore(true);
    setPage(page);
    await fetchApartmentsByLocation(
      filter,
      page,
      false,
      location_slug,
      location_id,
      dispatch
    );
    setLoadingMore(false);
  };

  console.log("apartmentsByLocation", apartmentsByLocation);

  return (
    <div>
      <Heading2
        heading={
          aptLocation && `Shortlet Apartments in ${aptLocation.location}`
        }
      />
      {loading && <ShowLoading />}

      {!loading && apartmentsByLocation.length == 0 && (
        <h2 className="text-xl">No apartment found for this location</h2>
      )}

      {!loading && apartmentsByLocation.length > 0 && (
        <div className="relative flex">
          {/* CARDSSSS */}
          <div className="pb-10">
            {/* w-full xl:w-[780px] 2xl:w-[880px] flex-shrink-0 xl:px-8 */}
            {/* <div className="mb-8 lg:mb-11">
            <TabFilters />
          </div> */}

            {/* {!loading && apartmentsByLocation.length > 0 && (
              <SectionGridFilterCard className="" data={shortlets} />
            )} */}

            <div className="grid grid-cols-1 gap-6 md:gap-8 xl:grid-cols-2">
              {apartmentsByLocation.map((item, id) => (
                <div
                  key={id}
                  onMouseEnter={() => setCurrentHoverID((_) => id)}
                  onMouseLeave={() => setCurrentHoverID((_) => -1)}
                >
                  <ApartmentsLocation data={item} />
                </div>
              ))}
            </div>
            <div className="flex mt-16 justify-center items-center pb-24">
              {!loading && pagination.nextPage && (
                <div className="flex mt-16 justify-center items-center pb-0">
                  <ButtonPrimary
                    loading={loadingMore}
                    onClick={() => loadMore(pagination.nextPage)}
                  >
                    Load More
                  </ButtonPrimary>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SectionGridHasMap;
