import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import SectionHeroArchivePage from "components/SectionHeroArchivePage/SectionHeroArchivePage";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import { FC, useEffect, useState } from "react";
import SectionGridFilterCard from "./SectionGridFilterCard";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { getShortlets } from "redux/actions/shortletsAction";
import { RootState } from "redux/reducers";
import { ShowLoading } from "components/ShowLoading";
import Heading2 from "components/Heading/Heading2";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { ApartmentDataType } from "data/types";
import ApartmentCard from "components/ApartmentCard/ApartmentCard";
import CarCardDisplay from "components/CarCard/CarCardDisplay";
import BoatCardDisplay from "components/BoatCard/BoatCardDisplay";
import { toTitleCase } from "utils/helpers";

export interface ListingStayPageProps {
  className?: string;
}

export interface ResultProps {
  // category: "";
  // apartments?: Array<ApartmentDataType>;
  // cars?: Array<any>;
  // boats?: Array<any>;
  count: number;
  // pagination: { nextPage: number };
  // currentPage: number;
  searchResult: {
    category: String;
    apartments?: any;
    cars?: any;
    boats?: any;
    count: number;
  };
}

const SearchResultPage: FC<ListingStayPageProps> = ({ className = "" }) => {
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [filter, setFilter] = useState({});
  const [loadingMore, setLoadingMore] = useState(false);

  const { searchResult, count }: ResultProps = useSelector(
    (store: RootState) => store.booking
  );

  const category = searchResult.category;

  let searchContent: Array<any> = [];
  if (category === "apartment") searchContent = searchResult.apartments;
  else if (category === "car") searchContent = searchResult.cars;
  else if (category === "boat") searchContent = searchResult.boats;

  console.log("searchResult", searchResult);

  // console.log("shortlets", shortlets);
  // console.log("currentPage", currentPage);

  // useEffect(() => {
  //   const fetchShortlets = async () => {
  //     await getShortlets(filter, 1, false, dispatch);
  //   };

  //   if (shortlets.length === 0) fetchShortlets();
  // }, [dispatch]);

  const loadMore = async (page: number) => {
    setLoadingMore(true);
    setPage(page);
    await getShortlets(filter, page, true, dispatch);
    setLoadingMore(false);
  };

  return (
    <div
      className={`nc-ListingStayPage relative overflow-hidden ${className}`}
      data-nc-id="ListingStayPage"
    >
      <Helmet>
        <title>Shortlet Apartments - Holiday Short Stays</title>
      </Helmet>
      <BgGlassmorphism />

      <div className="container relative overflow-hidden">
        {/* SECTION HERO */}
        <SectionHeroArchivePage
          currentPage="Stay"
          currentTab="Stay"
          className=""
          heroText="Search Results"
          category={category}
          subtitleText="Results have been tailored based on your search"
        />

        {/* SECTION */}
        <Heading2
          heading={`Search result: ${searchContent[0]?.location?.location}, ${searchContent[0]?.location?.state}`}
          subHeading={
            category !== "boat" ??
            `${searchResult.count ?? "0"} ${category}s found in ${
              searchContent[0].location.location
            }`
          }
        />
        {/* {loading && <ShowLoading />} */}

        {searchResult && (
          <div
            className={`nc-SectionGridFilterCard ${className}`}
            data-nc-id="SectionGridFilterCard"
          >
            {category === "apartment" && (
              <div className="grid grid-cols-1 gap-6 md:gap-8 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
                {searchContent.map((stay: ApartmentDataType, index: number) => (
                  <ApartmentCard key={index} data={stay} />
                ))}
              </div>
            )}

            {category === "car" && (
              <div className="grid grid-cols-1 gap-6 md:gap-8 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
                {searchContent.map((car) => (
                  <CarCardDisplay key={car._id} data={car} />
                ))}
              </div>
            )}

            {category === "boat" && (
              <div className="grid grid-cols-1 gap-6 md:gap-8 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
                {searchContent.map((boat) => (
                  <BoatCardDisplay key={boat._id} data={boat} />
                ))}
              </div>
            )}
          </div>
        )}

        {/* {!loading && pagination.nextPage && (
          <div className="flex mt-16 justify-center items-center pb-24 lg:pb-32">
            <ButtonPrimary
              loading={loadingMore}
              onClick={() => loadMore(pagination.nextPage)}
            >
              Load More
            </ButtonPrimary>
          </div>
        )}  */}

        {/* SECTION 1 */}
        {/* <div className="relative py-16">
          <BackgroundSection />
          <SectionSliderNewCategories
            heading="Explore by types of stays"
            subHeading="Explore houses based on 10 types of stays"
            categoryCardType="card5"
            itemPerRow={5}
            sliderStyle="style2"
          />
        </div> */}

        {/* SECTION */}
        <SectionSubscribe2 className="py-24 lg:py-32" />

        {/* SECTION */}
        {/* <div className="relative py-16 mb-24 lg:mb-32">
          <BackgroundSection className="bg-orange-50 dark:bg-black dark:bg-opacity-20 " />
          <SectionGridAuthorBox />
        </div> */}
      </div>
    </div>
  );
};

export default SearchResultPage;
