import { bookingConstants } from "../actionTypes";
import moment from "moment";

const INITIAL_STATE = {
  loading: false,
  error: "",
  price: 0,
  checkIn: moment(),
  checkOut: moment(),
  guests: {
    guestAdults: 0,
    guestChildren: 0,
    guestInfants: 0,
  },
  noDays: 0,
  serviceCharge: 0,
  total: 0,
  grandTotal: 0,
  apartmentTitle: "",
  apartmentSlug: "",
  success: false,
  bookingPayload: {},
  paymentSuccess: false,
  paymentFailure: false,
  mybookings: [],
  mybookingscount: 0,
  inquirySuccess: false,
  searchResult: {},
  hostProperties: {},
};

const bookingReducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case bookingConstants.LOADING:
      return {
        ...state,
        loading: true,
      };
    case bookingConstants.STOP_LOADING:
      return {
        ...state,
        loading: false,
      };
    case bookingConstants.SET_ORDER:
      return {
        ...state,
        ...payload,
      };
    case bookingConstants.CREATE_BOOKING:
      return {
        ...state,
        bookingPayload: payload.data.booking,
        success: true,
        loading: false,
      };
    case bookingConstants.RESET_BOOKING:
      return INITIAL_STATE;
    case bookingConstants.RECORD_PAYMENT:
      return {
        ...state,
        paymentSuccess: true,
      };
    case bookingConstants.PAYMENT_SUCCESS:
      return {
        ...state,
        paymentSuccess: true,
      };
    case bookingConstants.PAYMENT_FAILURE:
      return {
        ...state,
        paymentFailure: true,
      };
    case bookingConstants.GET_MY_BOOKINGS:
      return {
        ...state,
        mybookings: payload.bookings,
        mybookingscount: payload.count,
        loading: false,
      };
    case bookingConstants.SUBMIT_INQUIRY:
      return {
        ...state,
        inquirySuccess: true,
      };
    case bookingConstants.SEARCH_RESULT:
      return {
        ...state,
        searchResult: payload,
      };
    case bookingConstants.PROPERTIES_BY_HOST:
      return {
        ...state,
        hostProperties: payload,
      };
    case bookingConstants.ERROR:
      return {
        ...state,
        success: false,
        error: payload,
      };
    default:
      return state;
  }
};

export default bookingReducer;
