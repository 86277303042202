import { combineReducers } from "redux";
import auth from "./authReducer";
import profile from "./profileReducer";
import listing from "./listingReducer";
import list from "./listReducer";
import booking from "./bookingReducer";
import shortlets from "./shortletsReducer";
import hotels from "./hotelReducer";
import cars from "./carReducer";
import boats from "./boatReducer";

export const rootReducer = combineReducers({
  auth,
  profile,
  listing,
  list,
  booking,
  shortlets,
  hotels,
  cars,
  boats,
});

export type RootState = ReturnType<typeof rootReducer>;
