import React, { useEffect } from "react";
import ReactGA from "react-ga";
import MyRouter from "routers/index";

function App() {
  const setGA = () => {
    ReactGA.initialize("UA-219244294-1");
    ReactGA.pageview("App component/page");
  };

  useEffect(() => {
    setGA();
  }, []);

  return (
    <div className="bg-white text-base dark:bg-neutral-900 text-neutral-900 dark:text-neutral-200">
      <MyRouter />
    </div>
  );
}

export default App;
