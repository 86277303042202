import httpRequest from "../../api";
import { authConstants } from "../actionTypes";

const saveToStorage = ({ token, user }) => {
  // sessionStorage.setItem("token", token);
  // sessionStorage.setItem("userData", JSON.stringify(user));

  localStorage.setItem("token", token);
  localStorage.setItem("userData", JSON.stringify(user));
};

export const registerUserAction = (data, dispatch) => {
  return httpRequest("post", "auth/register", data)
    .then(({ data }) => {
      console.log("Data", data);

      const action = { type: authConstants.REGISTER_USER, payload: data };
      console.log("actions", action);

      dispatch(action);
      return action;
    })
    .catch(({ response }) => {
      const message = response?.data; //.data?.message;
      return {
        payload: message,
        type: "ERROR",
      };
    });
};

// payload:
//     data: {message: 'You have successfully registered', user: {…}}
//     status: 200
// type: "REGISTER_USER"

// ===============================================

export const loginUserAuth = (data, dispatch) => {
  dispatch({ type: authConstants.AUTH_LOADING });
  return httpRequest("post", "auth/login", data)
    .then(({ data }) => {
      console.log("Data", data);

      let action = {};

      if (data.status === 200) {
        action = { type: authConstants.LOGIN_USER, payload: data.data };
        console.log("success");

        const {
          token,
          user: {
            _id: user_id,
            first_name,
            last_name,
            email,
            location,
            phone,
            status,
            bio,
            created_at,
            profile_url,
            rating,
          },
        } = data.data;

        const user = {
          user_id,
          first_name,
          last_name,
          email,
          location,
          phone,
          status,
          bio,
          created_at,
          profile_url,
          rating,
        };
        saveToStorage({ token, user });
      } else {
        action = { type: authConstants.ERROR, payload: data };
        console.log("failure");
      }

      console.log("actions", action);

      dispatch(action);
      return { status: "success", message: data.data.message };
    })
    .catch(({ response }) => {
      console.log("response", response);
      const message = response?.data?.data.error;
      dispatch({ type: authConstants.STOP_AUTH_LOADING });
      return {
        status: "error",
        message: message,
      };
    });
};

// ===============================================

export const forgotPasswordAuth = (data, dispatch) => {
  dispatch({ type: authConstants.AUTH_LOADING });
  return httpRequest("post", "auth/forgotpassword", data)
    .then(({ data }) => {
      dispatch({ type: authConstants.STOP_AUTH_LOADING });
      return { status: "success", message: data.data.message };
    })
    .catch(({ response }) => {
      console.log("response", response);
      const message = response?.data?.data.error;
      dispatch({ type: authConstants.STOP_AUTH_LOADING });
      return {
        status: "error",
        message: message,
      };
    });
};

// ===============================================

export const resetPasswordAuth = (data, dispatch) => {
  dispatch({ type: authConstants.AUTH_LOADING });
  return httpRequest("post", "auth/resetpassword", data)
    .then(({ data }) => {
      dispatch({ type: authConstants.STOP_AUTH_LOADING });
      return { status: "success", message: data.data.message };
    })
    .catch(({ response }) => {
      console.log("response", response);
      const message = response?.data?.data.error;
      dispatch({ type: authConstants.STOP_AUTH_LOADING });
      return {
        status: "error",
        message: message,
      };
    });
};

// ===============================================
export const logoutAction = () => {
  // remove local auth
  localStorage.removeItem("userData");
  localStorage.removeItem("token");

  // clear session auth
  sessionStorage.removeItem("userData");
  sessionStorage.removeItem("token");

  window.location.reload();
};

// ===============================================

export const getStates = () => {
  const states = [
    "----",
    "Abuja FCT",
    "Anambra",
    "Enugu",
    "Akwa Ibom",
    "Adamawa",
    "Abia",
    "Bauchi",
    "Bayelsa",
    "Benue",
    "Borno",
    "Cross River",
    "Delta",
    "Ebonyi",
    "Edo",
    "Ekiti",
    "Gombe",
    "Imo",
    "Jigawa",
    "Kaduna",
    "Kano",
    "Katsina",
    "Kebbi",
    "Kogi",
    "Kwara",
    "Lagos",
    "Nasarawa",
    "Niger",
    "Ogun",
    "Ondo",
    "Osun",
    "Oyo",
    "Plateau",
    "Rivers",
    "Sokoto",
    "Taraba",
    "Yobe",
    "Zamfara",
    "Outside Nigeria",
  ];
  return states;
};
