import React, { FC, ReactNode, useEffect } from "react";
import { DEMO_STAY_LISTINGS } from "data/listings";
import { ApartmentDataType, StayDataType } from "data/types";
// import ButtonPrimary from "shared/Button/ButtonPrimary";
import HeaderFilter from "./HeaderFilter";
import { ShowLoading } from "components/ShowLoading";
import { fetchFeaturedApartments } from "redux/actions/listActions";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "redux/reducers";
import ApartmentCard from "components/ApartmentCard/ApartmentCard";

// OTHER DEMO WILL PASS PROPS
const DEMO_DATA: StayDataType[] = DEMO_STAY_LISTINGS.filter((_, i) => i < 8);

//
export interface SectionGridFeaturePlacesProps {
  stayListings?: StayDataType[];
  gridClass?: string;
  heading?: ReactNode;
  subHeading?: ReactNode;
  headingIsCenter?: boolean;
  tabs?: string[];
}

export interface FeaturedProps {
  loading: boolean;
  featuredApartments: Array<ApartmentDataType>;
}

const SectionGridFeaturePlaces: FC<SectionGridFeaturePlacesProps> = ({
  stayListings = DEMO_DATA,
  gridClass = "",
  heading = "Featured places to stay",
  subHeading = "Popular places to stay specially recommended for you",
  headingIsCenter,
  tabs = ["Bluewaters", "Lekki Phase 1", "Ikate", "Victoria Island"],
}) => {
  const dispatch = useDispatch();

  const { loading, featuredApartments }: FeaturedProps = useSelector(
    (store: RootState) => store.list
  );

  useEffect(() => {
    const fetchShortlets = async () => {
      await fetchFeaturedApartments(dispatch);
    };

    if (featuredApartments.length === 0) fetchShortlets();
  }, [dispatch]);

  const renderCard = (stay: ApartmentDataType, index: number) => {
    return <ApartmentCard key={index} data={stay} />;
  };

  return (
    <div className="nc-SectionGridFeaturePlaces relative">
      <HeaderFilter
        tabActive={"Bluewaters"}
        subHeading={subHeading}
        tabs={tabs}
        heading={heading}
        onClickTab={() => {}}
      />

      {loading && <ShowLoading />}

      {!loading && featuredApartments.length > 0 && (
        <div
          className={`grid gap-6 md:gap-8 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 ${gridClass}`}
        >
          {featuredApartments.map((stay, index) => renderCard(stay, index))}
        </div>
      )}

      {/* <div className="flex mt-16 justify-center items-center">
        <ButtonPrimary loading>Show me more</ButtonPrimary>
      </div> */}
    </div>
  );
};

export default SectionGridFeaturePlaces;
