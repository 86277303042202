// import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
// import SectionGridAuthorBox from "components/SectionGridAuthorBox/SectionGridAuthorBox";
import SectionHeroArchivePage from "components/SectionHeroArchivePage/SectionHeroArchivePage";
// import SectionSliderNewCategories from "components/SectionSliderNewCategories/SectionSliderNewCategories";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import { FC, useEffect, useState } from "react";
import SectionGridFilterCard from "./SectionGridFilterCard";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getShortlets } from "redux/actions/shortletsAction";
import { RootState } from "redux/reducers";
import { ShowLoading } from "components/ShowLoading";
import Heading2 from "components/Heading/Heading2";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { ApartmentDataType } from "data/types";
import { getPropertiesByHost } from "redux/actions/listActions";
import ApartmentCard from "components/ApartmentCard/ApartmentCard";
import CarCardDisplay from "components/CarCard/CarCardDisplay";
import BoatCardDisplay from "components/BoatCard/BoatCardDisplay";
import { toTitleCase } from "utils/helpers";

export interface ListingStayPageProps {
  className?: string;
}

export interface ShortletsProps {
  loading: boolean;
  shortlets: Array<ApartmentDataType>;
  count: number;
  pagination: { nextPage: number };
  currentPage: number;
}

export interface HostParams {
  property: string;
  hostid: string;
}

export interface ResultProps {
  loading: false;
  count: number;
  hostProperties: {
    category: String;
    apartments?: any;
    cars?: any;
    boats?: any;
    count: number;
    user: {
      first_name?: string;
      last_name?: string;
      company_name?: string;
    };
  };
}

const PropertiesByHostPage: FC<ListingStayPageProps> = ({ className = "" }) => {
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [filter, setFilter] = useState({});
  const [loadingMore, setLoadingMore] = useState(false);
  const { property, hostid } = useParams<HostParams>();
  //   const [ownerName, setOwnerName] = useState("");
  const [heading, setHeading] = useState("");

  console.log("property", property, "hostid", hostid);

  const { loading, hostProperties, count }: ResultProps = useSelector(
    (store: RootState) => store.booking
  );

  const category = hostProperties.category;
  const userDetails = hostProperties.user;

  console.log("userDetails", userDetails);

  let ownerName = "";
  let propCategory = "";
  //     userDetails && userDetails.company_name
  //       ? userDetails.company_name
  //       : `${userDetails.first_name} ${userDetails.last_name}`;

  let searchContent: Array<any> = [];
  if (category === "apartment") {
    searchContent = hostProperties.apartments;
    propCategory = "Shortlet Apartments";
    ownerName =
      userDetails && userDetails.company_name
        ? userDetails.company_name
        : `${userDetails.first_name} ${userDetails.last_name}`;
    // setHeading(`Shortlet Apartments`);
  } else if (category === "car") {
    searchContent = hostProperties.cars;
    propCategory = "Cars";
    ownerName =
      userDetails && userDetails.company_name
        ? userDetails.company_name
        : `${userDetails.first_name} ${userDetails.last_name}`;
    // setHeading(`Cars`);
  } else if (category === "boat") {
    searchContent = hostProperties.boats;
    propCategory = "Boats";
    ownerName =
      userDetails && userDetails.company_name
        ? userDetails.company_name
        : `${userDetails.first_name} ${userDetails.last_name}`;
    // setHeading(`Boats`);
  }

  console.log("hostProperties", hostProperties);

  useEffect(() => {
    const fetchPropertiesByHost = async () => {
      await getPropertiesByHost(property, hostid, filter, 1, false, dispatch);
    };

    fetchPropertiesByHost();
  }, [dispatch]);

  const loadMore = async (page: number) => {
    setLoadingMore(true);
    setPage(page);
    await getPropertiesByHost(property, hostid, filter, page, true, dispatch);
    setLoadingMore(false);
  };

  return (
    <div
      className={`nc-ListingStayPage relative overflow-hidden ${className}`}
      data-nc-id="ListingStayPage"
    >
      <Helmet>
        <title>Shortlet Apartments - Holiday Short Stays</title>
      </Helmet>
      <BgGlassmorphism />

      <div className="container relative overflow-hidden">
        {/* SECTION HERO */}
        <SectionHeroArchivePage
          currentPage="Stay"
          currentTab="Stay"
          className=""
          heroText="Shortlet Apartments Equipped For You"
          subtitleText="Check out our array of shortlet apartments tailored for your
              perfect holiday"
        />

        {/* SECTION */}
        <Heading2 heading={`${propCategory} by ${ownerName}`} />
        {loading && <ShowLoading />}

        {hostProperties && (
          <div
            className={`nc-SectionGridFilterCard ${className}`}
            data-nc-id="SectionGridFilterCard"
          >
            {category === "apartment" && (
              <div className="grid grid-cols-1 gap-6 md:gap-8 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
                {searchContent.map((stay: ApartmentDataType, index: number) => (
                  <ApartmentCard key={index} data={stay} />
                ))}
              </div>
            )}

            {category === "car" && (
              <div className="grid grid-cols-1 gap-6 md:gap-8 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
                {searchContent.map((car) => (
                  <CarCardDisplay key={car._id} data={car} />
                ))}
              </div>
            )}

            {category === "boat" && (
              <div className="grid grid-cols-1 gap-6 md:gap-8 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
                {searchContent.map((boat) => (
                  <BoatCardDisplay key={boat._id} data={boat} />
                ))}
              </div>
            )}
          </div>
        )}

        {/* {!loading && shortlets.length > 0 && (
          <SectionGridFilterCard className="" data={shortlets} />
        )} */}

        {/* {!loading && pagination.nextPage && (
          <div className="flex mt-16 justify-center items-center pb-24 lg:pb-32">
            <ButtonPrimary
              loading={loadingMore}
              onClick={() => loadMore(pagination.nextPage)}
            >
              Load More
            </ButtonPrimary>
          </div>
        )} */}

        {/* SECTION 1 */}
        {/* <div className="relative py-16">
          <BackgroundSection />
          <SectionSliderNewCategories
            heading="Explore by types of stays"
            subHeading="Explore houses based on 10 types of stays"
            categoryCardType="card5"
            itemPerRow={5}
            sliderStyle="style2"
          />
        </div> */}

        {/* SECTION */}
        <SectionSubscribe2 className="py-24 lg:py-32" />

        {/* SECTION */}
        {/* <div className="relative py-16 mb-24 lg:mb-32">
          <BackgroundSection className="bg-orange-50 dark:bg-black dark:bg-opacity-20 " />
          <SectionGridAuthorBox />
        </div> */}
      </div>
    </div>
  );
};

export default PropertiesByHostPage;
