import React, { FC, useState } from "react";
import Input from "shared/Input/Input";
// import Select from "shared/Select/Select";
import FormItem from "./FormItem";
import NcInputNumber from "components/NcInputNumber/NcInputNumber";
import { useDispatch, useSelector } from "react-redux";
import { saveListing } from "redux/actions/listingActions";
import { RootState } from "redux/reducers";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import cogoToast from "cogo-toast";
import ErrorMessage from "components/ErrorMessage/ErrorMessage";
import { useHistory } from "react-router-dom";

export interface PageAddListing7Props {}

const PageAddListing7: FC<PageAddListing7Props> = () => {
  const dispatch = useDispatch();
  const [error, setError] = useState("");
  const history = useHistory();
  const auth = useSelector((store: RootState) => store.auth);

  console.log("AUTH", auth);
  const {
    apartment_type,
    title,
    lga,
    state,
    no_guests,
    no_bedrooms,
    description,
    price,
    min_nights,
    max_nights,
    featured_img,
    other_imgs,
  } = useSelector((store: RootState) => store.listing);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue: any = {};
    newValue[e.target.name] = e.target.value;
    saveListing(newValue, dispatch);
  };

  const getRadioValue = (name: string, val: number) => {
    const newValue = { [name]: val };
    saveListing(newValue, dispatch);
  };

  const checkStates = () => {
    console.log("Checking states...");
    setError("");
    if (
      !apartment_type &&
      !state &&
      !lga &&
      !title &&
      !no_guests &&
      !no_bedrooms &&
      !description &&
      !price &&
      !min_nights &&
      !featured_img &&
      other_imgs.length < 4
    ) {
      cogoToast.error("Kindly fill all fields before proceeding", {
        position: "top-right",
      });
      setError(
        "Some fields are not completely filled. Kindly go back and complete the form before proceeding."
      );
    } else {
      history.push("/add-listing-8");
    }
  };

  // const [dates, setDates] = useState<moment.Moment[]>([]);

  // function handleDateChange(date: moment.Moment) {
  //   const wasPreviouslyPicked = dates.some((d) => d.isSame(date));
  //   if (wasPreviouslyPicked) {
  //     setDates((previousDates) => previousDates.filter((d) => !d.isSame(date)));
  //   } else {
  //     setDates((previousDates) => [...previousDates, date]);
  //   }
  // }

  return (
    <div
      className={`nc-PageAddListing1 px-4 max-w-3xl mx-auto pb-24 pt-14 sm:py-24 lg:pb-32`}
      data-nc-id="PageAddListing1"
    >
      <div className="space-y-11">
        <div>
          <span className="text-4xl font-semibold">07</span>
          <span className="text-lg text-neutral-500 dark:text-neutral-400">
            / 8
          </span>
        </div>

        {/* --------------------- */}
        <div className="listingSection__wrap ">
          <>
            {error && <ErrorMessage text={error} />}
            <div>
              <h2 className="text-2xl font-semibold">Price your space</h2>
              <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
                The host's revenue is directly dependent on the setting of rates
                and regulations on the number of guests, the number of nights,
                and the cancellation policy.
              </span>
            </div>
            <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
            {/* FORM */}
            <div className="space-y-2">
              {/* ITEM */}
              <FormItem label="Price per night">
                <div className="relative">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <span className="text-gray-500">&#8358;</span>
                  </div>
                  <Input
                    className="!pl-8 !pr-10"
                    placeholder="0.00"
                    name="price"
                    type="number"
                    value={price ?? 0.0}
                    onChange={handleChange}
                  />
                  <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                    <span className="text-gray-500">NGN</span>
                  </div>
                </div>
              </FormItem>
            </div>
          </>

          <div className="w-28 border-b border-neutral-200 dark:border-neutral-700"></div>

          <>
            <div>
              <h2 className="text-2xl font-semibold">
                How long can guests stay?
              </h2>
              <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
                Shorter trips can mean more reservations, but you'll turn over
                your space more often.
              </span>
            </div>
            <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
            {/* FORM */}
            <div className="space-y-7">
              {/* ITEM */}
              <NcInputNumber
                label="Nights min"
                defaultValue={min_nights ?? 3}
                onChange={(v) => getRadioValue("min_nights", v)}
              />
              {/* <NcInputNumber
                label="Nights max"
                defaultValue={max_nights ?? 60}
                onChange={(v) => getRadioValue("max_nights", v)}
              /> */}
            </div>
          </>
        </div>

        {/* --------------------- */}
        <div className="flex justify-end space-x-5">
          <ButtonSecondary href="/add-listing-6">Go back</ButtonSecondary>
          <ButtonPrimary href="#" onClick={checkStates}>
            Continue
          </ButtonPrimary>
        </div>
      </div>
    </div>
  );
};

export default PageAddListing7;
