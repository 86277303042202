import React, { FC } from "react";
import CommonLayout from "./CommonLayout";
import { addFeaturedImage, addOtherImages } from "redux/actions/listingActions";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "redux/reducers";
import LoadingGif from "./../../images/loading-gif.svg";

export interface PageAddListing6Props {}

const PageAddListing6: FC<PageAddListing6Props> = () => {
  const dispatch = useDispatch();
  const listing = useSelector((store: RootState) => store.listing);
  console.log("Listing", listing);

  const handleChangeFeatured = (e: React.ChangeEvent<HTMLInputElement>) => {
    const fileList = e.target.files;
    if (!fileList) return;

    console.log("single image", fileList[0]);

    addFeaturedImage(fileList[0], "apartment", dispatch);
  };

  const handleChangeOthers = (e: React.ChangeEvent<HTMLInputElement>) => {
    const fileList = e.target.files;

    if (!fileList) return;

    console.log("multiple images", fileList);

    addOtherImages(fileList, dispatch);
  };

  return (
    <CommonLayout
      index="06"
      backtHref="/add-listing-5"
      nextHref="/add-listing-7"
    >
      <>
        <div>
          <h2 className="text-2xl font-semibold">Pictures of the place</h2>
          <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
            A few beautiful photos will help customers have more sympathy for
            your property.
          </span>
        </div>

        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        {/* FORM */}
        <div className="space-y-8">
          <div>
            <span className="text-lg font-semibold">Upload Cover image</span>
            <div className="mt-5 ">
              <div>
                <input
                  className="block w-full pt-0 pb-0 h-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 cursor-pointer dark:text-gray-400 focus:outline-none focus:border-transparent dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
                  aria-describedby="featured_image_help"
                  id="featured_image"
                  name="featured_image"
                  type="file"
                  accept="image/*"
                  onChange={handleChangeFeatured}
                />
                <div
                  className="mt-1 text-sm text-gray-500 dark:text-gray-300"
                  id="featured_image_help"
                >
                  Only one picture allowed
                </div>
              </div>
            </div>
            <div className="grid grid-cols-6 gap-4 mt-4">
              {listing && listing.featured_img ? (
                <span>
                  <img src={listing.featured_img} alt="feat_img" />
                </span>
              ) : null}
              {listing && listing.loadingFeatured ? (
                <span>
                  <img src={LoadingGif} alt="Loading" />
                </span>
              ) : null}
            </div>
          </div>
          {/* ----------------- */}
          <div className="w-48 border-b border-neutral-200 dark:border-neutral-700"></div>
          <div>
            <span className="text-lg font-semibold">
              Upload Pictures of the apartment
            </span>
            <div className="mt-5 ">
              <input
                className="block w-full h-full pt-0 pb-0 text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 cursor-pointer dark:text-gray-400 focus:outline-none focus:border-transparent dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
                aria-describedby="apartment_images_help"
                id="apartment_images"
                type="file"
                name="apartment_images"
                onChange={handleChangeOthers}
                accept="image/*"
                multiple
              />
              <div
                className="mt-1 text-sm text-gray-500 dark:text-gray-300"
                id="apartment_images_help"
              >
                Multiple picutures are allowed. Minimum of 5
              </div>
            </div>
            <div className="grid grid-cols-6 gap-4 mt-4">
              {listing && listing.other_imgs
                ? listing.other_imgs.map((otherimg: string, index: number) => (
                    <span key={index}>
                      <img src={otherimg} alt="other_img" />
                    </span>
                  ))
                : null}
              {listing && listing.loadingOthers ? (
                <span>
                  <img src={LoadingGif} alt="Loading" />
                </span>
              ) : null}
            </div>
          </div>
        </div>
      </>
    </CommonLayout>
  );
};

export default PageAddListing6;
