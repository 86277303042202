import axios from "axios";
import cogoToast from "cogo-toast";

export const setToken = () => {
  const token = sessionStorage.token ?? localStorage.token;
  if (token) {
    axios.defaults.headers.common["x-access-token"] = token;
  }
};

// export const getToken = () => {
//   const token = sessionStorage.token ?? localStorage.token;
//   if (token) {
//     axios.defaults.headers.common.Authorization = `Bearer ${token}`;
//   }
// };

export const ternaryResolver = (resolvedExpression, truthyValue, falsyValue) =>
  resolvedExpression ? truthyValue : falsyValue;

export const formatMoney = (amount) => {
  const formatted = amount
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    .toString();
  return "₦" + formatted;
};

export const formatNaira = (amount) => {
  return new Intl.NumberFormat("en-NG", {
    style: "currency",
    currency: "NGN",
  }).format(amount);
};

export const showError = (message) => {
  cogoToast.error(message, {
    position: "top-right",
    hideAfter: 5,
  });
};

export const showSuccess = (message) => {
  cogoToast.success(message, {
    position: "top-right",
    hideAfter: 5,
  });
};

export const pluralize = (count, noun, suffix = "s") =>
  `${count} ${noun}${count !== 1 ? suffix : ""}`;

export const toTitleCase = (str) => {
  return str
    .toLowerCase()
    .split(" ")
    .map(function (word) {
      return word.charAt(0).toUpperCase() + word.slice(1);
    })
    .join(" ");
};
