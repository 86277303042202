import React, { FC } from "react";
// import { DEMO_STAY_LISTINGS } from "data/listings";
import { ApartmentDataType } from "data/types";
// import Pagination from "shared/Pagination/Pagination";
// import TabFilters from "./TabFilters";
import ApartmentCard from "components/ApartmentCard/ApartmentCard";
// import Button from "shared/Button/Button";
// import ButtonPrimary from "shared/Button/ButtonPrimary";

export interface SectionGridFilterCardProps {
  className?: string;
  data?: ApartmentDataType[];
}

const SectionGridFilterCard: FC<SectionGridFilterCardProps> = ({
  className = "",
  data = [],
}) => {
  return (
    <div
      className={`nc-SectionGridFilterCard ${className}`}
      data-nc-id="SectionGridFilterCard"
    >
      {/* <div className="mb-8 lg:mb-11">
        <TabFilters />
      </div> */}
      <div className="grid grid-cols-1 gap-6 md:gap-8 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
        {data.map((stay: ApartmentDataType, index: number) => (
          <ApartmentCard key={index} data={stay} />
        ))}
      </div>
    </div>
  );
};

export default SectionGridFilterCard;
