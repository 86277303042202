import React, { FC } from "react";
import { BoatDataType } from "data/types";
import Heading2 from "components/Heading/Heading2";
import BoatCardDisplay from "components/BoatCard/BoatCardDisplay";

export interface SectionGridFilterCardProps {
  className?: string;
  data?: BoatDataType[];
}

const SectionGridFilterCard: FC<SectionGridFilterCardProps> = ({
  className = "",
  data = [],
}) => {
  return (
    <div
      className={`nc-SectionGridFilterCard ${className}`}
      data-nc-id="SectionGridFilterCard"
    >
      <Heading2 heading="Boats for Hire in Lagos" />

      <div className="grid grid-cols-1 gap-6 md:gap-8 sm:grid-cols-2 mb-24 lg:grid-cols-3 xl:grid-cols-4">
        {data.map((boat) => (
          <BoatCardDisplay key={boat._id} data={boat} />
        ))}
      </div>
      {/* <div className="flex mt-16 justify-center items-center">
        <Pagination />
      </div> */}
    </div>
  );
};

export default SectionGridFilterCard;
