import { listConstants } from "../actionTypes";

const INITIAL_STATE = {
  loading: false,
  myapartments: [],
  apartment: {},
  apartmentsByLocation: [],
  pagination: {},
  count: 0,
  aptLocation: "",
  featuredApartments: [],
  ownerscount: 0,
};

const listReducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case listConstants.LOADING:
      return {
        ...state,
        loading: true,
      };
    case listConstants.STOP_LOADING:
      return {
        ...state,
        loading: false,
      };
    case listConstants.FETCH_MY_APARTMENTS:
      return {
        ...state,
        myapartments: payload,
        loading: false,
      };
    case listConstants.GET_SINGLE_APARTMENT:
      return {
        ...state,
        apartment: payload.apartment,
        ownerscount: payload.ownerscount,
        loading: false,
      };
    case listConstants.FETCH_APARTMENTS_LOCATION:
      return {
        ...state,
        apartmentsByLocation: payload.apartments,
        count: payload.total,
        loading: false,
        pagination: payload.pagination,
        aptLocation: payload.location,
      };
    case listConstants.FETCH_MORE_APARTMENTS_LOCATION:
      return {
        ...state,
        apartmentsByLocation: [
          ...state.apartmentsByLocation,
          ...payload.apartments,
        ],
        count: payload.total,
        loading: false,
        pagination: payload.pagination,
        aptLocation: payload.location,
      };
    case listConstants.FETCH_SINGLE_LOCATION:
      return {
        ...state,
        location: payload,
        loading: false,
      };
    case listConstants.FETCH_FEATURED_APARTMENTS:
      return {
        ...state,
        featuredApartments: payload,
        loading: false,
      };
    case listConstants.DELETE_APARTMENT:
      const newapartments = state.myapartments.filter(
        (theapartment) => theapartment._id !== payload
      );

      return {
        ...INITIAL_STATE,
        loading: false,
        myapartments: [...newapartments],
      };
    case listConstants.NO_APARTMENT:
      return {
        ...state,
        apartment: {},
        loading: false,
      };
    default:
      return state;
  }
};

export default listReducer;
