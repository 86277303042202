import { FC, useEffect, useState } from "react";
import GallerySlider from "components/GallerySlider/GallerySlider";
import { ApartmentDataType } from "data/types";
import StartRating from "components/StartRating/StartRating";
import BtnLikeIcon from "components/BtnLikeIcon/BtnLikeIcon";
import SaleOffBadge from "components/SaleOffBadge/SaleOffBadge";
import Badge from "shared/Badge/Badge";
import { formatMoney } from "utils/helpers";
import { useDispatch } from "react-redux";
import cogoToast from "cogo-toast";
import { deleteApartment } from "redux/actions/listActions";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { ButtonLoader } from "components/ButtonLoader.jsx";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import { useHistory } from "react-router-dom";

export interface StayCardProps {
  className?: string;
  ratioClass?: string;
  data?: ApartmentDataType;
  owner?: boolean;
  size?: "default" | "small";
}

const ApartmentCard: FC<StayCardProps> = ({
  size = "default",
  className = "",
  data = {},
  owner = false,
  ratioClass,
}) => {
  console.log("apartment data", data);

  const {
    featured_img,
    gallery_imgs,
    apartment_type,
    address,
    title,
    bedrooms,
    like,
    saleOff,
    isAds,
    price,
    reviewStart,
    reviewCount,
    _id: id,
    slug,
    location,
    sublocation,
    host,
  } = data;
  const href = `/apartment/${id}/${slug}`;

  const dispatch = useDispatch();
  const history = useHistory();
  const [loading, setLoading] = useState(false);

  // useEffect(() => {
  //   gallery_imgs.unshift(featured_img);
  // }, []);

  // const galleryImgs = data.gallery_imgs;

  const handleDeleteApartment = () => {
    // e.preventDefault();
    const answer = window.confirm(
      "Are you sure you want to delete this apartment?\nClick OK to confirm"
    );
    if (answer) {
      const deleteThisApartment = async () => {
        const result = await deleteApartment(id, slug, dispatch);
        if (result.status) {
          cogoToast.success(result.msg, {
            position: "top-right",
          });
        }
      };

      deleteThisApartment();
    }
  };

  const handleEditApartment = () => {
    history.push({
      pathname: `/list-apartment/edit/${id}`,
    });
  };

  const renderSliderGallery = () => {
    return (
      <div className="relative w-full">
        <a href={href}>
          {gallery_imgs && (
            <GallerySlider
              uniqueID={`stay-v-${id}`}
              ratioClass={ratioClass}
              featuredImg={featured_img}
              galleryImgs={gallery_imgs}
            />
          )}
        </a>
        {/* <BtnLikeIcon isLiked={like} className="absolute right-3 top-3" /> */}
        {saleOff && <SaleOffBadge className="absolute left-3 top-3" />}
      </div>
    );
  };

  const renderContent = () => {
    return (
      <div className={size === "default" ? "p-4 space-y-4" : "p-3 space-y-2"}>
        <div className="space-y-2">
          <span className="text-sm text-neutral-500 dark:text-neutral-400">
            {apartment_type} · {bedrooms} bedroom
          </span>
          <div className="flex items-center space-x-2">
            {isAds && <Badge name="ADS" color="green" />}
            <a href={href}>
              <h2
                className={` font-medium capitalize ${
                  size === "default" ? "text-lg" : "text-base"
                }`}
              >
                <span className="line-clamp-1">{title}</span>
              </h2>
            </a>
          </div>
          <div className="flex items-center text-neutral-500 dark:text-neutral-400 text-sm space-x-2">
            {size === "default" && (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={1.5}
                  d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
                />
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={1.5}
                  d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
                />
              </svg>
            )}
            <span className="">
              {location && location.location}
              {sublocation && `, ${sublocation}`}
            </span>
          </div>
        </div>
        {!host && (
          <div className="w-14 border-b border-neutral-100 dark:border-neutral-800"></div>
        )}
        {host && (
          <div className="inline-flex space-x-3">
            <Badge
              name={
                <div className="flex items-center">
                  <i className="text-sm las la-share-alt"></i>
                  <span className="ml-1">
                    {host &&
                      (host.company_name
                        ? host.company_name
                        : host.first_name + " " + host.last_name)}
                  </span>
                </div>
              }
            />
          </div>
        )}
        <div className="flex justify-between items-center">
          <span className="text-base font-semibold">
            {price ? formatMoney(price) : 0}
            {` `}
            {size === "default" && (
              <span className="text-sm text-neutral-500 dark:text-neutral-400 font-normal">
                /night
              </span>
            )}
          </span>
          {!!reviewStart && (
            <StartRating reviewCount={reviewCount} point={reviewStart} />
          )}
          {owner && (
            <div className="flex justify-between items-center">
              <ButtonPrimary type="button" onClick={handleDeleteApartment}>
                {loading ? <ButtonLoader /> : "Delete"}
              </ButtonPrimary>
              <ButtonSecondary
                className="ml-2"
                type="button"
                onClick={handleEditApartment}
              >
                {loading ? <ButtonLoader /> : "Edit"}
              </ButtonSecondary>
            </div>
          )}
        </div>
      </div>
    );
  };

  return (
    <div
      className={`nc-StayCard group relative bg-white dark:bg-neutral-900 border border-neutral-100 dark:border-neutral-800 rounded-2xl overflow-hidden hover:shadow-xl transition-shadow ${className}`}
      data-nc-id="StayCard"
    >
      {renderSliderGallery()}
      {renderContent()}
    </div>
  );
};

export default ApartmentCard;
