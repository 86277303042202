import { FC, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Helmet } from "react-helmet";
import Input from "shared/Input/Input";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { Link, useHistory } from "react-router-dom";
import { getStates, registerUserAction } from "redux/actions/authActions";
import { authConstants } from "redux/actionTypes";
import { ButtonLoader } from "components/ButtonLoader.jsx";
import { RootState } from "redux/reducers";
import Select from "shared/Select/Select";
import cogoToast from "cogo-toast";

export interface PageSignUpProps {
  className?: string;
}

// interface EventValue {
//    [id?: string]
//  }

const PageSignUp: FC<PageSignUpProps> = ({ className = "" }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [ngStates, setNgStates] = useState([]);
  const [state, setState] = useState({
    first_name: "",
    last_name: "",
    nin: "",
    phone: "",
    email: "",
    password: "",
    location: "",
  });

  const { loading } = useSelector((store: RootState) => store.auth);

  useEffect(() => {
    // console.log("loading", loading);
    Promise.resolve(getStates()).then((naijaStates: any) => {
      setNgStates(naijaStates);
    });
  }, []);

  const handleSubmit = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    dispatch({ type: authConstants.AUTH_LOADING });

    const registerUser = async () => {
      const registered = await registerUserAction(state, dispatch);
      const {
        payload: { data, status },
      } = registered;

      if (status === 200) {
        cogoToast.success(data.message, {
          position: "top-right",
        });
        history.push("/register-confirmation", { source: "registration" });
      } else {
        cogoToast.error(data.message, {
          position: "top-right",
        });
        dispatch({ type: authConstants.STOP_AUTH_LOADING });
      }
    };

    registerUser();
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue: any = {};
    newValue[e.target.name] = e.target.value;
    setState({ ...state, ...newValue });
  };

  const selectChange = (event: React.FormEvent<HTMLSelectElement>) => {
    const newValue: any = {};
    newValue[event.currentTarget.name] = event.currentTarget.value;
    setState({ ...state, ...newValue });
  };

  return (
    <div className={`nc-PageSignUp  ${className}`} data-nc-id="PageSignUp">
      <Helmet>
        <title>Sign up | Holiday Short Stays</title>
      </Helmet>
      <div className="container mb-24 lg:mb-32">
        <h2 className="my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          Become a Host on
          <br /> Holiday Short Stays
        </h2>
        <div className="max-w-5xl mx-auto space-y-6 ">
          {/* FORM */}
          <form className="" method="post" onSubmit={handleSubmit}>
            <div className="grid md:grid-cols-2 sm:grid-cols gap-6">
              <label className="block">
                <span className="text-neutral-800 dark:text-neutral-200">
                  First Name
                </span>
                <Input
                  id="first_name"
                  type="text"
                  placeholder=""
                  className="mt-1"
                  name="first_name"
                  onChange={handleChange}
                  required
                />
              </label>
              <label className="block">
                <span className="text-neutral-800 dark:text-neutral-200">
                  Last Name
                </span>
                <Input
                  id="last_name"
                  type="text"
                  placeholder=""
                  className="mt-1"
                  name="last_name"
                  onChange={handleChange}
                  required
                />
              </label>

              <label className="block">
                <span className="text-neutral-800 dark:text-neutral-200">
                  Phone Number
                </span>
                <Input
                  id="phone"
                  type="tel"
                  placeholder=""
                  className="mt-1"
                  name="phone"
                  onChange={handleChange}
                  required
                />
              </label>
              <div className="grid md:grid-cols-2 sm:grid-cols gap-6">
                <label className="block">
                  <span className="text-neutral-800 dark:text-neutral-200">
                    NIN Number
                  </span>
                  <Input
                    id="nin"
                    type="number"
                    placeholder=""
                    className="mt-1"
                    name="nin"
                    onChange={handleChange}
                    required
                  />
                </label>
                <label className="block">
                  <span className="text-neutral-800 dark:text-neutral-200">
                    State
                  </span>
                  <Select name="location" onChange={selectChange}>
                    {ngStates && (
                      <>
                        {" "}
                        {ngStates.map((mystate, index) => (
                          <option value={mystate} key={index}>
                            {mystate}
                          </option>
                        ))}{" "}
                      </>
                    )}
                  </Select>
                </label>
              </div>
              <label className="block">
                <span className="text-neutral-800 dark:text-neutral-200">
                  Email address
                </span>
                <Input
                  id="email"
                  type="email"
                  name="email"
                  placeholder="example@example.com"
                  onChange={handleChange}
                  className="mt-1"
                />
              </label>
              <label className="block">
                <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                  Password
                </span>
                <Input
                  id="password"
                  type="password"
                  name="password"
                  className="mt-1"
                  onChange={handleChange}
                />
              </label>
            </div>
            <div className="grid grid-cols-1 mt-6">
              <ButtonPrimary type="submit">
                {loading ? <ButtonLoader /> : "Register"}
              </ButtonPrimary>
            </div>
          </form>

          {/* ==== */}
          <span className="block text-center text-neutral-700 dark:text-neutral-300">
            Already have an account? {` `}
            <Link to="/login">Sign in</Link>
          </span>
        </div>
      </div>
    </div>
  );
};

export default PageSignUp;
