import { shortletsConstants } from "../actionTypes";

const INITIAL_STATE = {
  loading: false,
  shortlets: [],
  count: 0,
  pagination: {},
  currentPage: 0,
};

const shortletsReducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case shortletsConstants.LOADING:
      return {
        ...state,
        loading: true,
      };
    case shortletsConstants.STOP_LOADING:
      return {
        ...state,
        loading: false,
      };
    case shortletsConstants.SET_CURRENT_PAGE:
      return {
        ...state,
        currentPage: parseInt(payload),
      };
    case shortletsConstants.GET_SHORTLETS:
      return {
        ...state,
        loading: false,
        shortlets: payload.apartments,
        count: payload.count,
        pagination: payload.pagination,
      };
    case shortletsConstants.GET_MORE_SHORTLETS:
      return {
        ...state,
        loading: false,
        shortlets: [...state.shortlets, ...payload.apartments],
        count: payload.count,
        pagination: payload.pagination,
      };
    default:
      return state;
  }
};

export default shortletsReducer;
