import httpRequest from "../../api";
import { boatConstants } from "../actionTypes";

// ========================================================================

export const publishBoatAction = (
  details,
  user_id,
  featured_img,
  other_imgs,
  dispatch
) => {
  dispatch({ type: boatConstants.LOADING });
  console.log("Post Data", details);

  const postData = {
    title: details.title,
    guest_capacity: details.guest_capacity,
    cabins: details.cabins,
    beds: details.beds,
    bathrooms: details.bathrooms,
    description: details.description,
    rules: details.rules,
    price: details.price,
    featured_img: featured_img,
    other_imgs: other_imgs,
  };

  return httpRequest("post", `boat`, postData)
    .then(({ data }) => {
      let action = {};
      console.log("Response Data", data);
      if (data.status === 200) {
        action = {
          type: boatConstants.POST_BOAT,
          payload: data.message,
        };
        dispatch(action);
      } else {
        const payload = "Could not load post boat";
        action = { type: boatConstants.ERROR, payload };
        dispatch(action);
      }

      return action;
    })
    .catch(({ response }) => {
      console.log("response", response);

      const message = response?.data?.data?.error;
      console.log("MSG", message);
      const action = { payload: message, type: "ERROR" };

      dispatch(action);

      return action;
    });
};

// ====================================================================

export const fetchMyBoats = (host_id, dispatch) => {
  dispatch({ type: boatConstants.FETCHING });

  return httpRequest("get", `boat/user/${host_id}`)
    .then(({ data }) => {
      let action = {};
      if (data.status === 200) {
        if (data.data.boats.length > 0) {
          action = {
            type: boatConstants.GET_MY_BOATS,
            payload: data.data.boats,
          };
        } else {
          action = {
            type: boatConstants.NO_BOAT,
            payload: data.data.message,
          };
        }
        dispatch({ type: boatConstants.STOP_FETCHING });
        dispatch(action);
      } else {
        const payload = "Could not load boat";
        action = { type: boatConstants.ERROR, payload };

        dispatch({ type: boatConstants.STOP_LOADING });
        dispatch(action);
      }

      return action;
    })
    .catch(({ response }) => {
      dispatch({ type: boatConstants.STOP_LOADING });

      const message = response?.data; //.data?.message;
      return {
        payload: message,
        type: "ERROR",
      };
    });
};

// ====================================================================

export const fetchBoatDetails = (boat_id, dispatch) => {
  dispatch({ type: boatConstants.FETCHING });

  console.log("{{{{{{{ FETCHING BOAT DETAILS }}}}}}}}");

  return httpRequest("get", `boat/${boat_id}`)
    .then(({ data }) => {
      console.log("fetched", data.data.boat);
      let action = {};
      if (data.status === 200) {
        action = {
          type: boatConstants.GET_BOAT_DETAILS,
          payload: data.data.boat,
        };
        dispatch(action);
        dispatch({ type: boatConstants.STOP_FETCHING });
      } else {
        const payload = "Could not load boat";
        action = { type: boatConstants.ERROR, payload };

        dispatch({ type: boatConstants.STOP_FETCHING });
        dispatch(action);
      }

      return action;
    })
    .catch(({ response }) => {
      dispatch({ type: boatConstants.STOP_FETCHING });

      const message = response?.data; //.data?.message;
      return {
        payload: message,
        type: "ERROR",
      };
    });
};

// ====================================================================

export const fetchAllBoats = (filter = {}, page, loadMore, dispatch) => {
  if (page === 1) dispatch({ type: boatConstants.FETCHING });
  const limit = 12;

  // dispatch({ type: boatConstants.FETCHING });

  console.log("{{{{{{{ FETCHING ALL BOATS }}}}}}}}");

  return httpRequest("get", `boat?page=${page}&limit=${limit}`)
    .then(({ data }) => {
      console.log("fetched all boats", data);
      let action = {};
      if (data.status === 200) {
        dispatch({ type: boatConstants.STOP_FETCHING });

        if (data.data.boats.length > 0) {
          if (!loadMore) {
            action = {
              type: boatConstants.GET_BOATS,
              payload: data.data,
            };
          } else {
            action = {
              type: boatConstants.GET_MORE_BOATS,
              payload: data.data,
            };
          }
        } else {
          action = {
            type: boatConstants.NO_BOAT,
            payload: data.data.message,
          };
        }
        dispatch(action);
      } else {
        const payload = "Could not load boats";
        action = { type: boatConstants.ERROR, payload };

        dispatch({ type: boatConstants.STOP_FETCHING });
        dispatch(action);
      }

      return action;
    })
    .catch(({ response }) => {
      dispatch({ type: boatConstants.STOP_FETCHING });

      const message = response?.data; //.data?.message;
      return {
        payload: message,
        type: "ERROR",
      };
    });
};

// ========================================================================

export const addFeaturedImage = async (image, dispatch) => {
  dispatch({ type: boatConstants.LOADING_FEATURED });

  const data = new FormData();
  data.append("file", image);
  data.append("upload_preset", "boatpreset");
  data.append("cloud_name", "holiday-short-stays");
  return fetch(
    "https://api.cloudinary.com/v1_1/holiday-short-stays/image/upload",
    {
      method: "POST",
      body: data,
    }
  )
    .then((response) => response.json())
    .then((data) => {
      console.log("data", data);
      return data.url;
    })
    .catch((err) => console.log("error", err));
};

// ==================================

export const addOtherImages = async (images, dispatch) => {
  let allImages = [];
  for (let i = 0; i < images.length; i++) {
    dispatch({ type: "LOADING_OTHER_IMAGES" });

    const data = new FormData();
    data.append("file", images[i]);
    data.append("upload_preset", "boatpreset");
    data.append("cloud_name", "holiday-short-stays");

    let img = await fetch(
      "https://api.cloudinary.com/v1_1/holiday-short-stays/image/upload",
      {
        method: "POST",
        body: data,
      }
    )
      .then((response) => response.json())
      .then((data) => {
        return data.url;
      })
      .catch((err) => console.log("error", err));

    allImages.push(img);
  }
  return allImages;
};

// ====================================================================

export const deleteBoat = (boat_id, slug, dispatch) => {
  dispatch({ type: boatConstants.FETCHING });

  console.log("{{{{{{{ DELETING A BOAT }}}}}}}}");

  return httpRequest("delete", `boat/${boat_id}/${slug}`)
    .then(({ data }) => {
      console.log("fetched", data.data.message);
      let action = {};
      if (data.status === 200) {
        action = {
          type: boatConstants.DELETE_BOAT,
          payload: boat_id,
        };
        dispatch(action);
      } else {
        const payload = "Could not load boat";
        action = { type: boatConstants.ERROR, payload };

        // dispatch(action);
      }

      // dispatch({ type: boatConstants.GET_MY_BOATS });
      dispatch({ type: boatConstants.STOP_FETCHING });

      return { status: true, msg: data.data.message };
    })
    .catch(({ response }) => {
      dispatch({ type: boatConstants.STOP_FETCHING });

      const message = response?.data; //.data?.message;
      return {
        payload: message,
        type: "ERROR",
      };
    });
};

// ========================================================================

export const updateBoatAction = (
  details,
  boatid,
  featured_img,
  other_imgs,
  dispatch
) => {
  const postData = {
    title: details.title,
    guest_capacity: details.guest_capacity,
    cabins: details.cabins,
    beds: details.beds,
    bathrooms: details.bathrooms,
    description: details.description,
    rules: details.rules,
    price: details.price,
    featured_img: featured_img,
    other_imgs: other_imgs,
  };

  return httpRequest("patch", `boat/${boatid}`, postData)
    .then(({ data }) => {
      let action = {};
      console.log("Response Data", data);
      if (data.status === 200) {
        action = {
          type: "SUCCESS",
          payload: data.data.message,
        };
      } else {
        const payload = "Could not load post car";
        action = { type: "ERROR", payload };
      }

      return action;
    })
    .finally(() => {
      dispatch({ type: boatConstants.STOP_LOADING });
    })
    .catch(({ response }) => {
      console.log("response", response);

      const message = response?.data?.data?.error;
      console.log("MSG", message);
      const action = { payload: message, type: "ERROR" };

      dispatch(action);

      return action;
    });
};
