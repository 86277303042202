import StartRating from "components/StartRating/StartRating";
import { OwnerType } from "data/types";
import { FC } from "react";
import { Link } from "react-router-dom";
import { formatMoney } from "utils/helpers";
import toTitleCase from "utils/strFunctions";
import SocialRow from "./SocialRow";

export interface OwnersCardProps {
  className?: string;
  ownerDetails: OwnerType;
  price: number;
  unit: string;
  whatsAppText?: string;
  ownerscount?: number;
  property?: string;
}

const OwnersCard: FC<OwnersCardProps> = ({
  className = "",
  ownerDetails,
  price,
  unit,
  whatsAppText = "",
  ownerscount = 0,
  property = "shortlets",
}) => {
  const {
    _id,
    first_name,
    last_name,
    company_name,
    phone,
    phone1,
    email,
    // created_at,
    // bio,
    // gender,
    social,
    website,
  } = ownerDetails;

  console.log("OWNER DETAILS", ownerDetails);

  return (
    <div className={`nc-OwnersCard ${className}`} data-nc-id="OwnersCard">
      <div className="listingSection__wrap shadow-xl">
        {/* PRICE */}
        <div className="flex justify-between">
          <span className="text-3xl font-semibold">
            {price ? formatMoney(price) : 0}
            <span className="ml-1 text-base font-normal text-neutral-500 dark:text-neutral-400">
              /{unit}
            </span>
          </span>
          <StartRating />
        </div>

        {/* SUM */}
        <div className="flex flex-col space-y-4">
          <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
            <span className="font-semibold border-b-2 border-blue-100">
              Posted By:
            </span>
          </div>
          <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
          <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
            <span className="font-medium">Full Name:</span>
            <span>{`${first_name} ${last_name}`}</span>
          </div>
          <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
          <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
            <span className="font-medium">Company:</span>
            <span>{company_name ? company_name : "Nill"}</span>
          </div>
          <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
          <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
            <span className="font-medium">Email:</span>
            <span>{email}</span>
          </div>
          <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
          <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
            <span className="font-medium">Phone:</span>
            <span>{phone}</span>
          </div>
          <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
          {phone1 && (
            <>
              <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
                <span className="font-medium">Whatsapp:</span>
                <span>
                  <a
                    href={`https://wa.me/${phone1}?text=${whatsAppText}`}
                    target="_blank"
                    style={{ textDecoration: "underline" }}
                  >
                    +{phone1}
                  </a>
                </span>
              </div>

              <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
            </>
          )}
          <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
            <span
              className="font-medium"
              style={{ textDecoration: "underline" }}
            >
              <Link to={`/host/${property}/${_id}`}>
                Total {toTitleCase(property)}s by Host
              </Link>
            </span>
            <span>{ownerscount}</span>
          </div>
          <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
          {website && (
            <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
              <span className="font-medium">Website:</span>
              <span>{website}</span>
            </div>
          )}

          <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
            <span className="font-medium">Social Media:</span>
            <span>
              <SocialRow socials={social} />
            </span>
          </div>
        </div>

        {/* SUBMIT */}
        {/* <ButtonPrimary onClick={reserveBooking}>Reserve</ButtonPrimary> */}
      </div>
    </div>
  );
};

export default OwnersCard;
