import httpRequest from "../../api";
import { shortletsConstants } from "../actionTypes";

// ==================================

export const getShortlets = (filter = {}, page, loadMore, dispatch) => {
  if (page === 1) dispatch({ type: shortletsConstants.LOADING });
  const limit = 12;

  return httpRequest("get", `apartment?page=${page}&limit=${limit}`)
    .then(({ data }) => {
      console.log("Shortlets data", data.data);
      if (data.status === 200) {
        dispatch({ type: shortletsConstants.STOP_LOADING });
        dispatch({ type: shortletsConstants.SET_CURRENT_PAGE, payload: page });

        let action = {};

        if (!loadMore) {
          action = {
            type: shortletsConstants.GET_SHORTLETS,
            payload: data.data,
          };
        } else {
          action = {
            type: shortletsConstants.GET_MORE_SHORTLETS,
            payload: data.data,
          };
        }

        dispatch(action);
      } else {
        dispatch({ type: shortletsConstants.STOP_LOADING });

        const payload = "Could not load amenities";
        const action = { type: shortletsConstants.ERROR, payload };
        dispatch(action);
      }

      // return action;
    })
    .catch(({ response }) => {
      const message = response?.data; //.data?.message;
      return {
        payload: message,
        type: "ERROR",
      };
    });
};
