import axios from "axios";
import { setToken } from "./../utils/helpers";

const { NODE_ENV, REACT_APP_API_URL, REACT_APP_API_URL_TEST } = process.env;

const API_URL =
  NODE_ENV === "production" ? REACT_APP_API_URL : REACT_APP_API_URL_TEST;

export const axiosSetup = {
  /**
   * @description axios Setup
   * @param {string} method - http method
   * @param {string} path - url path
   * @param {any} data - All data types
   * @param {class} ErrorClass - ErrorClass
   * @returns {object} - axios instance
   */
  fetch: (method, path, data = {}, ErrorClass = Error) => {
    try {
      /**
       * If internet connection exists make the request
       * otherwise throw a custom error as shown in the
       * catch block
       */
      if (window.navigator.onLine) {
        setToken();
        method = `${method}`.toLowerCase();
        return axios[method](`${API_URL}${path}`, data);
      }
      throw ErrorClass();
    } catch (error) {
      const defaultError = {
        response: {
          data: {
            message: "Please check your internet connection",
          },
        },
      };
      return Promise.reject(defaultError);
    }
  },
};

export default axiosSetup.fetch;
