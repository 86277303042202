import NcInputNumber from "components/NcInputNumber/NcInputNumber";
import { FC } from "react";
import { useSelector, useDispatch } from "react-redux";
import { saveListing } from "redux/actions/listingActions";
import { RootState } from "redux/reducers";
// import Select from "shared/Select/Select";
import CommonLayout from "./CommonLayout";

export interface PageAddListing2Props {}

const PageAddListing2: FC<PageAddListing2Props> = () => {
  const dispatch = useDispatch();
  const listing = useSelector((store: RootState) => store.listing);

  const getRadioValue = (name: string, val: number) => {
    const newValue = { [name]: val };
    console.log(newValue);
    saveListing(newValue, dispatch);
  };

  return (
    <CommonLayout index="02" backtHref="/add-listing" nextHref="/add-listing-3">
      <>
        <h2 className="text-2xl font-semibold">Apartment Specification</h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        {/* FORM */}
        <div className="space-y-8">
          {/* ITEM */}
          <NcInputNumber
            label="No. of Guest Allowed"
            defaultValue={listing.no_guests ?? 3}
            onChange={(v) => getRadioValue("no_guests", v)}
          />
          <NcInputNumber
            label="No. of Bedrooms"
            defaultValue={listing.no_bedrooms ?? 2}
            min={1}
            onChange={(v) => getRadioValue("no_bedrooms", v)}
          />
          <NcInputNumber
            label="No of Bathrooms/Toilet"
            defaultValue={listing.no_bathrooms ?? 1}
            onChange={(v) => getRadioValue("no_bathrooms", v)}
          />
        </div>
      </>
    </CommonLayout>
  );
};

export default PageAddListing2;
