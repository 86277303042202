import React, { FC, useEffect } from "react";
import Logo from "shared/Logo/Logo";
import Navigation from "shared/Navigation/Navigation";
// import SearchDropdown from "./SearchDropdown";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import MenuBar from "shared/MenuBar/MenuBar";
import SwitchDarkMode from "shared/SwitchDarkMode/SwitchDarkMode";
import hssLogo from "images/HSS-logo.png";
import hssLogoLight from "images/HSS-Logo-white.png";
import NavigationItem, { NavItemType } from "shared/Navigation/NavigationItem";
import ncNanoId from "utils/ncNanoId";
import { RootState } from "redux/reducers";
import { useSelector } from "react-redux";
import { logoutAction } from "redux/actions/authActions";
import ButtonSecondary from "shared/Button/ButtonSecondary";

export interface MainNav1Props {
  isTop: boolean;
}

// const newPostingChildren: NavItemType[] = [
//   {
//     id: ncNanoId(),
//     href: "/login",
//     name: "Login",
//   },
// ];

const hostChildren: NavItemType[] = [
  {
    id: ncNanoId(),
    href: "/profile",
    name: "My Profile",
  },
];

const listChildren: NavItemType[] = [
  {
    id: ncNanoId(),
    href: "/add-listing",
    name: "List A Shortlet Apartment",
  },
  // {
  //   id: ncNanoId(),
  //   href: "/list-hotel",
  //   name: "List A Hotel",
  // },
  {
    id: ncNanoId(),
    href: "/list-car",
    name: "List A Car for Rental",
  },
  {
    id: ncNanoId(),
    href: "/list-boat",
    name: "List A Boat",
  },
];

export const HOST_DEMO: NavItemType[] = [
  {
    id: ncNanoId(),
    href: "#",
    name: "Account",
    type: "dropdown",
    children: hostChildren,
  },
  {
    id: ncNanoId(),
    href: "#",
    name: "Post Property",
    type: "dropdown",
    children: listChildren,
  },
];

const MainNav1: FC<MainNav1Props> = ({ isTop }) => {
  const { isAuthenticated } = useSelector((store: RootState) => store.auth);

  useEffect(() => {
    if (isAuthenticated) {
      hostChildren.unshift({
        id: ncNanoId(),
        href: "/dashboard",
        name: "Dashboard",
      });
    }
  }, [isAuthenticated]);

  return (
    <div
      className={`nc-MainNav1 relative z-10 ${
        isTop ? "onTop " : "notOnTop backdrop-filter"
      }`}
    >
      <div className="container py-5 relative flex justify-between items-center space-x-4 xl:space-x-8">
        <div className="flex justify-start flex-grow items-center space-x-4 sm:space-x-10 2xl:space-x-14">
          <Logo img={hssLogo} imgLight={hssLogoLight} />
          <Navigation />
        </div>
        <div className="flex-shrink-0 flex items-center justify-end text-neutral-700 dark:text-neutral-100 space-x-1">
          <div className="hidden items-center xl:flex space-x-1">
            <SwitchDarkMode />
            {/* <SearchDropdown /> */}
            {isAuthenticated ? (
              <ul className="nc-Navigation hidden lg:flex lg:flex-wrap lg:items-center lg:space-x-1 relative">
                {HOST_DEMO.map((item) => (
                  <NavigationItem key={item.id} menuItem={item} />
                ))}
              </ul>
            ) : null}
            <div className="px-1" />
            {isAuthenticated ? (
              <ButtonSecondary href="#" onClick={logoutAction}>
                Logout
              </ButtonSecondary>
            ) : (
              <ButtonPrimary href="/signup">Join Us</ButtonPrimary>
            )}
            {/* <Link to="#" onClick={logoutAction}>
               Logout
             </Link> */}
          </div>
          <div className="flex items-center xl:hidden">
            <SwitchDarkMode />
            <div className="px-1" />
            <MenuBar />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainNav1;
