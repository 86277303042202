import React, { FC, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addAdditionalRule,
  removeAdditionalRule,
  updateRule,
} from "redux/actions/listingActions";
import { RootState } from "redux/reducers";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Input from "shared/Input/Input";
import CommonLayout from "./CommonLayout";

export interface PageAddListing4Props {}

const PageAddListing4: FC<PageAddListing4Props> = () => {
  const dispatch = useDispatch();
  const listing = useSelector((store: RootState) => store.listing);

  console.log("listing", listing.house_rules);

  const [rule, setRule] = useState("");

  const handleAddRule = () => {
    if (!rule) {
      alert("Kindly enter a rule");
      return;
    }

    addAdditionalRule(rule, dispatch);
    setRule("");
  };

  const removeRule = (event: React.MouseEvent<HTMLElement>, id: number) => {
    console.log("event type:", event);
    console.log("id", id);
    removeAdditionalRule(id, dispatch);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRule(e.target.value);
  };

  const changeRule = (
    itemName: string,
    status: boolean,
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const item = { [itemName]: status };
    updateRule(item, dispatch);
  };

  // const isSelected = ()

  const renderRadio = (
    name: string,
    id: string,
    label: string,
    defaultChecked?: boolean
  ) => {
    return (
      <>
        <div className="flex items-center">
          <input
            id={id + name}
            name={name}
            type="radio"
            checked={defaultChecked ?? false}
            className="focus:ring-primary-500 h-6 w-6 text-primary-500 border-neutral-300 !checked:bg-primary-500 bg-transparent"
            onChange={(e) => changeRule(name, true, e)}
          />
          <label
            htmlFor={id + name}
            className="ml-3 block text-sm font-medium text-neutral-700 dark:text-neutral-300"
          >
            Allowed
          </label>
        </div>
        <div className="flex items-center">
          <input
            id={id + name}
            name={name}
            type="radio"
            checked={!defaultChecked ?? true}
            className="focus:ring-primary-500 h-6 w-6 text-primary-500 border-neutral-300 !checked:bg-primary-500 bg-transparent"
            onChange={(e) => changeRule(name, false, e)}
          />
          <label
            htmlFor={id + name}
            className="ml-3 block text-sm font-medium text-neutral-700 dark:text-neutral-300"
          >
            Not Allowed
          </label>
        </div>
      </>
    );
  };

  const renderNoInclude = (index: number, text: string) => {
    return (
      <div key={index} className="flex items-center justify-between py-3">
        <span className="text-neutral-6000 dark:text-neutral-400 font-medium">
          {text}
        </span>
        <button data-name={index} onClick={(e) => removeRule(e, index)}>
          <i className="text-2xl text-neutral-400 las la-times-circle hover:text-neutral-900 dark:hover:text-neutral-100 cursor-pointer"></i>
        </button>
      </div>
    );
  };

  return (
    <CommonLayout
      index="04"
      backtHref="/add-listing-3"
      nextHref="/add-listing-5"
    >
      <>
        <div>
          <h2 className="text-2xl font-semibold">
            Set house rules for your guests{" "}
          </h2>
          <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
            Guests must agree to your house rules before they book.
          </span>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        {/* FORM */}
        <div className="space-y-8">
          {/* ITEM */}
          <div>
            <label className="text-lg font-semibold" htmlFor="">
              Smoking
            </label>
            <div className="mt-4 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-5">
              {renderRadio(
                "smoking",
                "smoking",
                "Allowed",
                listing.house_rules.smoking
              )}
            </div>
          </div>

          {/* ITEM */}
          <div>
            <label className="text-lg font-semibold" htmlFor="">
              Pets
            </label>
            <div className="mt-4 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-5">
              {renderRadio(
                "pets",
                "pets",
                "Not Allowed",
                listing.house_rules.pets
              )}
            </div>
          </div>

          {/* ITEM */}
          <div>
            <label className="text-lg font-semibold" htmlFor="">
              Party in apartment
            </label>
            <div className="mt-4 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-5">
              {renderRadio(
                "party_allowed",
                "party_allowed",
                "Not Allowed",
                listing.house_rules.party_allowed
              )}
            </div>
          </div>

          {/* ITEM */}
          <div>
            <label className="text-lg font-semibold" htmlFor="">
              Cooking
            </label>
            <div className="mt-4 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-5">
              {renderRadio(
                "cooking",
                "cooking",
                "Not Allowed",
                listing.house_rules.cooking
              )}
            </div>
          </div>

          {/* ----------- */}
          <div className=" border-b border-neutral-200 dark:border-neutral-700"></div>
          <span className="block text-lg font-semibold">
            Additional rules (max: 5)
          </span>
          <div className="flow-root">
            <div className="-my-3 divide-y divide-neutral-100 dark:divide-neutral-800">
              {listing.additional_rules &&
                listing.additional_rules.map((rule: string, index: number) => {
                  return renderNoInclude(index, rule);
                })}
            </div>
          </div>
          <div className="flex flex-col sm:flex-row sm:justify-between space-y-3 sm:space-y-0 sm:space-x-5">
            <Input
              className="!h-full"
              placeholder="No smoking..."
              name="rule"
              value={rule}
              onChange={handleChange}
            />
            <ButtonPrimary
              className="flex-shrink-0"
              onClick={handleAddRule}
              disabled={
                listing && listing.additional_rules
                  ? listing.additional_rules.length >= 5
                  : false
              }
            >
              <i className="text-xl las la-plus"></i>
              <span className="ml-3">Add rule</span>
            </ButtonPrimary>
          </div>
        </div>
      </>
    </CommonLayout>
  );
};

export default PageAddListing4;
