import { PencilAltIcon } from "@heroicons/react/outline";
import React, { FC, useState } from "react";
import Input from "shared/Input/Input";
import Label from "components/Label/Label";
import Textarea from "shared/Textarea/Textarea";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import NcImage from "shared/NcImage/NcImage";
import StartRating from "components/StartRating/StartRating";
import NcModal from "shared/NcModal/NcModal";
import { RootState } from "redux/reducers";
import { useDispatch, useSelector } from "react-redux";
import { formatMoney, showError, showSuccess } from "utils/helpers";
import moment from "moment";
import { createBooking } from "redux/actions/bookingActions";
import { ButtonLoader } from "components/ButtonLoader.jsx";
import { useHistory } from "react-router-dom";

export interface CheckOutPageProps {
  className?: string;
}

const CheckOutPage: FC<CheckOutPageProps> = ({ className = "" }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [state, setState] = useState({
    card_fullname: "",
    card_email: "",
    card_phone: "",
    card_location: "",
    card_message: "",
    fullname: "",
    email: "",
    phone: "",
    location: "",
    message: "",
  });
  const booking = useSelector((state: RootState) => state.booking);
  const { apartment }: any = useSelector((state: RootState) => state.list);
  console.log("booking", booking, "list", apartment);

  const totalGuests = (guestObj: any) => {
    const { guestAdults, guestChildren, guestInfants } = guestObj;
    return guestAdults + guestChildren + guestInfants;
  };

  const handleChange = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    const newValue: any = {};
    newValue[e.target.name] = e.target.value;
    setState({ ...state, ...newValue });
  };

  if (booking.success && booking.error === "") {
    console.log("booking success");
    showSuccess("Booking Processed Successfully");
    // history.push("/booking-success", { data: state });
    history.push({
      pathname: "/booking-success",
      state: state,
    });
  } else if (!booking.success && booking.error) {
    console.log("booking error");
    showError("Unable to complete booking process");
  }

  const processBooking = () => {
    let checkoutPayload = {
      apartment_id: booking.apartmentId,
      apartment_slug: booking.apartmentSlug,
      apartmentTitle: apartment.title,
      fullname: "",
      email: "",
      phone: "",
      location: "",
      message: "",
      guests: {
        guestAdults: booking.guests.guestAdults,
        guestChildren: booking.guests.guestChildren,
        guestInfants: booking.guests.guestInfants,
      },
      price: booking.price,
      total: booking.total,
      grandTotal: booking.grandTotal,
      serviceCharge: booking.serviceCharge,
      checkInCheckOut: {
        startDate: moment(booking.checkIn).toDate(),
        endDate: moment(booking.checkOut).toDate(),
      },
      bookingDate: moment().toDate(),
      owner: apartment.host._id,
    };

    console.log("state", state);

    checkoutPayload.fullname = state.fullname;
    checkoutPayload.email = state.email;
    checkoutPayload.phone = state.phone;
    checkoutPayload.location = state.location;
    checkoutPayload.message = state.message;

    if (
      !checkoutPayload.fullname ||
      !checkoutPayload.email ||
      !checkoutPayload.phone
    ) {
      showError("Kindly fill fields before submitting");
    } else {
      createBooking(checkoutPayload, dispatch);
    }
  };

  const renderSidebar = () => {
    return (
      <div className="w-full flex flex-col sm:rounded-2xl sm:border border-neutral-200 dark:border-neutral-700 space-y-6 sm:space-y-8 px-0 sm:p-6 xl:p-8">
        <div className="flex flex-col sm:flex-row sm:items-center">
          <div className="flex-shrink-0 w-full sm:w-40">
            <div className=" aspect-w-4 aspect-h-3 sm:aspect-h-4 rounded-2xl overflow-hidden">
              <NcImage src={apartment.gallery_imgs[0]} />
            </div>
          </div>
          <div className="py-5 sm:px-5 space-y-3">
            <div>
              <span className="text-sm text-neutral-500 dark:text-neutral-400 line-clamp-1">
                {apartment.apartment_type}
              </span>
              <span className="text-base font-medium mt-1 block">
                {apartment.title}
              </span>
            </div>
            <span className="block  text-sm text-neutral-500 dark:text-neutral-400">
              {apartment.bedrooms} beds · {apartment.bathrooms} baths
            </span>
            <div className="w-10 border-b border-neutral-200  dark:border-neutral-700"></div>
            <StartRating />
          </div>
        </div>
        <div className="flex flex-col space-y-4">
          <h3 className="text-2xl font-semibold">Price detail</h3>
          <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
            <span>
              {formatMoney(booking.price)} x {booking.noDays} days
            </span>
            <span>{formatMoney(booking.total)}</span>
          </div>
          <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
            <span>Service charge</span>
            <span>{formatMoney(booking.serviceCharge)}</span>
          </div>

          <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
          <div className="flex justify-between font-semibold">
            <span>Total</span>
            <span>{formatMoney(booking.grandTotal)}</span>
          </div>
        </div>
      </div>
    );
  };

  const renderMain = () => {
    return (
      <div className="w-full flex flex-col sm:rounded-2xl sm:border border-neutral-200 dark:border-neutral-700 space-y-8 px-0 sm:p-6 xl:p-8">
        <h2 className="text-3xl lg:text-4xl font-semibold">
          Submit Reservation
        </h2>
        <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
        <div>
          <div>
            <NcModal
              renderTrigger={(openModal) => (
                <span
                  onClick={() => openModal()}
                  className="block lg:hidden underline  mt-1 cursor-pointer"
                >
                  View booking details
                </span>
              )}
              renderContent={renderSidebar}
            />
          </div>
          <div className="mt-6 border border-neutral-200 dark:border-neutral-700 rounded-3xl flex flex-col sm:flex-row divide-y sm:divide-x sm:divide-y-0 divide-neutral-200 dark:divide-neutral-700">
            <div className="flex-1 p-5 flex justify-between space-x-5">
              <div className="flex flex-col">
                <span className="text-sm text-neutral-400">Date</span>
                <span className="mt-1.5 text-lg font-semibold">
                  {moment(booking.checkIn).format("MMM Do")} -{" "}
                  {moment(booking.checkOut).format("MMM Do YYYY")}
                </span>
              </div>
              <PencilAltIcon className="w-6 h-6 text-neutral-300 dark:text-neutral-6000" />
            </div>
            <div className="flex-1 p-5 flex justify-between space-x-5">
              <div className="flex flex-col">
                <span className="text-sm text-neutral-400">Guests</span>
                <span className="mt-1.5 text-lg font-semibold">
                  {totalGuests(booking.guests)} Guests
                </span>
              </div>
              <PencilAltIcon className="w-6 h-6 text-neutral-300 dark:text-neutral-6000" />
            </div>
          </div>
        </div>

        <div>
          <div className="mt-4">
            <h3 className="text-2xl font-semibold mb-3">
              Submit your request to shortlet owner
            </h3>
            <div className="space-y-1 mb-4">
              <Label>Your Full Name </Label>
              <Input
                type="email"
                name="fullname"
                placeholder=""
                onChange={handleChange}
              />
            </div>
            <div className="space-y-1 mb-4">
              <Label>Your Phone Number </Label>
              <Input
                type="email"
                name="phone"
                placeholder=""
                onChange={handleChange}
              />
            </div>
            <div className="space-y-1 mb-4">
              <Label>Your Email Address </Label>
              <Input
                type="email"
                name="email"
                placeholder="example@gmail.com"
                onChange={handleChange}
              />
            </div>
            <div className="space-y-1 mb-4">
              <Label>Your Location</Label>
              <Input
                type="text"
                name="location"
                placeholder=""
                onChange={handleChange}
              />
            </div>
            <div className="space-y-1 mb-4">
              <Label>Message for the host </Label>
              <Textarea
                name="message"
                placeholder="Write a few sentences about your preference."
                onChange={handleChange}
              />
            </div>
            <div className="pt-4">
              <ButtonPrimary
                onClick={processBooking}
                disabled={booking.loading}
              >
                {booking.loading ? <ButtonLoader /> : "Complete Booking"}
              </ButtonPrimary>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className={`nc-CheckOutPage ${className}`} data-nc-id="CheckOutPage">
      <main className="container mt-11 mb-24 lg:mb-32 flex flex-col-reverse lg:flex-row">
        <div className="w-full lg:w-3/5 xl:w-2/3 lg:pr-10 ">{renderMain()}</div>
        <div className="hidden lg:block flex-grow">{renderSidebar()}</div>
      </main>
    </div>
  );
};

export default CheckOutPage;
