import React, { FC } from "react";
import "react-dates/initialize";
// import ExperiencesSearchForm from "./ExperiencesSearchForm";
import StaySearchForm from "./StaySearchForm";
// import RentalCarSearchForm from "./RentalCarSearchForm";

export type SearchTab =
  | "Stay"
  | "Experiences"
  | "Rental car"
  | "Shortlets"
  | "Rental boat";

export interface HeroSearchFormProps {
  className?: string;
  currentTab?: SearchTab;
  currentPage?:
    | "Stay"
    | "Experiences"
    | "Rental car"
    | "Shortlets"
    | "Rental boat";
}

const HeroSearchForm: FC<HeroSearchFormProps> = ({
  className = "",
  currentTab = "Stay",
  currentPage,
}) => {
  // const [tabActive, setTabActive] = useState<SearchTab>(currentTab);

  const renderForm = () => {
    const isArchivePage = !!currentPage && !!currentTab;
    return <StaySearchForm haveDefaultValue={isArchivePage} />;
    // switch (tabActive) {
    //   case "Stay":
    //     return <StaySearchForm haveDefaultValue={isArchivePage} />;
    //   case "Experiences":
    //     return <ExperiencesSearchForm haveDefaultValue={isArchivePage} />;
    //   case "Rental car":
    //     return <RentalCarSearchForm haveDefaultValue={isArchivePage} />;

    //   default:
    //     return null;
    // }
  };

  return (
    <div
      className={`nc-HeroSearchForm w-full max-w-6xl py-5 lg:py-0 ${className}`}
      data-nc-id="HeroSearchForm"
    >
      {renderForm()}
    </div>
  );
};

export default HeroSearchForm;
