import { FC, useEffect, useState } from "react";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import SectionGridFilterCard from "./SectionGridFilterCard";
import { Helmet } from "react-helmet";
import SectionHeroCarPage from "components/SectionHeroArchivePage/SectionHeroCarPage";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "redux/reducers";
import { fetchAllCars } from "redux/actions/carActions";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { ShowLoading } from "components/ShowLoading";

export interface ListingCarPageProps {
  className?: string;
}

export interface CarsListingProps {
  cars: Array<any>;
  fetching: boolean;
  loading: boolean;
  pagination: { nextPage: number };
  count: number;
  currentPage: number;
}

const ListingCarPage: FC<ListingCarPageProps> = ({ className = "" }) => {
  const dispatch = useDispatch();
  const [filter, setFilter] = useState({});
  const [page, setPage] = useState(1);
  const [loadingMore, setLoadingMore] = useState(false);

  const {
    fetching: fetchingCars,
    cars,
    // count,
    pagination,
  }: // currentPage,
  CarsListingProps = useSelector((store: RootState) => store.cars);

  const carsData = cars;

  useEffect(() => {
    console.log("useEffect ... ");
    const fetchData = async () => {
      console.log("Cars Listing Page");
      await fetchAllCars(filter, 1, false, dispatch);
    };

    if (cars.length === 0) fetchData();
  }, []);

  const loadMore = async (page: number) => {
    setLoadingMore(true);
    setPage(page);
    await fetchAllCars(filter, page, true, dispatch);
    setLoadingMore(false);
  };

  return (
    <div
      className={`nc-ListingCarPage relative overflow-hidde mb-10 ${className}`}
      data-nc-id="ListingCarPage"
    >
      <Helmet>
        <title>Holiday Short Stays </title>
      </Helmet>
      <BgGlassmorphism />

      <div className="container relative">
        {/* SECTION HERO */}
        <SectionHeroCarPage
          currentPage="Rental car"
          currentTab="Rental car"
          listingType={
            <>
              <i className="text-2xl las la-car"></i>
              <span className="ml-2.5">1512 cars</span>
            </>
          }
          className="pt-10"
          heroText="We wont let you stress yourself"
          subtitleText="Rent a car of your choice to move around"
        />

        {/* SECTION */}
        {fetchingCars && <ShowLoading />}

        {!fetchingCars && cars.length > 0 && (
          <SectionGridFilterCard className="" data={carsData} />
        )}

        {!fetchingCars && pagination.nextPage && (
          <div className="flex mt-16 justify-center items-center pb-24">
            <ButtonPrimary
              loading={loadingMore}
              onClick={() => loadMore(pagination.nextPage)}
            >
              Load More
            </ButtonPrimary>
          </div>
        )}

        {/* SECTION */}
        {/* <SectionSubscribe2 className="py-24 lg:py-32" /> */}
      </div>
    </div>
  );
};

export default ListingCarPage;
