import React, { FC, useState } from "react";
import { BoatDataType } from "data/types";
import StartRating from "components/StartRating/StartRating";
import NcImage from "shared/NcImage/NcImage";
import { formatMoney } from "utils/helpers";
import Badge from "shared/Badge/Badge";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "redux/reducers";
import Button from "shared/Button/Button";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import ButtonThird from "shared/Button/ButtonThird";
import { ButtonLoader } from "components/ButtonLoader.jsx";
import { deleteBoat } from "redux/actions/boatActions";
import cogoToast from "cogo-toast";
import { useHistory } from "react-router-dom";

export interface CarCardProps {
  className?: string;
  data?: BoatDataType;
  owner?: boolean;
  size?: "default" | "small";
}

const DEMO_DATA: any = [];

const BoatCardDisplay: FC<CarCardProps> = ({
  size = "default",
  className = "",
  owner = false,
  data = DEMO_DATA,
}) => {
  const {
    _id: id,
    owner_id,
    title,
    slug,
    guest_capacity,
    // cabins,
    // beds,
    // bathrooms,
    // is_ads,
    price,
    // description,
    featured_img,
    // other_imgs,
    // views_count,
  } = data;
  const href = `/boat/${id}/${slug}`;

  const history = useHistory();

  const { isAuthenticated } = useSelector((store: RootState) => store.auth);

  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const handleDeleteBoat = () => {
    // e.preventDefault();
    const answer = window.confirm(
      "Are you sure you want to delete this boat?\nClick OK to confirm"
    );
    if (answer) {
      const deleteThisBoat = async () => {
        const result = await deleteBoat(id, slug, dispatch);
        if (result.status) {
          cogoToast.success(result.msg, {
            position: "top-right",
          });
        }
      };

      deleteThisBoat();
    }
  };

  const handleEditBoat = () => {
    history.push({
      pathname: `/list-boat/edit/${id}`,
    });
  };

  const renderSliderGallery = () => {
    return (
      <a href={href} className="flex flex-col">
        <div className="relative w-full rounded-2xl overflow-hidden">
          <div className="aspect-w-16 aspect-h-9 ">
            <NcImage
              containerClassName="flex items-center justify-center"
              className="w-full"
              src={featured_img}
            />
          </div>
        </div>
      </a>
    );
  };

  const renderContent = () => {
    return (
      <div className={size === "default" ? "p-5  space-y-4" : "p-3  space-y-2"}>
        <div className="space-y-2">
          <div className="flex items-center space-x-2">
            <a href={href} className="flex flex-col">
              <h2
                className={`  capitalize ${
                  size === "default"
                    ? "text-xl font-semibold"
                    : "text-base font-medium"
                }`}
              >
                <span className="line-clamp-1">{title}</span>
              </h2>
            </a>
          </div>
          <div className="flex items-center text-neutral-500 dark:text-neutral-400 text-sm space-x-2">
            <span className="hidden sm:inline-block  text-base">
              <i className="las la-users"></i>
            </span>
            <span className="">Capacity: {guest_capacity}</span>
            {/* <span className="">Beds: {beds} </span> */}
          </div>
          {!owner && (
            <div className="inline-flex space-x-3">
              <Badge
                name={
                  <div className="flex items-center">
                    <i className="text-sm las la-share-alt"></i>
                    <span className="ml-1">
                      {owner_id &&
                        (owner_id.company_name
                          ? owner_id.company_name
                          : owner_id.first_name + " " + owner_id.last_name)}
                    </span>
                  </div>
                }
              />
            </div>
          )}

          {/* <div className="flex items-center">
            <span className="hidden sm:inline-block  text-base">
              <i className="las la-map-marked"></i>
            </span>
            <span className="sm:ml-2">{fullLocation}</span>
          </div> */}
        </div>
        <div className="w-14  border-b border-neutral-100 dark:border-neutral-800"></div>
        <div className="flex justify-between items-center">
          <span className="text-base font-semibold">
            {price && formatMoney(price)}
            {` `}
            {size === "default" && (
              <span className="text-sm text-neutral-500 dark:text-neutral-400 font-normal">
                /day
              </span>
            )}
          </span>
          <StartRating reviewCount={5} />
        </div>
        {owner && (
          <div className="flex justify-between items-center">
            <ButtonPrimary type="button" onClick={handleDeleteBoat}>
              {loading ? <ButtonLoader /> : "Delete"}
            </ButtonPrimary>
            <ButtonSecondary
              className="ml-2"
              type="button"
              onClick={handleEditBoat}
            >
              {loading ? <ButtonLoader /> : "Edit Boat"}
            </ButtonSecondary>
          </div>
        )}
      </div>
    );
  };

  return (
    <div
      className={`nc-CarCard group relative border border-neutral-200 dark:border-neutral-700 rounded-3xl overflow-hidden hover:shadow-xl transition-shadow bg-white dark:bg-neutral-900 ${className}`}
      data-nc-id="CarCard"
    >
      {renderSliderGallery()}
      {renderContent()}
    </div>
  );
};

export default BoatCardDisplay;
