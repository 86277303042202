import { FC, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Input from "shared/Input/Input";
import Select from "shared/Select/Select";
import CommonLayout from "./CommonLayout";
import FormItem from "./FormItem";
import {
  saveListing,
  getStates,
  getLGAs,
  getCustomLocations,
} from "redux/actions/listingActions";
import { RootState } from "redux/reducers";
import Autocomplete from "react-google-autocomplete";

export interface PageAddListing1Props {}

const PageAddListing1: FC<PageAddListing1Props> = () => {
  const dispatch = useDispatch();
  const listing = useSelector((store: RootState) => store.listing);

  console.log("listing", listing);

  const customInput = {
    className: "",
    sizeClass: "h-14 px-4 py-3",
    fontClass: "text-lg font-normal",
    rounded: "rounded-xl",
  };

  const selectChange = (event: React.FormEvent<HTMLSelectElement>) => {
    const newValue: any = {};
    newValue[event.currentTarget.name] = event.currentTarget.value;
    console.log("New value", newValue);
    saveListing(newValue, dispatch);
  };

  const selectChangeLocation = (event: React.FormEvent<HTMLSelectElement>) => {
    const newValue: any = {};
    newValue[event.currentTarget.name] = event.currentTarget.value;
    console.log("New value", newValue);
    saveListing(newValue, dispatch);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue: any = {};
    newValue[e.target.name] = e.target.value;
    console.log("New value", newValue);

    saveListing(newValue, dispatch);
  };

  const changeLocation = (str: any) => {
    const newValue: any = {};
    newValue["long_location"] = str.formatted_address;
    saveListing(newValue, dispatch);
  };

  useEffect(() => {
    async function getLocations() {
      await getCustomLocations(dispatch);
    }
    getLocations();
  }, []);

  const changeState = (event: React.FormEvent<HTMLSelectElement>) => {
    const newValue: any = {};
    const stateslug =
      event.currentTarget.options[
        event.currentTarget.selectedIndex
      ].getAttribute("data-slug");
    // const evt = event.currentTarget.getAttribute("data-slug");
    newValue[event.currentTarget.name] = event.currentTarget.value;
    console.log("EVT", stateslug);

    console.log("newValue", newValue);
    // saveListing(newValue, dispatch);
  };

  return (
    <>
      <CommonLayout
        index="01"
        backtHref="/"
        disableBack={true}
        nextHref="/add-listing-2"
      >
        <>
          <h2 className="text-2xl font-semibold">
            Choosing listing categories
          </h2>
          <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
          {/* FORM */}
          <div className="space-y-8">
            {/* ITEM */}
            <FormItem
              label="Type of Apartment"
              desc="Hotel: Professional hospitality businesses that usually have a unique style or theme defining their brand and decor"
            >
              <Select
                name="apartment_type"
                onChange={selectChange}
                defaultValue="select-apartment"
              >
                <option value="select-apartment" disabled>
                  --Select Apartment Type--
                </option>
                <option value="Beach House">Beach House</option>
                <option value="Condo">Condo</option>
                <option value="Duplex">Duplex</option>
                <option value="Flat">Flat</option>
                <option value="Garden">Garden</option>
                <option value="Highrise">Highrise</option>
                <option value="Penthouse">Penthouse</option>
                <option value="Studio Apartment">Studio Apartment</option>
              </Select>
            </FormItem>
            <FormItem
              label="Title of Apartment"
              desc="A catchy name usually includes: House name + Featured property + Tourist destination"
            >
              <Input
                placeholder="Apartment title"
                name="title"
                onChange={handleChange}
                value={listing.title ?? ""}
              />
            </FormItem>
          </div>
        </>

        <>
          <h2 className="text-2xl font-semibold">Apartment Location</h2>
          <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
          {/* FORM */}
          <div className="space-y-8">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8 md:gap-5">
              <FormItem label="Location">
                <Select name="location" onChange={selectChangeLocation}>
                  <option>--Select Location--</option>
                  {listing &&
                    listing.customLocations.map((eachLocation: any) => (
                      <option
                        key={eachLocation.slug}
                        value={eachLocation._id}
                        data-slug={eachLocation.state}
                      >
                        {eachLocation.location}
                      </option>
                    ))}
                </Select>
              </FormItem>
              <FormItem label="Sub Location">
                <Input
                  placeholder="Sub Location"
                  name="sublocation"
                  onChange={handleChange}
                  value={listing.sublocation ?? ""}
                />
              </FormItem>
            </div>
          </div>

          <div className="space-y-8">
            {/* ITEM */}
            {/* <div className="grid grid-cols-1 gap-8 md:gap-5">
              <FormItem label="Select Location">
                <Autocomplete
                  apiKey="AIzaSyDK7O0sdgzQFq3MR-e66JZ3RAkZHEy4Tis"
                  onPlaceSelected={(place) => {
                    changeLocation(place);
                    console.log(place);
                  }}
                  className={`block w-full border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900 border-solid ${customInput.rounded} ${customInput.fontClass} ${customInput.sizeClass} ${customInput.className}`}
                  style={{ border: "1px solid #E5E5E5" }}
                  defaultValue={listing.long_location ?? ""}
                />
              </FormItem>
            </div> */}
          </div>
        </>
      </CommonLayout>
    </>
  );
};

export default PageAddListing1;
