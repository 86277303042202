import httpRequest from "../../api";
import { listConstants, bookingConstants } from "../actionTypes";

export const fetchMyApartments = (host_id, dispatch) => {
  dispatch({ type: listConstants.LOADING });
  return httpRequest("get", `apartment/user/${host_id}`)
    .then(({ data }) => {
      console.log("fetched", data);
      let action = {};
      if (data.status === 200) {
        if (data.data.apartments.length > 0) {
          action = {
            type: listConstants.FETCH_MY_APARTMENTS,
            payload: data.data.apartments,
          };
        } else {
          action = {
            type: listConstants.NO_APARTMENT,
            payload: data.data.message,
          };
        }
        dispatch({ type: listConstants.STOP_LOADING });
        dispatch(action);
      } else {
        const payload = "Could not load apartment";
        action = { type: listConstants.ERROR, payload };

        dispatch({ type: listConstants.STOP_LOADING });
        dispatch(action);
      }

      return action;
    })
    .catch(({ response }) => {
      dispatch({ type: listConstants.STOP_LOADING });

      const message = response?.data; //.data?.message;
      return {
        payload: message,
        type: "ERROR",
      };
    });
};

// ========================================================

export const getSingleApartment = (apartment_id, slug, dispatch) => {
  console.log("starting to fetch");
  dispatch({ type: listConstants.LOADING });

  return httpRequest("get", `apartment/${apartment_id}`)
    .then(({ data }) => {
      const action = {
        type: listConstants.GET_SINGLE_APARTMENT,
        payload: data.data,
      };

      dispatch({ type: listConstants.STOP_LOADING });
      dispatch(action);
    })
    .catch(({ response }) => {
      const message = response?.data; //.data?.message;
      dispatch({ type: listConstants.STOP_LOADING });

      return {
        payload: message,
        type: "ERROR",
      };
    });
};

// ========================================================

export const getSingleLocation = (location_id, slug, dispatch) => {
  console.log("starting to fetch");
  dispatch({ type: listConstants.LOADING });

  return httpRequest("get", `location/${location_id}/${slug}`)
    .then(({ data }) => {
      console.log("dataloation", data.data);
      const action = {
        type: listConstants.FETCH_SINGLE_LOCATION,
        payload: data.data.location,
      };

      dispatch({ type: listConstants.STOP_LOADING });
      dispatch(action);
    })
    .catch(({ response }) => {
      const message = response?.data; //.data?.message;
      dispatch({ type: listConstants.STOP_LOADING });

      return {
        payload: message,
        type: "ERROR",
      };
    });
};

// ========================================================

export const fetchApartmentsByLocation = (
  filter = {},
  page,
  loadMore,
  location_slug,
  location_id,
  dispatch
) => {
  if (page === 1) dispatch({ type: listConstants.LOADING });
  const limit = 12; //change to 10

  return httpRequest(
    "get",
    `apartment/location/${location_id}?page=${page}&limit=${limit}`
  )
    .then(({ data }) => {
      console.log("fetched", data);
      let action = {};

      if (data.status === 200) {
        // if (data.data.apartments.length > 0) {

        if (page > 1) {
          action = {
            type: listConstants.FETCH_MORE_APARTMENTS_LOCATION,
            payload: data.data,
          };
        } else {
          action = {
            type: listConstants.FETCH_APARTMENTS_LOCATION,
            payload: data.data,
          };
        }
        // } else {
        //   action = {
        //     type: listConstants.NO_APARTMENT,
        //     payload: data.data.message,
        //   };
        // }
        dispatch({ type: listConstants.STOP_LOADING });
        dispatch(action);
      } else {
        const payload = "Could not load apartment";
        action = { type: listConstants.ERROR, payload };

        dispatch({ type: listConstants.STOP_LOADING });
        dispatch(action);
      }

      return action;
    })
    .catch(({ response }) => {
      dispatch({ type: listConstants.STOP_LOADING });

      const message = response?.data; //.data?.message;
      return {
        payload: message,
        type: "ERROR",
      };
    });
};

// ========================================================

export const fetchFeaturedApartments = (dispatch) => {
  dispatch({ type: listConstants.LOADING });

  return httpRequest("get", `apartment/featured`)
    .then(({ data }) => {
      console.log("featured", data);

      let action = {};

      if (data.status === 200) {
        if (data.data.apartments.length > 0) {
          action = {
            type: listConstants.FETCH_FEATURED_APARTMENTS,
            payload: data.data.apartments,
          };
        } else {
          action = {
            type: listConstants.NO_APARTMENT,
            payload: data.data.message,
          };
        }
        dispatch({ type: listConstants.STOP_LOADING });
        dispatch(action);
      } else {
        const payload = "Could not load apartment";
        action = { type: listConstants.ERROR, payload };

        dispatch({ type: listConstants.STOP_LOADING });
        dispatch(action);
      }

      return action;
    })
    .catch(({ response }) => {
      dispatch({ type: listConstants.STOP_LOADING });

      const message = response?.data; //.data?.message;
      return {
        payload: message,
        type: "ERROR",
      };
    });
};

// ====================================================================

export const deleteApartment = (apartment_id, slug, dispatch) => {
  dispatch({ type: listConstants.FETCHING });

  console.log("{{{{{{{ DELETING AN APARTMENT }}}}}}}}");

  return httpRequest("delete", `apartment/${apartment_id}/${slug}`)
    .then(({ data }) => {
      console.log("fetched", data.data.message);
      let action = {};
      if (data.status === 200) {
        action = {
          type: listConstants.DELETE_APARTMENT,
          payload: apartment_id,
        };
        dispatch(action);
      } else {
        const payload = "Could not load boat";
        action = { type: listConstants.ERROR, payload };

        // dispatch(action);
      }

      dispatch({ type: listConstants.STOP_FETCHING });

      return { status: true, msg: data.data.message };
    })
    .catch(({ response }) => {
      dispatch({ type: listConstants.STOP_FETCHING });

      const message = response?.data; //.data?.message;
      return {
        payload: message,
        type: "ERROR",
      };
    });
};

// ===============≠===============≠===============

export const getPropertiesByHost = (
  category,
  hostid,
  filter = {},
  page,
  loadMore,
  dispatch
) => {
  dispatch({ type: bookingConstants.LOADING });

  dispatch({ type: bookingConstants.LOADING });

  return httpRequest("get", `${category}/user/${hostid}?page=1&limit=12`)
    .then(({ data }) => {
      const action = {
        type: bookingConstants.PROPERTIES_BY_HOST,
        payload: data.data,
      };

      console.log("APArtments by host >>>", data);

      dispatch({ type: bookingConstants.STOP_LOADING });
      dispatch(action);

      console.log("properties by host", data);
      return data;
    })
    .catch(({ response }) => {
      const message = response?.data; //.data?.message;
      console.log("response", response);
      dispatch({ type: bookingConstants.STOP_LOADING });
      return {
        payload: message,
        type: "ERROR",
      };
    });
};

// ========================================================================

export const updateApartmentAction = (details, apartmentid, dispatch) => {
  const postData = {
    title: details.title,
    apartment_type: details.apartment_type,
    location: details.location,
    sublocation: details.sublocation,
    description: details.description,
    featured_img: details.featured_img,
    gallery_imgs: details.gallery_imgs,
    price: details.price,
    max_guests: details.max_guests,
    bedrooms: details.bedrooms,
    bathrooms: details.bathrooms,
    min_nights: details.min_nights,
  };

  return httpRequest("patch", `apartment/${apartmentid}`, postData)
    .then(({ data }) => {
      let action = {};
      console.log("Response Data", data);
      if (data.status === 200) {
        action = {
          payload: data.message,
        };

        // dispatch(action);
      } else {
        const payload = "Could not load post car";
        action = { payload };
        // dispatch(action);
      }

      return action;
    })
    .finally(() => {
      dispatch({ type: listConstants.STOP_LOADING });
    })
    .catch(({ response }) => {
      console.log("response", response);

      const message = response?.data?.data?.error;
      console.log("MSG", message);
      const action = { payload: message, type: "ERROR" };

      dispatch(action);

      return action;
    });
};
