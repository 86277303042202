import React, { FC, ReactNode, useState } from "react";
import imagePng from "images/shortlet-homes1.jpg";
import boatsbanner from "images/boatbanner.jpg";
import carsbanner from "images/carbanner.jpg";
import shortletsbanner from "images/shortletspageimg.jpeg";
import HeroSearchForm, {
  SearchTab,
} from "components/HeroSearchForm/HeroSearchForm";

export interface SectionHeroArchivePageProps {
  className?: string;
  listingType?: ReactNode;
  currentPage: "Stay" | "Experiences" | "Rental car" | "Shortlets";
  currentTab: SearchTab;
  heroText: string;
  subtitleText: string;
  category?: String;
}

const SectionHeroArchivePage: FC<SectionHeroArchivePageProps> = ({
  className = "",
  listingType,
  currentPage,
  currentTab,
  heroText,
  subtitleText,
  category,
}) => {
  const [searchBanner, setSearchBanner] = useState(shortletsbanner);

  useState(() => {
    if (category) {
      if (category === "apartment") setSearchBanner(shortletsbanner);
      else if (category === "car") setSearchBanner(carsbanner);
      else if (category === "boat") setSearchBanner(boatsbanner);
    }
  });

  return (
    <div
      className={`nc-SectionHeroArchivePage flex flex-col relative ${className}`}
      data-nc-id="SectionHeroArchivePage"
    >
      <div className="flex flex-col lg:flex-row lg:items-center pb-14">
        <div className="flex-shrink-0 lg:w-1/2 flex flex-col items-start space-y-2 lg:space-y-2 pb-14 lg:pb-16 xl:pr-14 lg:mr-10 xl:mr-0">
          <h2 className="font-medium text-4xl md:text-5xl xl:text-7xl leading-[110%]">
            {heroText}
          </h2>
          <div className="flex items-center text-base md:text-lg text-neutral-500 dark:text-neutral-400">
            {/* <i className="text-2xl las la-map-marked"></i> */}
            <span className="ml-2.5">{subtitleText}</span>
            <span className="mx-5"></span>
            {/* {listingType ? (
              listingType
            ) : (
              <>
                <i className="text-2xl las la-home"></i>
                <span className="ml-2.5">112 properties</span>
              </>
            )} */}
          </div>
        </div>
        <div className="flex-grow">
          <img className="w-full" src={searchBanner} alt="hero" />
        </div>
      </div>

      {/* <div className="flow-root w-full">
        <div className="z-10 lg:-mt-40 xl:-mt-56 w-full">
          <HeroSearchForm currentPage={currentPage} currentTab={currentTab} />
        </div>
      </div> */}
    </div>
  );
};

export default SectionHeroArchivePage;
