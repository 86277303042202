import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { Page } from "./types";
import ScrollToTop from "./ScrollToTop";
import Footer from "shared/Footer/Footer";
import PageHome from "containers/PageHome/PageHome";
import Header from "shared/Header/Header";
import Page404 from "containers/Page404/Page404";
import ShortletsPage from "containers/ListingStayPage/ShortletsPage";
// import ListingStayMapPage from "containers/ListingStayPage/ListingStayMapPage";
import FeaturedLocationsPage from "containers/ListingStayPage/FeaturedLocationsPage";
// import ListingExperiencesPage from "containers/ListingExperiencesPage/ListingExperiencesPage";
import ListingExperiencesMapPage from "containers/ListingExperiencesPage/ListingExperiencesMapPage";
import ListingStayDetailPage from "containers/ListingDetailPage/ListingStayDetailPage";
import ListingExperiencesDetailPage from "containers/ListingDetailPage/ListingExperiencesDetailPage";
import ListingCarPage from "containers/ListingCarPage/ListingCarPage";
// import ListingCarMapPage from "containers/ListingCarPage/ListingCarMapPage";
// import ListingCarDetailPage from "containers/ListingDetailPage/ListingCarDetailPage";
import CheckOutPage from "containers/CheckOutPage/CheckOutPage";
import PayPage from "containers/PayPage/PayPage";
import DashboardPage from "containers/DashboardPage/DashboardPage";
import ProfilePage from "containers/ProfilePage/ProfilePage";
import ProfilePass from "containers/ProfilePage/ProfilePass";
import ProfileSavelists from "containers/ProfilePage/ProfileSavelists";
import ProfileBilling from "containers/ProfilePage/ProfileBilling";
import PageContact from "containers/PageContact/PageContact";
import PageAbout from "containers/PageAbout/PageAbout";
import PageSignUp from "containers/PageSignUp/PageSignUp";
import PageLogin from "containers/PageLogin/PageLogin";
import PageForgotPassword from "containers/PageForgotPassword/PageForgotPassword";
import PageResetPassword from "containers/PageResetPassword/PageResetPassword";
import PageSubcription from "containers/PageSubcription/PageSubcription";
import BlogPage from "containers/BlogPage/BlogPage";
import BlogSingle from "containers/BlogPage/BlogSingle";
import PageAddListing1 from "containers/PageAddListing/PageAddListing1";
import PageAddListing2 from "containers/PageAddListing/PageAddListing2";
import PageAddListing3 from "containers/PageAddListing/PageAddListing3";
import PageAddListing4 from "containers/PageAddListing/PageAddListing4";
import PageAddListing5 from "containers/PageAddListing/PageAddListing5";
import PageAddListing6 from "containers/PageAddListing/PageAddListing6";
import PageAddListing7 from "containers/PageAddListing/PageAddListing7";
import PageAddListing8 from "containers/PageAddListing/PageAddListing8";
import ListingRealEstateMapPage from "containers/ListingRealEstatePage/ListingRealEstateMapPage";
import ListingRealEstatePage from "containers/ListingRealEstatePage/ListingRealEstatePage";
import PageRegisterConfirmation from "containers/PageRegisterConfirmation/PageRegisterConfirmation";
import ProtectedRoute from "./ProtectedRoute";
import PagePublishedListing from "containers/PageAddListing/PagePublishedListing";
import ApartmentDetailsPage from "containers/ListingDetailPage/ApartmentDetailsPage";
import PageBookingSuccess from "containers/PageAddListing/PageBookingSuccess";
import MyBookingsPage from "containers/Bookings/MyBookingsPage";
import PageListHotel from "containers/PageAddListing/PageListHotel";
import ListingExperiencesPage from "containers/ListingExperiencesPage/ListingExperiencesPage";
import PageListCar from "containers/PageAddListing/PageListCar";
import PageListBoat from "containers/PageAddListing/PageListBoat";
import ListingHotelPage from "containers/ListingRealEstatePage/ListingHotelPage";
import ListingCarDetailPage from "containers/ListingDetailPage/ListingCarDetailPage";
import ListingCarMapPage from "containers/ListingCarPage/ListingCarMapPage";
import ListingBoatPage from "containers/ListingBoatPage/ListingBoatPage";
import ListingBoatDetailPage from "containers/ListingDetailPage/ListingBoatDetailPage";
import SearchResultPage from "containers/ListingStayPage/SearchResultPage";
import PropertiesByHostPage from "containers/ListingStayPage/PropertiesByHostPage";
import PageEditCar from "containers/PageAddListing/PageEditCar";
import PageEditBoat from "containers/PageAddListing/PageEditBoat";
import PageEditApartment from "containers/PageAddListing/PageEditApartment";

export const pages: Page[] = [
  { path: "/blog", component: BlogPage },
  { path: "/blog-single", component: BlogSingle },
  { path: "/contact", component: PageContact },
  { path: "/about", component: PageAbout },
  { path: "/subscription", component: PageSubcription },
];

const Routes = () => {
  return (
    <BrowserRouter basename="/">
      <ScrollToTop />
      <Header />
      <Switch>
        <Route exact path="/" component={PageHome} />
        <Route exact path="/#" component={PageHome} />
        <Route path="/login" component={PageLogin} />
        <Route path="/forgot-pass" component={PageForgotPassword} />
        <Route
          path="/reset-password/:userid/:resettoken"
          component={PageResetPassword}
        />
        <Route path="/signup" component={PageSignUp} />
        <Route
          path="/register-confirmation"
          component={PageRegisterConfirmation}
        />

        <Route
          path="/searchresult/:category/:locationid"
          component={SearchResultPage}
        />

        {/* DISPLAY */}
        <Route
          path="/apartment/:apartmentid/:slug"
          component={ApartmentDetailsPage}
        />

        <Route
          path="/apartment/:apartmentid/:slug/preview"
          component={ApartmentDetailsPage}
        />

        {/* Stays */}
        <Route path="/shortlets" component={ShortletsPage} />
        <Route
          path="/host/:property/:hostid"
          component={PropertiesByHostPage}
          exact={true}
        />

        {/* Edit car details */}
        <ProtectedRoute
          path="/list-car/edit/:carid"
          component={PageEditCar}
          exact={true}
        />
        {/* Edit boat details */}
        <ProtectedRoute
          path="/list-boat/edit/:boatid"
          component={PageEditBoat}
          exact={true}
        />
        {/* Edit boat details */}
        <ProtectedRoute
          path="/list-apartment/edit/:apartmentid"
          component={PageEditApartment}
          exact={true}
        />
        {/* LIST APARTMENTS BY LOCATION */}
        <Route
          path="/:location_slug/:location_id"
          component={FeaturedLocationsPage}
          exact={true}
        />

        <Route path="/listing-stay-detail" component={ListingStayDetailPage} />

        {/* LIST PROPERTIES BY HOST */}
        {/* <Route
          path="/host/:apartmentid/:slug/preview"
          component={ApartmentDetailsPage}
        /> */}

        <Route path="/hotels" component={ListingHotelPage} />

        <Route
          path="/car/:carid/:slug"
          component={ListingCarDetailPage}
          exact={true}
        />
        <Route path="/car-rentals" component={ListingCarPage} />

        <Route path="/boats" component={ListingBoatPage} />
        <Route path="/boat/:boatid/:slug" component={ListingBoatDetailPage} />
        <Route
          path="/listing-real-estate-map"
          component={ListingRealEstateMapPage}
        />
        {/* Real Estate */}
        <Route path="/listing-real-estate" component={ListingRealEstatePage} />
        {/* Experiences */}
        <Route path="/listing-experiences" component={ListingExperiencesPage} />
        <Route
          path="/listing-experiences-map"
          component={ListingExperiencesMapPage}
        />
        <Route
          path="/listing-experiences-detail"
          component={ListingExperiencesDetailPage}
        />
        {/* Cars Listing */}
        {/*
        <Route path="/listing-car" component={ListingCarPage} />
        {/* Protected Routes */}
        {/* <Route path="/listing-car-map" component={ListingCarMapPage} /> */}
        <Route path="/listing-car-detail" component={ListingCarDetailPage} />

        <ProtectedRoute path="/dashboard" component={DashboardPage} />
        <ProtectedRoute path="/profile" component={ProfilePage} />
        <ProtectedRoute path="/profile-password" component={ProfilePass} />
        <ProtectedRoute
          path="/profile-savelists"
          component={ProfileSavelists}
        />
        <ProtectedRoute path="/profile-billing" component={ProfileBilling} />

        {/* POST APARTMENT */}
        <ProtectedRoute path="/add-listing" component={PageAddListing1} />
        <ProtectedRoute path="/add-listing-2" component={PageAddListing2} />
        <ProtectedRoute path="/add-listing-3" component={PageAddListing3} />
        <ProtectedRoute path="/add-listing-4" component={PageAddListing4} />
        <ProtectedRoute path="/add-listing-5" component={PageAddListing5} />
        <ProtectedRoute path="/add-listing-6" component={PageAddListing6} />
        <ProtectedRoute path="/add-listing-7" component={PageAddListing7} />
        <ProtectedRoute path="/add-listing-8" component={PageAddListing8} />
        <ProtectedRoute
          path="/listing-published"
          component={PagePublishedListing}
        />

        {/* POST LISTING */}
        <ProtectedRoute path="/list-hotel" component={PageListHotel} />
        <ProtectedRoute path="/list-car" component={PageListCar} exact={true} />
        <ProtectedRoute path="/list-boat" component={PageListBoat} />

        <ProtectedRoute path="/bookings" component={MyBookingsPage} />

        <Route path="/booking-success" component={PageBookingSuccess} />
        <Route path="/checkout" component={CheckOutPage} />
        <Route path="/pay-done" component={PayPage} />
        <Route component={Page404} />
      </Switch>
      <Footer />
    </BrowserRouter>
  );
};

export default Routes;
