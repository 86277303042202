import React, { FC, useEffect, useState } from "react";
import { ArrowRightIcon } from "@heroicons/react/outline";
import FiveStartIconForRate from "components/FiveStartIconForRate/FiveStartIconForRate";
import { DateRage } from "components/HeroSearchForm/StaySearchForm";
import StartRating from "components/StartRating/StartRating";
// import useWindowSize from "hooks/useWindowResize";
import moment from "moment";
import Avatar from "shared/Avatar/Avatar";
import Badge from "shared/Badge/Badge";
import { Helmet } from "react-helmet";
import ButtonCircle from "shared/Button/ButtonCircle";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Input from "shared/Input/Input";
import NcImage from "shared/NcImage/NcImage";
import LikeSaveBtns from "./LikeSaveBtns";
import ModalPhotos from "./ModalPhotos";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import SectionSliderNewCategories from "components/SectionSliderNewCategories/SectionSliderNewCategories";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "redux/reducers";
import { getSingleApartment } from "redux/actions/listActions";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import { formatMoney } from "utils/helpers";
import { submitInquiry } from "redux/actions/bookingActions";
import { bookingConstants } from "redux/actionTypes";
import cogoToast from "cogo-toast";
import { ShowLoading } from "components/ShowLoading";
import OwnersCard from "components/Owner/OwnersCard";
import FormItem from "containers/PageAddListing/FormItem";
import Textarea from "shared/Textarea/Textarea";
import { ButtonLoader } from "components/ButtonLoader.jsx";
import SocialRow from "components/Owner/SocialRow";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export interface ApartmentDetailPageProps {
  className?: string;
  isPreviewMode?: boolean;
}

export interface SingleAparmentProps {
  apartmentid: string;
  slug: string;
}

const ApartmentDetailsPage: FC<ApartmentDetailPageProps> = ({
  className = "",
  isPreviewMode = true,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useDispatch();
  // const history = useHistory();
  const [openFocusIndex, setOpenFocusIndex] = useState(0);
  const [state, setState] = useState({
    fullname: "",
    phone: "",
    email: "",
    message: "",
    from: "",
    to: "",
  });
  const location = useLocation();
  const [whatsAppMessage, setWhatsAppMessage] = useState("");
  const [pageTitle, setPageTitle] = useState("");
  const [apartmentURL, setApartmentURL] = useState("");
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const { apartmentid, slug } = useParams<SingleAparmentProps>();
  const { apartment, loading, ownerscount }: any = useSelector(
    (state: RootState) => state.list
  );

  // console.log("apartmnet details", apartment);

  useEffect(() => {
    // setLoading(true);
    const fetchSingleApartment = async () => {
      await getSingleApartment(apartmentid, slug, dispatch);
      // setLoading(false);
    };

    fetchSingleApartment();
  }, []);

  // const windowSize = useWindowSize();

  const handleOpenModal = (index: number) => {
    setIsOpen(true);
    setOpenFocusIndex(index);
  };

  useEffect(() => {
    setApartmentURL(`https://holidayshortstays.com${location.pathname}`);
    const text = `I am interested in your property on Holiday Short Stays:

    https://holidayshortstays.com${location.pathname}`;

    decodeURIComponent(text.replace(/\+/g, " "));
    setWhatsAppMessage(text);

    document.title = "Fresh apartment";

    // const changePageTitle = async () => {
    //   await new Promise((resolve) => setTimeout(resolve, 5000));
    //   if (apartment)
    //     setPageTitle(
    //       `${apartment.title} - Shortlet Apartments on Holiday Short Stays`
    //     );
    // };
    // console.log("apartment", apartment);

    // changePageTitle();
  }, [location]);

  const handleCloseModal = () => setIsOpen(false);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue: any = {};
    newValue[e.target.name] = e.target.value;
    setState({ ...state, ...newValue });
  };

  const handleChangeTextArea = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newValue: any = {};
    newValue[e.target.name] = e.target.value;
    setState({ ...state, ...newValue });
  };

  const handleSubmit = (e: { preventDefault: () => void }) => {
    e.preventDefault();

    if (!state.fullname || !state.phone || !state.email || !state.message) {
      cogoToast.error("Kindly fill all fields before submitting", {
        position: "top-center",
      });
      return;
    }

    dispatch({ type: bookingConstants.LOADING });
    const category = "apartment";

    console.log("property", apartment);

    const fromDate = moment(startDate).format("dddd, MMMM Do YYYY");
    const toDate = moment(endDate).format("dddd, MMMM Do YYYY");

    let postPayload: any = state;
    postPayload["owner_id"] = apartment.host;
    postPayload["category"] = category;
    postPayload["property_id"] = apartment._id;
    postPayload["property_title"] = apartment.title;
    postPayload["fromDate"] = fromDate;
    postPayload["toDate"] = toDate;

    postPayload[
      "property_url"
    ] = `https://holidayshortstays.com/${category}/${apartment._id}/${slug}`;

    const processSubmitInquiry = async () => {
      const booked = await submitInquiry(postPayload, dispatch);
      console.log("Booked", booked);
      const {
        payload: { data, status },
      } = booked;
      if (status === 200) {
        cogoToast.success(data.message, {
          position: "top-right",
          hideAfter: 5,
        });
        const defaultState = {
          fullname: "",
          phone: "",
          email: "",
          message: "",
        };
        setState({ ...state, ...defaultState });
      } else {
        cogoToast.error(data.message, {
          position: "top-right",
        });
        dispatch({ type: bookingConstants.STOP_LOADING });
      }
    };

    processSubmitInquiry();
  };

  const renderSection1 = () => {
    return (
      <div className="listingSection__wrap !space-y-6">
        {/* 1 */}
        <div className="flex justify-between items-center">
          <Badge name={apartment.apartment_type} />
          <LikeSaveBtns
            url={apartmentURL}
            description={`Check out this apartment on Holiday Short Stays`}
          />
        </div>

        {/* 2 */}
        <h2 className="text-2xl sm:text-3xl lg:text-4xl font-semibold">
          {apartment.title}
        </h2>

        {/* 3 */}
        <div className="flex items-center space-x-4">
          <span>
            <i className="las la-map-marker-alt"></i>
            <span className="ml-1">
              {" "}
              {apartment.location && apartment.location.location}
              {apartment.sublocation ? `, ${apartment.sublocation}` : ""}
            </span>
          </span>
          <span>·</span>
          <StartRating />
        </div>

        {/* 4 */}
        <div className="flex items-center">
          <Avatar
            hasChecked
            sizeClass="h-10 w-10"
            radius="rounded-full"
            userName={apartment.host && apartment.host.first_name}
            imgUrl={
              apartment.host && apartment.host.profile_url
                ? apartment.host.profile_url
                : null
            }
          />
          <span className="ml-2.5 text-neutral-500 dark:text-neutral-400">
            Hosted by{" "}
            <span className="text-neutral-900 dark:text-neutral-200 font-medium">
              {apartment.host &&
                (apartment.host.company_name
                  ? apartment.host.company_name
                  : `${apartment.host.first_name} ${apartment.host.last_name}`)}
            </span>
          </span>
        </div>

        {/* 5 */}
        <div className="w-full border-b border-neutral-100 dark:border-neutral-700" />

        {/* 6 */}
        <div className="flex items-center justify-between xl:justify-start space-x-8 xl:space-x-12 text-sm text-neutral-700 dark:text-neutral-300">
          <div className="flex items-center space-x-3 ">
            <i className=" las la-user text-2xl "></i>
            <span className="">
              {apartment.max_guests}{" "}
              <span className="hidden sm:inline-block">guests</span>
            </span>
          </div>
          <div className="flex items-center space-x-3">
            <i className=" las la-bed text-2xl"></i>
            <span className=" ">
              {apartment.bedrooms}{" "}
              <span className="hidden sm:inline-block">beds</span>
            </span>
          </div>
          <div className="flex items-center space-x-3">
            <i className=" las la-bath text-2xl"></i>
            <span className=" ">
              {apartment.bathrooms}{" "}
              <span className="hidden sm:inline-block">baths</span>
            </span>
          </div>
          {/* <div className="flex items-center space-x-3">
            <i className=" las la-door-open text-2xl"></i>
            <span className=" ">
              2 <span className="hidden sm:inline-block">bedrooms</span>
            </span>
          </div> */}
        </div>
      </div>
    );
  };

  const renderSection2 = () => {
    return (
      <div className="listingSection__wrap">
        <h2 className="text-2xl font-semibold">
          More information about apartment
        </h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        <div className="text-neutral-6000 dark:text-neutral-300">
          {apartment.description}
        </div>
      </div>
    );
  };

  const renderSection3 = () => {
    return (
      <div className="listingSection__wrap">
        <div>
          <h2 className="text-2xl font-semibold">Amenities </h2>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        {/* 6 */}
        <div className="grid md:grid-cols-1 grid-cols-2 xl:grid-cols-3 gap-6 text-sm text-neutral-700 dark:text-neutral-300 ">
          {apartment.amenities &&
            apartment.amenities
              .filter((_: any, i: number) => i < 12)
              .map((item: any) => (
                <div key={item.name} className="flex items-center space-x-3">
                  <i className={`text-3xl las ${item.icon}`}></i>
                  <span className=" ">{item.name}</span>
                </div>
              ))}
        </div>
      </div>
    );
  };

  const renderSection4 = () => {
    return (
      <div className="listingSection__wrap">
        {/* HEADING */}
        <div>
          <h2 className="text-2xl font-semibold">Room Rates </h2>
          <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
            Prices may increase on weekends or holidays
          </span>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        {/* CONTENT */}
        <div className="flow-root">
          <div className="text-sm sm:text-base text-neutral-6000 dark:text-neutral-300 mb-4">
            <div className="p-4 bg-neutral-100 dark:bg-neutral-800 flex justify-between items-center space-x-4 rounded-lg">
              <span>Daily Rate</span>
              <span>{apartment.price ? formatMoney(apartment.price) : 0}</span>
            </div>
            <div className="p-4 flex justify-between items-center space-x-4 rounded-lg">
              <span>Minimum number of nights</span>
              <span>{apartment.min_nights} night</span>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderSection5 = () => {
    return (
      <div className="listingSection__wrap md:hidden">
        {/* HEADING */}
        <div>
          <h2 className="text-2xl font-semibold">Posted By</h2>
          <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
            The details of this apartment is in total control of the poster.
          </span>
        </div>
        <div className="w-14 border-b border-ne utral-200 dark:border-neutral-700"></div>
        {/* CONTENT */}
        <div className="flow-root">
          <div className="text-sm sm:text-base text-neutral-6000 dark:text-neutral-300 mb-4">
            <div className="p-4 bg-neutral-100 dark:bg-neutral-800 flex justify-between items-center space-x-4 rounded-lg">
              <span>Full Name</span>
              <span>
                {apartment.host &&
                  `${apartment.host.first_name} ${apartment.host.last_name}`}
              </span>
            </div>
            <div className="p-4 flex justify-between items-center space-x-4 rounded-lg">
              <span>Company Name</span>
              <span>
                {apartment.host &&
                  (apartment.host.company_name
                    ? apartment.host.company_name
                    : `${apartment.host.first_name} ${apartment.host.last_name}`)}
              </span>
            </div>
            <div className="p-4 bg-neutral-100 dark:bg-neutral-800 flex justify-between items-center space-x-4 rounded-lg">
              <span>Phone Number: </span>
              <span>{apartment.host && apartment.host.phone1}</span>
            </div>
            <div className="p-4 flex justify-between items-center space-x-4 rounded-lg">
              <span>Email: </span>
              <span>{apartment.host && apartment.host.email}</span>
            </div>
            {apartment.host && apartment.host.phone1 && (
              <div className="p-4 flex justify-between dark:bg-neutral-800 items-center space-x-4 rounded-lg">
                <span>WhatsApp:</span>
                <span>
                  <a
                    href={`https://wa.me/${apartment.host.phone1}?text=${whatsAppMessage}`}
                    target="_blank"
                    style={{ textDecoration: "underline" }}
                  >
                    +{apartment.host.phone1}
                  </a>
                </span>
              </div>
            )}
            <div className="p-4 flex justify-between items-center space-x-4 rounded-lg">
              {apartment && apartment.host && (
                <Link to={`/host/apartment/${apartment.host._id}`}>
                  <span style={{ textDecoration: "underline" }}>
                    Total Properties:
                  </span>
                </Link>
              )}
              <span>{ownerscount}</span>
            </div>
            <div className="p-4 flex justify-between dark:bg-neutral-800 items-center space-x-4 rounded-lg">
              <span>Social Media:</span>
              <span>
                <SocialRow socials={apartment.social} />
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderSection6 = () => {
    return (
      <div className="listingSection__wrap">
        {/* HEADING */}
        <h2 className="text-2xl font-semibold">Reviews</h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>

        {/* Content */}
        <div className="space-y-5">
          <FiveStartIconForRate iconClass="w-6 h-6" className="space-x-0.5" />
          <div className="relative">
            <Input
              fontClass=""
              sizeClass="h-16 px-4 py-3"
              rounded="rounded-3xl"
              placeholder="Share your thoughts ..."
            />
            <ButtonCircle
              className="absolute right-2 top-1/2 transform -translate-y-1/2"
              size=" w-12 h-12 "
            >
              <ArrowRightIcon className="w-5 h-5" />
            </ButtonCircle>
          </div>
        </div>
      </div>
    );
  };

  const renderSectionCheckIndate = () => {
    return (
      <div className="listingSection__wrap overflow-hidden">
        <form method="POST" onSubmit={handleSubmit}>
          {/* HEADING */}
          <div className="">
            <h2 className="text-2xl font-semibold">
              Inquire about this shortlet
            </h2>
            <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
              All inquiries filled in the form below go directly to the host of
              the apartment.
            </span>
          </div>
          <div className="w-14 border-b border-neutral-200 mt-8 mb-8 dark:border-neutral-700"></div>
          {/*  */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8 md:gap-5 mt-6">
            <FormItem label="Full Name">
              <Input
                placeholder="Full Name"
                type="text"
                name="fullname"
                onChange={handleChange}
                value={state.fullname}
              />
            </FormItem>
            <FormItem label="Phone Number">
              <Input
                placeholder="phone"
                type="number"
                name="phone"
                onChange={handleChange}
                value={state.phone}
              />
            </FormItem>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-1 gap-8 md:gap-5 mt-6">
            <FormItem label="Email Address">
              <Input
                placeholder="email"
                type="email"
                name="email"
                onChange={handleChange}
                value={state.email}
              />
            </FormItem>
          </div>

          <div className="grid grid-cols-2 md:grid-cols-2 gap-8 md:gap-5 mt-6">
            <FormItem label="From">
              <DatePicker
                name="from"
                dateFormat="dd/MMM/yyyy"
                className="block rounded-xl h-14 px-4 py-3 w-full border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900"
                selected={startDate}
                onChange={(date: Date) => setStartDate(date)}
              />
            </FormItem>
            <FormItem label="To">
              <DatePicker
                name="to"
                dateFormat="dd/MMM/yyyy"
                className="block rounded-xl h-14 px-4 py-3 w-full border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900"
                selected={endDate}
                onChange={(date: Date) => setEndDate(date)}
              />
            </FormItem>
          </div>
          <div className="grid grid-cols-1 gap-8 md:gap-5 mt-6">
            <FormItem label="Message">
              <Textarea
                placeholder="enter your message"
                rows={4}
                name="message"
                value={state.message ?? ""}
                onChange={handleChangeTextArea}
              />
            </FormItem>
            <div className="grid grid-cols-1">
              <ButtonPrimary type="submit">
                {loading ? <ButtonLoader /> : "Send Message"}
              </ButtonPrimary>
            </div>
          </div>
        </form>
      </div>
    );
  };

  const renderSection8 = () => {
    return (
      <div className="listingSection__wrap">
        {/* HEADING */}
        <h2 className="text-2xl font-semibold">Things to know</h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700" />

        {/* CONTENT */}
        <div>
          <h4 className="text-lg font-semibold">Check-in time</h4>
          <div className="mt-3 text-neutral-500 dark:text-neutral-400 max-w-md text-sm sm:text-base">
            <div className="flex space-x-10 justify-between p-3 bg-neutral-100 dark:bg-neutral-800 rounded-lg">
              <span>Check-in time</span>
              <span>14:00 pm</span>
            </div>
            <div className="flex space-x-10 justify-between p-3">
              <span>Check-out time</span>
              <span>12:00 noon</span>
            </div>
          </div>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700" />

        {/* CONTENT */}
        <div>
          <h4 className="text-lg font-semibold">Special Note</h4>
          <div className="prose sm:prose">
            <ul className="mt-3 text-neutral-500 dark:text-neutral-400 space-y-2">
              {apartment.rules &&
                apartment.rules.map((rule: string, index: number) => (
                  <li key={index}>{rule}</li>
                ))}
            </ul>
          </div>
        </div>
      </div>
    );
  };

  const renderSidebar = () => {
    return (
      <>
        {apartment && apartment.host && (
          <OwnersCard
            property="apartment"
            ownerDetails={apartment.host}
            price={apartment.price}
            unit="day"
            whatsAppText={whatsAppMessage}
            ownerscount={ownerscount}
          />
        )}
      </>
    );
  };

  const renderDisclaimer = () => {
    return (
      <div className="listingSection__wrap" id="disclaimer">
        <div>
          <h4 className="text-lg font-semibold">Disclaimer</h4>
          <span className="block mt-3 text-neutral-500 dark:text-neutral-400">
            The information displayed about the various listings on this website
            comprise an advertisement and they are solely for marketing
            purposes. Holiday Short Stays or her partners, affiliates, agents or
            marketers will not under any circumstance be held liable for any
            damages, conflict or imbroglio that arises from the use of any on
            the services enrolled on this website. <br />
            <br />
            Holiday Short Stay gives no indemnity as to the accuracy or
            completeness of the advertisement or any linked or associated
            information, and Holiday Short Stays has no control over the
            content. The various listings do not constitute service particulars.
            The information is provided and maintained by the owners of the
            different services for which Holiday Short Stays is an umbrella.
            Holiday Short Stays shall not in any way be held liable for the
            actions of any associate, marketer, agent and/or property
            owner/landlord on or off this website. Please ensure you verify Host
            before making payments, Thank you
          </span>
        </div>
      </div>
    );
  };

  return (
    <>
      {loading && <ShowLoading />}
      {!loading && apartment && (
        <div
          className={`nc-ListingStayDetailPage  ${className}`}
          data-nc-id="ListingStayDetailPage"
        >
          {/* SINGLE HEADER */}
          {apartment.gallery_imgs && (
            <>
              <header className="container 2xl:px-14 rounded-md sm:rounded-xl">
                <div className="relative grid grid-cols-3 sm:grid-cols-4 gap-1 sm:gap-2">
                  <div
                    className="col-span-2 row-span-3 sm:row-span-2 relative rounded-md sm:rounded-xl overflow-hidden cursor-pointer"
                    onClick={() => handleOpenModal(0)}
                  >
                    <NcImage
                      containerClassName="absolute inset-0"
                      className="object-cover w-full h-full rounded-md sm:rounded-xl"
                      src={apartment.gallery_imgs[0]}
                      prevImageHorizontal
                    />
                    <div className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity"></div>
                  </div>
                  {apartment.gallery_imgs
                    .filter((_: string, i: number) => i >= 1 && i < 5)
                    .map((item: string, index: number) => (
                      <div
                        key={index}
                        className={`relative rounded-md sm:rounded-xl overflow-hidden ${
                          index >= 3 ? "hidden sm:block" : ""
                        }`}
                      >
                        <NcImage
                          containerClassName="aspect-w-4 aspect-h-3 sm:aspect-w-6 sm:aspect-h-5"
                          className="object-cover w-full h-full rounded-md sm:rounded-xl "
                          src={item || ""}
                          prevImageHorizontal
                        />

                        {/* OVERLAY */}
                        <div
                          className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity cursor-pointer"
                          onClick={() => handleOpenModal(index + 1)}
                        />
                      </div>
                    ))}

                  <div
                    className="absolute hidden md:flex md:items-center md:justify-center left-3 bottom-3 px-4 py-2 rounded-xl bg-neutral-100 text-neutral-500 cursor-pointer hover:bg-neutral-200 z-10"
                    onClick={() => handleOpenModal(0)}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-5 w-5"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={1.5}
                        d="M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z"
                      />
                    </svg>
                    <span className="ml-2 text-neutral-800 text-sm font-medium">
                      Show all photos
                    </span>
                  </div>
                </div>
              </header>
              {/* MODAL PHOTOS */}
              <ModalPhotos
                imgs={apartment.gallery_imgs}
                isOpen={isOpen}
                onClose={handleCloseModal}
                initFocus={openFocusIndex}
              />
            </>
          )}

          {/* MAIn */}
          <main className="container mt-11 flex mb-8">
            {/* CONTENT */}
            {apartment && (
              <div className="w-full lg:w-3/5 xl:w-2/3 space-y-8 lg:space-y-10 lg:pr-10">
                {renderSection1()}
                {renderSection3()}
                {renderSection5()}
                {renderSection4()}
                {renderSection2()}
                {/* {renderSection6()} */}
                {renderSection8()}
                {renderSectionCheckIndate()}
                {renderDisclaimer()}
              </div>
            )}

            {/* SIDEBAR */}
            <div className="hidden lg:block flex-grow">
              <div className="sticky top-24">{renderSidebar()}</div>
            </div>
          </main>

          {/* STICKY FOOTER MOBILE */}
          {!isPreviewMode && (
            <div className="block lg:hidden fixed bottom-0 inset-x-0 py-4 bg-white text-neutral-900 border-t border-neutral-200 z-20">
              <div className="container flex items-center justify-between">
                <span className="text-2xl font-semibold">
                  {apartment.price ? formatMoney(apartment.price) : 0}
                  <span className="ml-1 text-base font-normal text-neutral-500 dark:text-neutral-400">
                    /night
                  </span>
                </span>

                <ButtonPrimary href="##">Reserve</ButtonPrimary>
              </div>
            </div>
          )}

          {/* OTHER SECTION */}
          {!isPreviewMode && (
            <div className="container py-24 lg:py-32">
              {/* SECTION 1 */}
              <div className="relative py-16">
                <BackgroundSection />
                <SectionSliderNewCategories
                  heading="Explore by types of stays"
                  subHeading="Explore houses based on 10 types of stays"
                  categoryCardType="card5"
                  itemPerRow={5}
                  sliderStyle="style2"
                />
              </div>

              {/* SECTION */}
              <SectionSubscribe2 className="pt-24 lg:pt-32" />
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default ApartmentDetailsPage;
