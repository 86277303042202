import { FC, useEffect, useState } from "react";
import CommonLayout from "./CommonLayout";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "redux/reducers";
import { getMyBookings } from "redux/actions/bookingActions";
import { ShowLoading } from "components/ShowLoading";
import moment from "moment";
import { formatMoney } from "utils/helpers";
export interface MyBookingsPageProps {
  className?: string;
}

const MyBookingsPage: FC<MyBookingsPageProps> = ({ className = "" }) => {
  const [loading] = useState(false);
  const { mybookings } = useSelector((store: RootState) => store.booking);
  const dispatch = useDispatch();

  console.log("my bookings", mybookings);
  const bookingsData = mybookings;

  useEffect(() => {
    const fetchMyBookings = async () => {
      await getMyBookings(dispatch);
    };

    fetchMyBookings();
  }, []);

  return (
    <div className={`nc-AccountPage ${className}`} data-nc-id="MyBookingsPage">
      <Helmet>
        <title>Account || Holiday Short Stays</title>
      </Helmet>
      <CommonLayout>
        <div className="space-y-6 sm:space-y-8">
          <div className="flex flex-col">
            <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="py-2 align-middle inline-block w-full sm:px-6 lg:px-8">
                {!loading && bookingsData && (
                  <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                    <table className="w-full divide-y divide-gray-200 table-auto">
                      <thead className="bg-gray-50">
                        <tr>
                          <th
                            scope="col"
                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                          >
                            Apartment
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                          >
                            Booking Duration
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                          >
                            Status
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                          >
                            Amount
                          </th>
                          <th scope="col" className="relative px-6 py-3">
                            <span className="sr-only">Option</span>
                          </th>
                        </tr>
                      </thead>
                      <tbody className="bg-white divide-y divide-gray-200">
                        {bookingsData.map((booking: any, index: number) => (
                          <tr key={index}>
                            <td className="px-6 py-4 whitespace-nowrap">
                              <div className="flex items-center">
                                <div className="flex-shrink-0 h-10 w-10">
                                  <img
                                    className="h-10 w-10 rounded-full"
                                    src={booking.apartment_id.gallery_imgs[0]}
                                    alt=""
                                  />
                                </div>
                                <div className="ml-4">
                                  <div className="text-sm font-medium text-gray-900">
                                    {booking.apartment_id.title}
                                  </div>
                                  <div className="text-sm text-gray-500">
                                    {booking.apartment_id.location.lga},{" "}
                                    {booking.apartment_id.location.state}
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap">
                              <div className="text-sm text-gray-900">
                                {moment(
                                  booking.checkInCheckOut.startDate
                                ).format("Do MMM. YYYY")}{" "}
                                -{" "}
                                {moment(booking.checkInCheckOut.endDate).format(
                                  "Do MMM. YYYY"
                                )}{" "}
                              </div>
                              <div className="text-sm text-gray-500">
                                {moment(booking.checkInCheckOut.endDate).diff(
                                  moment(booking.checkInCheckOut.startDate),
                                  "days"
                                ) + 1}{" "}
                                Days
                              </div>
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap">
                              <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                                Active
                              </span>
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                              {formatMoney(booking.grandTotal)}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                              <a
                                href={
                                  "/apartment/" +
                                  booking.apartment_id._id +
                                  "/" +
                                  booking.apartment_slug
                                }
                                target="_blank"
                                className="text-indigo-600 hover:text-indigo-900"
                                rel="noreferrer"
                              >
                                View Apartment
                              </a>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                )}
                {loading && !bookingsData && <ShowLoading />}
              </div>
            </div>
          </div>
        </div>
      </CommonLayout>
    </div>
  );
};

export default MyBookingsPage;
