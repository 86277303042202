import httpRequest from "../../api";
import { listingConstants } from "../actionTypes";

export const getStates = (dispatch) => {
  return httpRequest("get", "states")
    .then(({ data }) => {
      let action = {};
      if (data.status === 200) {
        action = {
          type: listingConstants.GET_STATES,
          payload: data.data.states.states,
        };
        dispatch(action);
      } else {
        const payload = "Could not load states";
        action = { type: listingConstants.ERROR, payload };
        dispatch(action);
      }

      return action;
    })
    .catch(({ response }) => {
      const message = response?.data; //.data?.message;
      return {
        payload: message,
        type: "ERROR",
      };
    });
};

// ==================================

export const getLGAs = (stateName, dispatch) => {
  return httpRequest("get", `states/lga/${stateName}`)
    .then(({ data }) => {
      let action = {};
      if (data.status === 200) {
        action = {
          type: listingConstants.GET_LGAS,
          payload: data.data.lgas,
        };
        dispatch(action);
      } else {
        const payload = "Could not load local governments";
        action = { type: listingConstants.ERROR, payload };
        dispatch(action);
      }

      return action;
    })
    .catch(({ response }) => {
      const message = response?.data; //.data?.message;
      return {
        payload: message,
        type: "ERROR",
      };
    });
};

export const saveListing = (data, dispatch) => {
  const action = { type: "UPDATE_LISTING", payload: data };
  console.log("data", data);

  dispatch(action);
};

// ==================================

export const getCustomLocations = (dispatch) => {
  return httpRequest("get", "location/custom")
    .then(({ data }) => {
      let action = {};
      if (data.status === 200) {
        action = {
          type: listingConstants.GET_LOCATIONS,
          payload: data.data.locations,
        };
        dispatch(action);
      } else {
        const payload = "Could not load custom locations";
        action = { type: listingConstants.ERROR, payload };
        dispatch(action);
      }

      return action;
    })
    .catch(({ response }) => {
      const message = response?.data; //.data?.message;
      return {
        payload: message,
        type: "ERROR",
      };
    });
};

// ==================================

export const addAdditionalRule = (rule, dispatch) => {
  const action = { type: listingConstants.ADD_ADDITIONAL_RULE, payload: rule };

  dispatch(action);
};

// ==================================

export const removeAdditionalRule = (id, dispatch) => {
  const action = { type: listingConstants.REMOVE_ADDITIONAL_RULE, payload: id };

  dispatch(action);
};

// ==================================

export const addFeaturedImage = async (image, property, dispatch) => {
  dispatch({ type: listingConstants.LOADING_FEATURED });

  let preset = "aprtmntupld";
  if (property === "hotel") {
    preset = "hotelpreset";
  } else if (property === "car") {
    preset = "carpreset";
  }

  const data = new FormData();
  data.append("file", image);
  data.append("upload_preset", preset);
  data.append("cloud_name", "holiday-short-stays");
  return fetch(
    "https://api.cloudinary.com/v1_1/holiday-short-stays/image/upload",
    {
      method: "POST",
      body: data,
    }
  )
    .then((response) => response.json())
    .then((data) => {
      dispatch({
        type: listingConstants.ADD_FEATURED_IMAGE,
        payload: data.url,
      });

      return data.url;
    })
    .catch((err) => console.log("error", err));
};

// ==================================

export const addOtherImages = async (images, dispatch) => {
  let allImages = [];
  for (let i = 0; i < images.length; i++) {
    dispatch({ type: "LOADING_OTHERS" });
    const data = new FormData();
    data.append("file", images[i]);
    data.append("upload_preset", "aprtmntupld");
    data.append("cloud_name", "holiday-short-stays");

    let img = await fetch(
      "https://api.cloudinary.com/v1_1/holiday-short-stays/image/upload",
      {
        method: "POST",
        body: data,
      }
    )
      .then((response) => response.json())
      .then((data) => {
        console.log("data", data);
        dispatch({
          type: listingConstants.ADD_OTHER_IMAGES,
          payload: data.url,
        });

        console.log("data.url", data.url);

        return data.url;
      })
      .catch((err) => console.log("error", err));

    allImages.push(img);
  }
  return allImages;
};

// ==================================

// const uploadImage = (image) => {
//   const data = new FormData();
//   data.append("file", image);
//   data.append("upload_preset", "aprtmntupld");
//   data.append("cloud_name", "holiday-short-stays");
//   fetch("https://api.cloudinary.com/v1_1/holiday-short-stays/image/upload", {
//     method: "POST",
//     body: data,
//   })
//     .then((response) => response.json())
//     .then((data) => {
//       console.log("data", data);
//       return data;
//     })
//     .catch((err) => console.log("error", err));
// };

// ==================================

export const fetchAmenities = (dispatch) => {
  dispatch({ type: listingConstants.LOADING });
  return httpRequest("get", "amenity")
    .then(({ data }) => {
      if (data.status === 200) {
        dispatch({ type: listingConstants.STOP_LOADING });
        const action = {
          type: listingConstants.GET_AMENITIES,
          payload: data.data.amenities,
        };
        dispatch(action);
      } else {
        dispatch({ type: listingConstants.STOP_LOADING });

        const payload = "Could not load amenities";
        const action = { type: listingConstants.ERROR, payload };
        dispatch(action);
      }

      // return action;
    })
    .catch(({ response }) => {
      const message = response?.data; //.data?.message;
      return {
        payload: message,
        type: "ERROR",
      };
    });
};

// ==================================

export const updateChosenAmenity = (amenity_id, status, dispatch) => {
  let action = {};

  if (status) {
    console.log(status);
    action = { type: listingConstants.ADD_CHOSEN_AMENITY, payload: amenity_id };
  } else {
    console.log(status);
    action = {
      type: listingConstants.REMOVE_CHOSEN_AMENITY,
      payload: amenity_id,
    };
  }

  dispatch(action);
};

// ==================================

export const updateRule = (item, dispatch) => {
  const action = {
    type: listingConstants.UPDATE_RULE,
    payload: item,
  };

  dispatch(action);
};

// ==================================

export const publishApartmentAction = (details, user_id, dispatch) => {
  dispatch({ type: listingConstants.LOADING });
  console.log("Post Data", details);

  const postData = {
    host: user_id,
    title: details.title,
    apartment_type: details.apartment_type,
    location: details.location,
    sublocation: details.sublocation,
    amenities: details.chosen_amenities,
    rules: details.additional_rules,
    description: details.description,
    featured_img: details.featured_img,
    gallery_imgs: details.other_imgs,
    price: details.price,
    listing_category: details.apartment_type,
    max_guests: details.no_guests,
    bedrooms: details.no_bedrooms,
    bathrooms: details.no_bathrooms,
    min_nights: details.min_nights,
    max_nights: details.max_nights,
  };

  return httpRequest("post", `apartment`, postData)
    .then(({ data }) => {
      let action = {};
      console.log("Response Data", data);
      if (data.status === 200) {
        action = {
          type: listingConstants.POST_APARTMENT,
          payload: data.message,
        };
        dispatch(action);
      } else {
        const payload = "Could not load post apartment";
        action = { type: listingConstants.ERROR, payload };
        dispatch(action);
      }

      return action;
    })
    .catch(({ response }) => {
      console.log("response", response);

      const message = response?.data?.data?.error;
      console.log("MSG", message);
      const action = { payload: message, type: "ERROR" };

      dispatch(action);

      return action;
    });
};
