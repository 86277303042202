import React from "react";
import Loader from "react-loader-spinner";
import { string } from "prop-types";
import "./index.scss";

/**
 * @function ButtonLoader
 * @description Button Loader
 * @returns {JSX} - returns Javascript Syntax Extension
 */
export const ButtonLoader = ({ type, color, width, height, customClass }) => (
  <Loader
    className={`button__loader ${customClass}`}
    type={type}
    color={color}
    width={width}
    height={height}
  />
);

ButtonLoader.propTypes = {
  color: string,
  width: string,
  height: string,
  type: string,
  customClass: string,
};

ButtonLoader.defaultProps = {
  type: "ThreeDots",
  color: "#fff",
  width: "30",
  height: "30",
  customClass: "20",
};
