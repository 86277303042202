import { FC, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import { RootState } from "redux/reducers";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import toTitleCase from "utils/strFunctions";

export interface PageAddListing8Props {}

const PagePublishedListing: FC<PageAddListing8Props> = () => {
  const listing = useSelector((store: RootState) => store.listing);
  const history = useHistory();
  const location = useLocation();

  const pageDetails: any = location.state;

  return (
    <div
      className={`nc-PageAddListing1 px-4 max-w-3xl mx-auto pb-24 pt-14 sm:py-24 lg:pb-32`}
      data-nc-id="PageAddListing1"
    >
      <div className="space-y-11">
        <div className="listingSection__wrap ">
          <>
            {listing && !listing.posted ? (
              <>
                <div className="text-center">
                  <h2 className="text-2xl font-semibold">Great! 🎉</h2>
                  <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
                    <p className="pt-4">
                      You have successfully submitted your{" "}
                      <strong>{pageDetails.property} listing</strong> details.
                    </p>
                    <p className="mt-4">
                      Your listing will appear on the website immediately.{" "}
                      <br />
                    </p>
                    <p className="pt-4">
                      <ButtonSecondary href={`/${pageDetails.post_url}`}>
                        Post Another {toTitleCase(pageDetails.property)}
                      </ButtonSecondary>
                    </p>
                  </span>
                </div>
                <div className="w-14 border-b text-center border-neutral-200 dark:border-neutral-700"></div>
                {/* FORM */}
              </>
            ) : (
              history.push("/add-listing")
            )}
          </>
        </div>

        {/* --------------------- */}
        <div className="flex justify-center space-x-5">
          <ButtonPrimary href="/dashboard">Go to My Listing Page</ButtonPrimary>
        </div>
      </div>
    </div>
  );
};

export default PagePublishedListing;
