import React, { FC, useState, useEffect } from "react";
import StartRating from "components/StartRating/StartRating";
import moment from "moment";
import Avatar from "shared/Avatar/Avatar";
import Badge from "shared/Badge/Badge";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Input from "shared/Input/Input";
import NcImage from "shared/NcImage/NcImage";
import LikeSaveBtns from "./LikeSaveBtns";
// import ModalPhotos from "./ModalPhotos";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import SectionSliderNewCategories from "components/SectionSliderNewCategories/SectionSliderNewCategories";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { RootState } from "redux/reducers";
import { pluralize } from "utils/helpers";
import FormItem from "containers/PageAddListing/FormItem";
import OwnersCard from "components/Owner/OwnersCard";
import { fetchBoatDetails } from "redux/actions/boatActions";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import cogoToast from "cogo-toast";
import { submitInquiry } from "redux/actions/bookingActions";
import { bookingConstants } from "redux/actionTypes";
import Textarea from "shared/Textarea/Textarea";
import { ButtonLoader } from "components/ButtonLoader.jsx";

export interface ListingCarDetailPageProps {
  className?: string;
}

export interface SingleBoatProps {
  boatid: string;
  slug: string;
}

const ListingBoatDetailPage: FC<ListingCarDetailPageProps> = ({
  className = "",
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [openFocusIndex, setOpenFocusIndex] = useState(0);
  const dispatch = useDispatch();
  const history = useHistory();
  const [whatsAppMessage, setWhatsAppMessage] = useState("");
  const [apartmentURL, setApartmentURL] = useState("");
  const location = useLocation();
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [state, setState] = useState({
    fullname: "",
    phone: "",
    email: "",
    message: "",
    from: "",
    to: "",
  });

  const { boatid, slug } = useParams<SingleBoatProps>();
  const { boat: car, fetching }: any = useSelector(
    (state: RootState) => state.boats
  );

  const { loading }: any = useSelector((state: RootState) => state.booking);

  console.log("Boat details", car);
  console.log("boatid", boatid, "slug", slug);

  useEffect(() => {
    // setLoading(true);
    console.log("in useeffect");
    const fetchSingleBoatDetails = async () => {
      console.log("before fetching");
      await fetchBoatDetails(boatid, dispatch);
      console.log("after fetching");
      // setLoading(false);
    };

    fetchSingleBoatDetails();
  }, []);

  useEffect(() => {
    setApartmentURL(`https://holidayshortstays.com${location.pathname}`);
    const text = `I am interested in your property on Holiday Short Stays:

    https://holidayshortstays.com${location.pathname}`;

    decodeURIComponent(text.replace(/\+/g, " "));
    setWhatsAppMessage(text);

    document.title = "Fresh apartment";
  }, [location]);

  useEffect(() => {
    console.log("Whats popping...");
  }, []);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue: any = {};
    newValue[e.target.name] = e.target.value;
    setState({ ...state, ...newValue });
  };

  const handleChangeTextArea = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newValue: any = {};
    newValue[e.target.name] = e.target.value;
    setState({ ...state, ...newValue });
  };

  const handleSubmit = (e: { preventDefault: () => void }) => {
    e.preventDefault();

    if (!state.fullname || !state.phone || !state.email || !state.message) {
      cogoToast.error("Kindly fill all fields before submitting", {
        position: "top-center",
      });
      return;
    }

    dispatch({ type: bookingConstants.LOADING });
    const category = "boat";

    const fromDate = moment(startDate).format("dddd, MMMM Do YYYY");
    const toDate = moment(endDate).format("dddd, MMMM Do YYYY");

    let postPayload: any = state;
    postPayload["owner_id"] = car.owner_id;
    postPayload["category"] = category;
    postPayload["property_id"] = boatid;
    postPayload["property_title"] = car.title;
    postPayload["fromDate"] = fromDate;
    postPayload["toDate"] = toDate;
    postPayload[
      "property_url"
    ] = `https://holidayshortstays.com/${category}/${boatid}/${slug}`;

    const processSubmitInquiry = async () => {
      const booked = await submitInquiry(postPayload, dispatch);

      console.log("Booked", booked);

      const {
        payload: { data, status },
      } = booked;

      if (status === 200) {
        cogoToast.success(data.message, {
          position: "top-right",
          hideAfter: 5,
        });

        const defaultState = {
          fullname: "",
          phone: "",
          email: "",
          message: "",
        };
        setState({ ...state, ...defaultState });
      } else {
        cogoToast.error(data.message, {
          position: "top-right",
        });
        dispatch({ type: bookingConstants.STOP_LOADING });
      }
    };

    processSubmitInquiry();
  };

  // USE STATE
  // const [dateRangeValue, setDateRangeValue] = useState<DateRage>({
  //   startDate: moment(),
  //   endDate: moment().add(4, "days"),
  // });
  // const [timeRangeValue, setTimeRangeValue] = useState<TimeRage>({
  //   startTime: "10:00 AM",
  //   endTime: "10:00 AM",
  // });

  // const windowSize = useWindowSize();

  // const getDaySize = () => {
  //   if (windowSize.width <= 375) {
  //     return 34;
  //   }
  //   if (windowSize.width <= 500) {
  //     return undefined;
  //   }
  //   if (windowSize.width <= 1280) {
  //     return 56;
  //   }
  //   return 48;
  // };

  const handleOpenModal = (index: number) => {
    setIsOpen(true);
    setOpenFocusIndex(index);
  };

  const handleCloseModal = () => setIsOpen(false);

  const renderSection1 = () => {
    return (
      <div className="listingSection__wrap !space-y-6">
        {/* 1 */}
        <div className="flex justify-between items-center">
          <Badge color="pink" name={`${car.guest_capacity} person Capacity`} />
          <LikeSaveBtns
            url={apartmentURL}
            description={`Check out this boat on Holiday Short Stays`}
          />
        </div>

        {/* 2 */}
        <h2 className="text-2xl sm:text-3xl lg:text-4xl font-semibold">
          {car.title}
        </h2>

        {/* 3 */}
        {/* <div className="flex items-center space-x-4">
          <StartRating />
          <span>·</span>
          <span>
            <i className="las la-map-marker-alt"></i>
            <span className="ml-1"> Tokyo, Jappan</span>
          </span>
        </div> */}

        {/* 4 */}
        <div className="flex items-center">
          <Avatar hasChecked sizeClass="h-10 w-10" radius="rounded-full" />
          <span className="ml-2.5 text-neutral-500 dark:text-neutral-400">
            Boat owner:{" "}
            <span className="text-neutral-900 dark:text-neutral-200 font-medium">
              {car &&
                car.owner_id &&
                (car.owner_id.company_name
                  ? car.owner_id.company_name
                  : car.owner_id.first_name + " " + car.owner_id.last_name)}
            </span>
          </span>
        </div>

        {/* 5 */}
        <div className="w-full border-b border-neutral-100 dark:border-neutral-700" />

        {/* 6 */}
        <div className="flex items-center justify-between xl:justify-start space-x-8 xl:space-x-12 text-sm text-neutral-700 dark:text-neutral-300">
          <div className="flex flex-col sm:flex-row items-center space-y-3 sm:space-y-0 text-center sm:text-left sm:space-x-3 ">
            <i className="las la-bed text-2xl"></i>
            <span className="">{pluralize(car.beds, "Bed")}</span>
          </div>
          <div className="flex flex-col sm:flex-row items-center space-y-3 sm:space-y-0 text-center sm:text-left sm:space-x-3 ">
            <i className="las la-users text-2xl"></i>
            <span className=""> {car.guest_capacity} person capacity</span>
          </div>
        </div>
      </div>
    );
  };

  const renderDescription = () => {
    return (
      <div className="listingSection__wrap">
        <h2 className="text-2xl font-semibold">Car description</h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        <div className="text-neutral-6000 dark:text-neutral-300">
          <p>{car.description}</p>
        </div>
      </div>
    );
  };

  const renderSectionCheckIndate = () => {
    return (
      <div className="listingSection__wrap overflow-hidden">
        <form method="POST" onSubmit={handleSubmit}>
          {/* HEADING */}
          <div className="">
            <h2 className="text-2xl font-semibold">
              Inquire about this luxury boat
            </h2>
            <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
              All inquiries go directly to the host of the property
            </span>
          </div>
          <div className="w-14 border-b border-neutral-200 mt-8 mb-8 dark:border-neutral-700"></div>
          {/*  */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8 md:gap-5 mt-6">
            <FormItem label="Full Name">
              <Input
                placeholder="Full Name"
                type="text"
                name="fullname"
                onChange={handleChange}
                value={state.fullname}
              />
            </FormItem>
            <FormItem label="Phone Number">
              <Input
                placeholder="phone"
                type="number"
                name="phone"
                onChange={handleChange}
                value={state.phone}
              />
            </FormItem>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-1 gap-8 md:gap-5 mt-6">
            <FormItem label="Email Address">
              <Input
                placeholder="email"
                type="email"
                name="email"
                onChange={handleChange}
                value={state.email}
              />
            </FormItem>
          </div>
          <div className="grid grid-cols-2 md:grid-cols-2 gap-8 md:gap-5 mt-6">
            <FormItem label="From">
              <DatePicker
                name="from"
                dateFormat="dd/MMM/yyyy"
                className="block rounded-xl h-14 px-4 py-3 w-full border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900"
                selected={startDate}
                onChange={(date: Date) => setStartDate(date)}
              />
            </FormItem>
            <FormItem label="To">
              <DatePicker
                name="to"
                dateFormat="dd/MMM/yyyy"
                className="block rounded-xl h-14 px-4 py-3 w-full border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900"
                selected={endDate}
                onChange={(date: Date) => setEndDate(date)}
              />
            </FormItem>
          </div>
          <div className="grid grid-cols-1 gap-8 md:gap-5 mt-6">
            <FormItem label="Message">
              <Textarea
                placeholder="enter your message"
                rows={4}
                name="message"
                value={state.message ?? ""}
                onChange={handleChangeTextArea}
              />
            </FormItem>
            <div className="grid grid-cols-1">
              <ButtonPrimary type="submit">
                {loading ? <ButtonLoader /> : "Send Message"}
              </ButtonPrimary>
            </div>
          </div>
        </form>
      </div>
    );
  };

  const renderSection5 = () => {
    return (
      <div className="listingSection__wrap">
        {/* HEADING */}
        <h2 className="text-2xl font-semibold">Car Owner</h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>

        {/* host */}
        <div className="flex items-center space-x-4">
          <Avatar
            hasChecked
            hasCheckedClass="w-4 h-4 -top-0.5 right-0.5"
            sizeClass="h-14 w-14"
            radius="rounded-full"
          />
          <div>
            <a className="block text-xl font-medium" href="##">
              {car.owner_id &&
                car.owner_id.first_name + " " + car.owner_id.last_name}
            </a>
            <div className="mt-1.5 flex items-center text-sm text-neutral-500 dark:text-neutral-400">
              <StartRating />
              <span className="mx-2">·</span>
              <span> 12 places</span>
            </div>
          </div>
        </div>

        {/* desc */}
        <span className="block text-neutral-6000 dark:text-neutral-300">
          {car.owner_id.bio && car.owner_id.bio.substring(0, 100)}
        </span>

        {/* info */}
        <div className="block text-neutral-500 dark:text-neutral-400 space-y-2.5">
          <div className="flex items-center space-x-3">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
              />
            </svg>
            <span>
              Joined in{" "}
              {car.owner_id &&
                moment(car.owner_id.created_at).format("Do MMM. YYYY")}
            </span>
          </div>
          <div className="flex items-center space-x-3">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                d="M7 8h10M7 12h4m1 8l-4-4H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-3l-4 4z"
              />
            </svg>
            <span>
              {car.owner_id.phone}
              {car.owner_id.phone1 && ", " + car.owner_id.phone1}
            </span>
          </div>
          <div className="flex items-center space-x-3">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>

            <span>{car.owner_id.email}</span>
          </div>
        </div>

        {/* == */}
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
      </div>
    );
  };

  const renderSidebarPrice = () => {
    return (
      <>
        {car.owner_id && (
          <OwnersCard
            property="boat"
            ownerDetails={car.owner_id}
            price={car.price}
            unit="hour"
          />
        )}
      </>
    );
  };

  const renderSidebarDetail = () => {
    return (
      <div className="listingSection__wrap shadow-xl">
        <span className="text-2xl font-semibold block">
          Pick up and drop off
        </span>
        <div className="mt-8 flex">
          <div className="flex-shrink-0 flex flex-col items-center py-2">
            <span className="block w-6 h-6 rounded-full border border-neutral-400"></span>
            <span className="block flex-grow border-l border-neutral-400 border-dashed my-1"></span>
            <span className="block w-6 h-6 rounded-full border border-neutral-400"></span>
          </div>
          <div className="ml-4 space-y-14 text-sm">
            <div className="flex flex-col space-y-2">
              <span className=" text-neutral-500 dark:text-neutral-400">
                Monday, August 12 · 10:00
              </span>
              <span className=" font-semibold">
                Saint Petersburg City Center
              </span>
            </div>
            <div className="flex flex-col space-y-2">
              <span className=" text-neutral-500 dark:text-neutral-400">
                Monday, August 16 · 10:00
              </span>
              <span className=" font-semibold">
                Saint Petersburg City Center
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div
      className={`nc-ListingCarDetailPage  ${className}`}
      data-nc-id="ListingCarDetailPage"
    >
      {/* SINGLE HEADER */}
      {car && (
        <>
          <header className="container 2xl:px-14 rounded-md sm:rounded-xl">
            <div className="relative grid grid-cols-4 gap-1 sm:gap-2">
              <div
                className="col-span-2 row-span-2 relative rounded-md sm:rounded-xl overflow-hidden cursor-pointer"
                onClick={() => handleOpenModal(0)}
              >
                <NcImage
                  containerClassName="absolute inset-0"
                  className="object-cover w-full h-full rounded-md sm:rounded-xl"
                  src={car.featured_img && car.featured_img}
                  prevImageHorizontal
                />
                <div className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity"></div>
              </div>

              {/*  */}
              <div
                className="col-span-1 row-span-2 relative rounded-md sm:rounded-xl overflow-hidden cursor-pointer"
                onClick={() => handleOpenModal(1)}
              >
                <NcImage
                  containerClassName="absolute inset-0"
                  className="object-cover w-full h-full rounded-md sm:rounded-xl"
                  src={car.other_imgs && car.other_imgs[0]}
                  prevImageHorizontal
                />
                <div className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity"></div>
              </div>

              {/*  */}
              {car &&
                car.other_imgs &&
                car.other_imgs
                  .filter((_: any, i: number) => i >= 2 && i < 4)
                  .map((item: any, index: number) => (
                    <div
                      key={index}
                      className={`relative rounded-md sm:rounded-xl overflow-hidden ${
                        index >= 2 ? "block" : ""
                      }`}
                    >
                      <NcImage
                        containerClassName="aspect-w-4 aspect-h-3"
                        className="object-cover w-full h-full rounded-md sm:rounded-xl "
                        src={item || ""}
                        prevImageHorizontal
                      />

                      {/* OVERLAY */}
                      <div
                        className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity cursor-pointer"
                        onClick={() => handleOpenModal(index + 2)}
                      />
                    </div>
                  ))}

              <div
                className="absolute hidden md:flex md:items-center md:justify-center left-3 bottom-3 px-4 py-2 rounded-xl bg-neutral-100 text-neutral-500 cursor-pointer hover:bg-neutral-200 z-10"
                onClick={() => handleOpenModal(0)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={1.5}
                    d="M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z"
                  />
                </svg>
                <span className="ml-2 text-neutral-800 text-sm font-medium">
                  Show all photos
                </span>
              </div>
            </div>
          </header>
          {/* MODAL PHOTOS */}
          {/* <ModalPhotos
            imgs={car.other_imgs && car.other_imgs}
            isOpen={isOpen}
            onClose={handleCloseModal}
            initFocus={openFocusIndex}
          /> */}
        </>
      )}

      {/* MAIn */}
      <main className="container mt-11 flex ">
        {/* CONTENT */}
        {car && car.owner_id && (
          <div className="w-full lg:w-3/5 xl:w-2/3 space-y-8 lg:pr-10 lg:space-y-10">
            {renderSection1()}
            <div className="block lg:hidden">{renderSidebarDetail()}</div>
            {/* {renderSectionTienIch()} */}
            {car.description && renderDescription()}
            {renderSectionCheckIndate()}
            {car.owner_id && renderSection5()}
          </div>
        )}

        {/* SIDEBAR */}
        {car && (
          <div className="hidden lg:block flex-grow">
            {/* {renderSidebarDetail()} */}
            <div className="mt-10 sticky top-24">{renderSidebarPrice()}</div>
          </div>
        )}
      </main>

      {/* OTHER SECTION */}
      <div className="container py-24 lg:py-32">
        {/* SECTION 1 */}
        <div className="relative py-16">
          <BackgroundSection />
          <SectionSliderNewCategories
            heading="Explore by types of stays"
            subHeading="Explore houses based on 10 types of stays"
            categoryCardType="card5"
            itemPerRow={5}
            sliderStyle="style2"
          />
        </div>

        {/* SECTION */}
        <SectionSubscribe2 className="pt-24 lg:pt-32" />
      </div>
    </div>
  );
};

export default ListingBoatDetailPage;
