import React from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import CommonLayout from "./CommonLayout";

const ProfileBilling = () => {
  return (
    <div>
      <CommonLayout>
        <div className="space-y-6 sm:space-y-8">
          {/* HEADING */}
          <h2 className="text-3xl font-semibold">Payments & payouts</h2>
          <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
          <div className="max-w-2xl">
            <span className="text-xl font-semibold block">Payout methods</span>
            <br />
            <span className="text-neutral-700 dark:text-neutral-300 block">
              When we receive a payment for a booking on your behalf, we call
              that payment to you a "payout".
              <br />
              <br />
              To get paid, you need to set up a payout account details. We
              release payouts about 24 hours after a guest checks into the
              apartment. The time it takes for the funds to appear in your
              account depends on the bank.
            </span>
            <div className="pt-10">
              <ButtonPrimary>Add payout mothod</ButtonPrimary>
            </div>
          </div>
        </div>
      </CommonLayout>
    </div>
  );
};

export default ProfileBilling;
