import React, { FC } from "react";
import { useDispatch, useSelector } from "react-redux";
import { saveListing } from "redux/actions/listingActions";
import { RootState } from "redux/reducers";
import Textarea from "shared/Textarea/Textarea";
import CommonLayout from "./CommonLayout";

export interface PageAddListing5Props {}

const PageAddListing5: FC<PageAddListing5Props> = () => {
  const dispatch = useDispatch();
  const { description } = useSelector((store: RootState) => store.listing);

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newValue: any = {};
    newValue[e.target.name] = e.target.value;
    saveListing(newValue, dispatch);
  };

  return (
    <CommonLayout
      index="05"
      backtHref="/add-listing-4"
      nextHref="/add-listing-6"
    >
      <>
        <div>
          <h2 className="text-2xl font-semibold">
            Your place description for client
          </h2>
          <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
            Mention the best features of your accommodation, any special
            amenities like fast Wi-Fi or parking, as well as things you like
            about the neighborhood.
          </span>
        </div>

        <Textarea
          placeholder="..."
          rows={14}
          name="description"
          value={description ?? ""}
          onChange={handleChange}
        />
      </>
    </CommonLayout>
  );
};

export default PageAddListing5;
