import httpRequest from "../../api";
import { carConstants, actions } from "../actionTypes";

// ========================================================================

export const getAllMakes = (dispatch) => {
  return httpRequest("get", "car/makes")
    .then(({ data }) => {
      let action = {};
      console.log("makes", data);
      if (data.status === 200) {
        action = {
          type: carConstants.GET_MAKES,
          payload: data.data.carmakes,
        };
        dispatch(action);
      } else {
        const payload = "Could not load makes";
        action = { type: carConstants.ERROR, payload };
        dispatch(action);
      }

      return action;
    })
    .catch(({ response }) => {
      const message = response?.data; //.data?.message;
      return {
        payload: message,
        type: "ERROR",
      };
    });
};

// ========================================================================

export const getModels = (make_id, dispatch) => {
  return httpRequest("get", `car/model/${make_id}`)
    .then(({ data }) => {
      let action = {};
      if (data.status === 200) {
        action = {
          type: carConstants.GET_MODELS,
          payload: data.data.carmodels,
        };
        dispatch(action);
      } else {
        const payload = "Could not load makes";
        action = { type: carConstants.ERROR, payload };
        dispatch(action);
      }

      return action;
    })
    .catch(({ response }) => {
      const message = response?.data; //.data?.message;
      return {
        payload: message,
        type: "ERROR",
      };
    });
};

// ========================================================================

export const publishCarAction = (details, user_id, dispatch) => {
  console.log("Post Data", details);

  const postData = {
    make_id: details.make,
    model: details.model,
    title: details.title,
    year: details.year,
    price: details.price,
    condition: details.condition.toLowerCase(),
    color: details.color,
    location: details.location,
    sublocation: details.sublocation,
    description: details.description,
    featured_img: details.featured_image,
    other_imgs: details.other_imgs,
  };

  return httpRequest("post", `car`, postData)
    .then(({ data }) => {
      let action = {};
      console.log("Response Data", data);
      if (data.status === 200) {
        action = {
          type: carConstants.POST_CAR,
          payload: data.message,
        };

        dispatch(action);
      } else {
        const payload = "Could not load post car";
        action = { type: carConstants.ERROR, payload };
        dispatch(action);
      }

      return action;
    })
    .finally(() => {
      dispatch({ type: carConstants.STOP_LOADING });
      dispatch({ type: carConstants.RESET_LISTING });
    })
    .catch(({ response }) => {
      console.log("response", response);

      const message = response?.data?.data?.error;
      console.log("MSG", message);
      const action = { payload: message, type: "ERROR" };

      dispatch(action);

      return action;
    });
};

// ====================================================================

export const fetchCarDetails = (car_id, dispatch) => {
  dispatch({ type: carConstants.FETCHING });

  console.log("{{{{{{{ FETCHING CAR DETAILS }}}}}}}}");

  return httpRequest("get", `car/${car_id}`)
    .then(({ data }) => {
      console.log("fetched", data.data.car);
      let action = {};
      if (data.status === 200) {
        action = {
          type: carConstants.GET_CAR_DETAILS,
          payload: data.data,
        };
        dispatch({ type: carConstants.STOP_FETCHING });
        dispatch(action);
      } else {
        const payload = "Could not load car";
        action = { type: carConstants.ERROR, payload };

        dispatch({ type: carConstants.STOP_FETCHING });
        dispatch(action);
      }

      return action;
    })
    .catch(({ response }) => {
      dispatch({ type: carConstants.STOP_FETCHING });

      const message = response?.data; //.data?.message;
      return {
        payload: message,
        type: "ERROR",
      };
    });
};

// ====================================================================

export const fetchMyCars = (host_id, dispatch) => {
  dispatch({ type: carConstants.FETCHING });

  console.log("{{{{{{{ FETCHING CARS }}}}}}}}");

  return httpRequest("get", `car/user/${host_id}`)
    .then(({ data }) => {
      console.log("fetched", data.data.cars);
      let action = {};
      if (data.status === 200) {
        if (data.data.cars.length > 0) {
          action = {
            type: carConstants.GET_MY_CARS,
            payload: data.data.cars,
          };
        } else {
          action = {
            type: carConstants.NO_CAR,
            payload: data.data.message,
          };
        }
        dispatch({ type: carConstants.STOP_FETCHING });
        dispatch(action);
      } else {
        const payload = "Could not load cars";
        action = { type: carConstants.ERROR, payload };

        dispatch({ type: carConstants.STOP_LOADING });
        dispatch(action);
      }

      return action;
    })
    .catch(({ response }) => {
      dispatch({ type: carConstants.STOP_LOADING });

      const message = response?.data; //.data?.message;
      return {
        payload: message,
        type: "ERROR",
      };
    });
};

// ====================================================================

export const fetchAllCars = (filter = {}, page, loadMore, dispatch) => {
  if (page === 1) dispatch({ type: carConstants.FETCHING });
  const limit = 12;

  // dispatch({ type: carConstants.FETCHING });

  console.log("{{{{{{{ FETCHING ALL CARS }}}}}}}}");

  return httpRequest("get", `car?page=${page}&limit=${limit}`)
    .then(({ data }) => {
      console.log("fetched all cars", data);
      let action = {};
      if (data.status === 200) {
        dispatch({ type: carConstants.STOP_FETCHING });

        if (data.data.cars.length > 0) {
          if (!loadMore) {
            action = {
              type: carConstants.GET_CARS,
              payload: data.data,
            };
          } else {
            action = {
              type: carConstants.GET_MORE_CARS,
              payload: data.data,
            };
          }
        } else {
          action = {
            type: carConstants.NO_CAR,
            payload: data.data.message,
          };
        }
        dispatch(action);
      } else {
        const payload = "Could not load cars";
        action = { type: carConstants.ERROR, payload };

        dispatch({ type: carConstants.STOP_LOADING });
        dispatch(action);
      }

      return action;
    })
    .catch(({ response }) => {
      dispatch({ type: carConstants.STOP_LOADING });

      const message = response?.data; //.data?.message;
      return {
        payload: message,
        type: "ERROR",
      };
    });
};

// ========================================================================

export const bookCar = (details, dispatch) => {
  dispatch({ type: carConstants.LOADING });
  console.log("Post Data", details);

  return httpRequest("post", `booking/car`, details)
    .then(({ data }) => {
      let action = {};
      console.log("Response Data", data);
      if (data.status === 200) {
        action = {
          type: carConstants.BOOK_CAR,
          payload: data,
        };
        dispatch(action);
      } else {
        const payload = "Could not book car";
        action = { type: carConstants.ERROR, payload };
        dispatch(action);
      }

      return action;
    })
    .catch(({ response }) => {
      console.log("response", response);

      const message = response?.data?.data?.error;
      console.log("MSG", message);
      const action = { payload: message, type: "ERROR" };

      dispatch(action);

      return action;
    });
};

// ========================================================================

export const addCarFeaturedImage = async (image) => {
  // dispatch({ type: carConstants.LOADING_FEATURED });
  // dispatch({ type: actions.LOADING_FEATURED });

  const data = new FormData();
  data.append("file", image);
  data.append("upload_preset", "carpreset");
  data.append("cloud_name", "holiday-short-stays");

  let imgUrl = "";
  return fetch(
    "https://api.cloudinary.com/v1_1/holiday-short-stays/image/upload",
    {
      method: "POST",
      body: data,
    }
  )
    .then((response) => response.json())
    .then((data) => {
      console.log("upload car data", data);
      imgUrl = data.url;

      return imgUrl;
      // dispatch({
      //   type: carConstants.ADD_FEATURED_IMAGE,
      //   payload: data.url,
      // });
    })
    .catch((err) => console.log("error", err));

  return imgUrl;
};

// ==================================

export const addOtherImages = async (images) => {
  let allImages = [];
  for (let i = 0; i < images.length; i++) {
    // dispatch({ type: "LOADING_OTHER_IMAGES" });

    const data = new FormData();
    data.append("file", images[i]);
    data.append("upload_preset", "carpreset");
    data.append("cloud_name", "holiday-short-stays");

    let img = await fetch(
      "https://api.cloudinary.com/v1_1/holiday-short-stays/image/upload",
      {
        method: "POST",
        body: data,
      }
    )
      .then((response) => response.json())
      .then((data) => {
        console.log("data", data);
        // allImages.push(data.url);
        return data.url;
      })
      .catch((err) => console.log("error", err));

    allImages.push(img);
  }

  return allImages;
};

// ====================================================================

export const deleteCar = (car_id, slug, dispatch) => {
  dispatch({ type: carConstants.FETCHING });

  console.log("{{{{{{{ DELETING A CAR }}}}}}}}");

  return httpRequest("delete", `car/${car_id}/${slug}`)
    .then(({ data }) => {
      console.log("fetched", data.data.message);
      let action = {};
      if (data.status === 200) {
        action = {
          type: carConstants.DELETE_CAR,
          payload: car_id,
        };
        dispatch(action);
      } else {
        const payload = "Could not load car";
        action = { type: carConstants.ERROR, payload };
      }

      dispatch({ type: carConstants.STOP_FETCHING });

      return { status: true, msg: data.data.message };
    })
    .catch(({ response }) => {
      dispatch({ type: carConstants.STOP_FETCHING });

      const message = response?.data; //.data?.message;
      return {
        payload: message,
        type: "ERROR",
      };
    });
};

// ========================================================================

export const updateCarAction = (details, carid, dispatch) => {
  const postData = {
    make_id: details.make,
    model: details.model,
    title: details.title,
    year: details.year,
    price: details.price,
    condition: details.condition.toLowerCase(),
    color: details.color,
    location: details.location,
    sublocation: details.sublocation,
    description: details.description,
    featured_img: details.featured_image,
    other_imgs: details.other_imgs,
  };

  return httpRequest("patch", `car/${carid}`, postData)
    .then(({ data }) => {
      let action = {};
      console.log("Response Data", data);
      if (data.status === 200) {
        action = {
          payload: data.message,
        };

        // dispatch(action);
      } else {
        const payload = "Could not load post car";
        action = { payload };
        // dispatch(action);
      }

      return action;
    })
    .finally(() => {
      dispatch({ type: carConstants.STOP_LOADING });
      dispatch({ type: carConstants.RESET_LISTING });
    })
    .catch(({ response }) => {
      console.log("response", response);

      const message = response?.data?.data?.error;
      console.log("MSG", message);
      const action = { payload: message, type: "ERROR" };

      dispatch(action);

      return action;
    });
};
