import React, { FC, useState } from "react";
import { CarDisplayDataType } from "data/types";
import StartRating from "components/StartRating/StartRating";
import NcImage from "shared/NcImage/NcImage";
import { formatMoney } from "utils/helpers";
import toTitleCase from "utils/strFunctions";
import { ButtonLoader } from "components/ButtonLoader.jsx";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { deleteCar } from "redux/actions/carActions";
import { useDispatch } from "react-redux";
import cogoToast from "cogo-toast";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import { useHistory } from "react-router-dom";

export interface CarCardProps {
  className?: string;
  data?: CarDisplayDataType;
  owner?: boolean;
  size?: "default" | "small";
}

const DEMO_DATA: any = [];

const CarCardDisplay: FC<CarCardProps> = ({
  size = "default",
  className = "",
  owner = false,
  data = DEMO_DATA,
}) => {
  const {
    _id,
    // make_id,
    // model,
    title,
    year,
    condition,
    color,
    price,
    // description,
    featured_img,
    slug,
  } = data;
  const href = `/car/${_id}/${slug}`;

  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();

  const handleDeleteCar = () => {
    // e.preventDefault();
    const answer = window.confirm(
      "Are you sure you want to delete this car?\nClick OK to confirm"
    );
    if (answer) {
      const deleteThisApartment = async () => {
        const result = await deleteCar(_id, slug, dispatch);
        if (result.status) {
          cogoToast.success(result.msg, {
            position: "top-right",
          });
        }
      };

      deleteThisApartment();
    }
  };

  const handleEditCar = (carid: string) => {
    history.push({
      pathname: `/list-car/edit/${carid}`,
      state: data,
    });
  };

  const renderSliderGallery = () => {
    return (
      <a href={href} className="flex flex-col">
        <div className="relative w-full rounded-2xl overflow-hidden">
          <div className="aspect-w-16 aspect-h-9 ">
            <NcImage
              containerClassName="flex items-center justify-center"
              className="w-full"
              src={featured_img}
            />
          </div>
        </div>
      </a>
    );
  };

  const renderContent = () => {
    return (
      <div className={size === "default" ? "p-5  space-y-4" : "p-3  space-y-2"}>
        <div className="space-y-2">
          <div className="flex items-center space-x-2">
            <a href={href} className="flex flex-col">
              <h2
                className={`  capitalize ${
                  size === "default"
                    ? "text-xl font-semibold"
                    : "text-base font-medium"
                }`}
              >
                <span className="line-clamp-1">{title}</span>
              </h2>
            </a>
          </div>
          <div className="flex items-center text-neutral-500 dark:text-neutral-400 text-sm space-x-2">
            <span className="">{year}</span>
            <span> - </span>
            <span className="">{color} </span>
          </div>
        </div>
        <div className="w-14  border-b border-neutral-100 dark:border-neutral-800"></div>
        <div className="flex justify-between items-center">
          <span className="text-base font-semibold">
            {formatMoney(price)}
            {` `}
            {size === "default" && (
              <span className="text-sm text-neutral-500 dark:text-neutral-400 font-normal">
                /day
              </span>
            )}
          </span>
          {/* <StartRating reviewCount={5} /> */}
          {owner && (
            <div className="flex justify-between items-center">
              <ButtonPrimary type="button" onClick={handleDeleteCar}>
                {loading ? <ButtonLoader /> : "Delete"}
              </ButtonPrimary>
              <ButtonSecondary
                className="ml-2 p-2"
                type="button"
                onClick={() => handleEditCar(_id)}
              >
                {loading ? <ButtonLoader /> : "Edit"}
              </ButtonSecondary>
            </div>
          )}
        </div>
      </div>
    );
  };

  return (
    <div
      className={`nc-CarCard group relative border border-neutral-200 dark:border-neutral-700 rounded-3xl overflow-hidden hover:shadow-xl transition-shadow bg-white dark:bg-neutral-900 ${className}`}
      data-nc-id="CarCard"
    >
      {renderSliderGallery()}
      {renderContent()}
    </div>
  );
};

export default CarCardDisplay;
